const months = [
    {
        id: 0,
        nameMonth: "Januari"
    },
    {
        id: 1,
        nameMonth: "Februari"
    },
    {
        id: 2,
        nameMonth: "Maret"
    }, 
    {
        id: 3,
        nameMonth: "April"
    },
    {
        id: 4,
        nameMonth: "Mei"
    },
    {
        id: 5,
        nameMonth: "Juni"
    },
    {
        id: 6,
        nameMonth: "Juli"
    },
    {
        id: 7,
        nameMonth: "Agustus"
    }, 
    {
        id: 8,
        nameMonth: "September"
    },
    {
        id: 9,
        nameMonth: "Oktober"
    },
    {
        id: 10,
        nameMonth: "November"
    }, 
    {
        id: 11,
        nameMonth: "Desember"
    }
]

export default months