import React from 'react'
import { LogoEdufic } from '../../assets'
import styles from './SideBar.module.css'
import { useLocation, useNavigate } from 'react-router-dom'

const SideBar = () => {
  const location = useLocation()
  const currentLocation = location.pathname.split('/')[1]
  const navigate = useNavigate()

  const userLogout = () => {
    setTimeout(() => {
        localStorage.removeItem('accessToken')
        navigate('/')
    }, 200)
  }

  return (
    <div className={styles.sidebar}>
      <a href="https://edufic.id">
        <img src={LogoEdufic} alt="Edufic" height="50px" />
      </a>
      <div className={styles.sidebarcontent}>
        <div className={styles.sidebarmenu}>
            <a href="/dashboard">
            <div className={currentLocation === 'dashboard' ? styles.activemenu : styles.inactivemenu}>
                Dashboard
            </div>
            </a>
            <a href="/classes">
            <div className={currentLocation === 'classes' ? styles.activemenu : styles.inactivemenu}>
                Daftar Kelas
            </div>
            </a>
            <a href="/class-management">
            <div className={currentLocation === 'class-management' ? styles.activemenu : styles.inactivemenu}>
                Manajemen Kelas
            </div>
            </a>
            <a href="/students">
            <div className={currentLocation === 'students' ? styles.activemenu : styles.inactivemenu}>
                Daftar Murid
            </div>
            </a>
            <a href="/teachers">
            <div className={currentLocation === 'teachers' ? styles.activemenu : styles.inactivemenu}>
                Daftar Guru
            </div>
            </a>
            <a href="/rooms">
            <div className={currentLocation === 'rooms' ? styles.activemenu : styles.inactivemenu}>
                Daftar Room
            </div>
            </a>
            <a href="/preference">
            <div className={currentLocation === 'preference' ? styles.activemenu : styles.inactivemenu}>
                Preferensi Guru
            </div>
            </a>
            <a href="/attendance">
            <div className={currentLocation === 'attendance' ? styles.activemenu : styles.inactivemenu}>
                Monitoring Presensi
            </div>
            </a>
            <a href="/salary">
            <div className={currentLocation === 'salary' ? styles.activemenu : styles.inactivemenu}>
                Management Gaji
            </div>
            </a>
            <a href="/reset-password">
            <div className={currentLocation === 'reset-password' ? styles.activemenu : styles.inactivemenu}>
                Reset Password
            </div>
            </a>
            <a href="/short-link">
            <div className={currentLocation === 'short-link' ? styles.activemenu : styles.inactivemenu}>
                Short Link
            </div>
            </a>
        </div>
        <div className={styles.logoutContainer}>
            <button className={styles.logoutbutton} onClick={userLogout}>Logout</button>
        </div>
      </div>
    </div>
  )
}

export default SideBar
