import { Button } from 'primereact/button';
import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const EduButton = ({
    label="Add Item", 
    severity="primary", 
    type="", 
    icon="",
    actionButton= () => (""),
    outline=false,
    width="100%",
    disable=false
}) => {
  if (type === "add") {
    return (
      <Button 
        label={label} 
        icon="pi pi-plus" 
        className='rounded buttonAdd'
        iconPos='right'
        onClick={actionButton}
      />
    )
  } 

  if (type === "action"){
    return (
      <Button 
        icon={icon} 
        className='rounded mr-2'
        iconPos='right'
        severity={severity}
        onClick={actionButton}
        outlined={outline}
      />
    )
  }

  if (type === "link") {
    return (
      <Link target="_blank" to={actionButton}>
        <Button 
          label={label}
          className='rounded buttonLink'
          outlined={outline}
        />
      </Link>
    )
  }

  return (
    <Button 
      icon={icon} 
      label={label}
      className='rounded my-1'
      iconPos='right'
      severity={severity}
      onClick={actionButton}
      outlined={outline}
      style={{width: width}}
      disabled={disable}
    />
  )


}

export default EduButton
