import React, { useEffect, useRef, useState } from 'react'
import styles from './ListClass.module.css'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { BASE_API_URL, formattedDate, longStringToDate } from '../../helpers/general'
import axios from 'axios'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { FilterMatchMode, FilterService } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import Loading from '../../components/Loading'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { IconFamily } from '../../assets'
import { Badge } from 'primereact/badge'
import { InputTextarea } from 'primereact/inputtextarea'

FilterService.register('custom_sesiTerlaksana', (value, filters) => {
  const [from, to] = filters ?? [null, null];
  if (from === null && to === null) return true;
  if (from !== null && to === null) return from <= value;
  if (from === null && to !== null) return value <= to;
  return from <= value && value <= to;
});

FilterService.register('custom_sesiBelumTerlaksana', (value, filters) => {
  const [from, to] = filters ?? [null, null];
  if (from === null && to === null) return true;
  if (from !== null && to === null) return from <= value;
  if (from === null && to !== null) return value <= to;
  return from <= value && value <= to;
})

const ListClass = () => {
  const [ listClass, setListClass ] = useState(null)
  const [ listVerifiedClaSS, setListVerifiedClass ] = useState(null)
  const [ listUnVerifiedClass, setListUnVerifiedClass ] = useState(null)
  const [ listActiveClass, setListActiveClass ] = useState(null)
  const [ listNeedPTCClaSS, setListNeedPTCClass ] = useState(null)
  const [ listNeedRRClaSS, setListNeedRRClass ] = useState(null)
  const [ listNeedEndClaSS, setListNeedEndClass ] = useState(null)
  const [ listFinishClass, setListFinishClass ] = useState(null)
  const [ listDetailCountClass, setListDetailCountClass ] = useState(null)
  const [ detailClass, setDetailClass] = useState({
    _id: '',
    kodeBatch: null,
    kodeKelas: null,
    namaKelas: '',
    level: null,
    jenisKelas: '',
    materiKelas: null,
    jadwal: null,
    guru: null,
    statusKelas: '',
    followUp: '',
    keterangan: ''
  })
  const [ addUpdateClassDialog, setAddUpdateClassDialog] = useState(false)
  const [ deleteClassDialog, setDeleteClassDialog ] = useState(false)
  const [ rejectClassDialog, setRejectClassDialog ] = useState(false)
  const [ verifClassDialog, setVerifClassDialog ] = useState(false)
  const [ updateStatusDialog, setUpdateStatusDialog ] = useState(false)
  const [ errorDialog, setErrorDialog ] = useState({
    status: false,
    title: '',
    message: ''
  })
  const [ followUpDialog, setFollowUpDialog ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ dialogTitle, setDialogTitle ] = useState(null)
  const accessToken = localStorage.getItem('accessToken')
  const [ refreshTable, setRefreshTable ] = useState(false)
  const dt = useRef(null)
  const [ listTeachers, setListTeachers ] = useState(null)
  const classType = [
    {
      id: '1-PRV',
      name: 'Private'
    },
    {
      id: '2-SML',
      name: 'Small'
    },
  ]
  const [ activeKey, setActiveKey ] = useState(0)
  const listSubject = [
    {
      name: "Adab",
      code: "Adab"
    }, 
    {
      name: "AFQ",
      code: 'AFQ'
    },
    {
      name: "IC Atas",
      code: "IC Atas"
    },
    {
      name: "IC Bawah",
      code: "IC Bawah"
    },
    {
      name: "ICWS",
      code: "ICWS"
    },
    {
      name: "IE",
      code: "IE"
    },
    {
      name: "IL",
      code: "IL"
    },
    {
      name: "IL & SoIP",
      code: "IL & SoIP"
    }, 
    {
      name: "NI",
      code: "NI"
    }, 
    {
      name: "QT",
      code: "QT"
    }
  ]
  const statusKelas = ['Aktif', 'Belum PTC', 'Belum RR', 'Belum diselesaikan']

  const getAllTeachers = async () => {
    const optionsGetTeachers = {
      method: 'get',
      url: `${BASE_API_URL}/teachers`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      }
    }

    await axios.request(optionsGetTeachers)
      .then((response) => {
        setListTeachers(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetDetailClass = () => {
    setDetailClass({
      _id: '',
      kodeKelas: null,
      kodeBatch: null,
      namaKelas: '',
      level: null,
      materiKelas: null,
      jenisKelas: '',
      jadwal: null,
      guru: null,
      statusKelas: '',
      followUp: '',
      keterangan: ''
    })
  }

  const getAllFinishClass = async () => {
    setLoading(true);

    const optionGetAllClass = {
      method: 'get',
      url: `${BASE_API_URL}/class-finish`,
      headers:  {
        'Authorization': accessToken
      }
    }

    await axios.request(optionGetAllClass)
      .then((response) => {
        if (response.status === 200) {
          const listClass = response.data;

          listClass.map((kelas, index) => {
            kelas["index"] = index + 1;
            kelas["subjekKelas"] = `${kelas.namaKelas} - Lvl ${kelas.level} (${kelas.jenisKelas}) - ${kelas.kodeBatch}.${kelas.kodeKelas} - Materi ${kelas.materiKelas}`;
            kelas["jadwal"] = formattedDate(kelas['jadwal'])
            return kelas;
          }); 
          // getFinishClass(listClass)
          setListFinishClass(listClass);
        }
      })
      .catch((error) =>  {
        console.log(error);
        setLoading(false);
      })
  }

  const getAllClass = async () => {
    setLoading(true)

    const optionGetAllCalss = {
        method: 'get',
        url: `${BASE_API_URL}/class`,
        headers: {
            'Authorization': accessToken
        }
    }

    await axios.request(optionGetAllCalss)
      .then((response) => {
        if(response.status == '200'){
            const listClass = response.data
            const verifiedClass = []
            const unVerifiedClass = []
            const activeClass = []
            const needPTC = []
            const needRR = []
            const needEnd = []
            // const finishClass = []

            listClass.map((kelas,index) => {
                const dataListAttendance = kelas.absensi;
                const listSesi = [];
                let terlaksana = 0, belumTerlaksana = 0;
                kelas['jadwal'] = formattedDate(kelas['jadwal'])
    
                // pengecekan apakah ada attendance / students
                if (Array.isArray(dataListAttendance) && dataListAttendance.length !== 0) {
                    const lengthSession = dataListAttendance[0].session.length;
    
                    // looping tiap attendance per student
                    dataListAttendance.map((data) => {
                        // looping tiap sesi per attendance
                        data.session.map((sesi) => {
                            if (sesi.status !== "Belum diabsen" && !listSesi.find((data) => data === sesi.sesi)) {
                                terlaksana++
                                listSesi.push(sesi.sesi)
                            }
                        })

                        return data;
                    })
                    belumTerlaksana = lengthSession - terlaksana
                }

                kelas["index"] = index + 1
                kelas["subjekKelas"] = `${kelas.namaKelas} - Lvl ${kelas.level} (${kelas.jenisKelas}) - ${kelas.kodeBatch}.${kelas.kodeKelas} - Materi ${kelas.materiKelas}`
                kelas.sesiTerlaksana = terlaksana
                kelas.sesiBelumTerlaksana = belumTerlaksana

                if (kelas.statusKelas !== "Belum terverifikasi") {
                  // kelas["index"] = index + 1
                  // kelas["subjekKelas"] = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl ${kelas.level}`
                  verifiedClass.push(kelas)
                }

                switch (kelas.statusKelas) {
                  case "Belum terverifikasi":
                    // kelas["index"] = index + 1
                    // kelas["subjekKelas"] = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl ${kelas.level}`
                    unVerifiedClass.push(kelas)
                    break;
                  
                  case "Aktif":
                    // kelas["index"] = index + 1
                    // kelas["subjekKelas"] = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl ${kelas.level}`
                    activeClass.push(kelas)
                    break;
                  
                  case "Belum PTC":
                    needPTC.push(kelas)
                    break;
                  
                  case "Belum RR":
                    needRR.push(kelas)
                    break;
                  
                  case "Belum Diselesaikan": 
                    needEnd.push(kelas)
                    break;

                  default:
                    break;
                }
            })

            setListClass(listClass)
            // getVerifiedClass(listClass)
            setListVerifiedClass(verifiedClass)
            // getUnVerifiedClass(listClass)
            setListUnVerifiedClass(unVerifiedClass)
            // getActiveClass(listClass)
            setListActiveClass(activeClass)
            // getNeedPTCClass(listClass)
            setListNeedPTCClass(needPTC)
            // getNeedRRClass(listClass)
            setListNeedRRClass(needRR)
            // getNeedEndClass(listClass)
            setListNeedEndClass(needEnd)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getDetailCount = async () => {
    // setLoading(true)

    const optionGetDetailCount = {
        method: 'get',
        url: `${BASE_API_URL}/getClassesStatusCountsAndStudents`,
        headers: {
            'Authorization': accessToken
        }
    }

    await axios.request(optionGetDetailCount)
      .then((response) => {
        if(response.status == '200'){
            setListDetailCountClass(response.data)
            // setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        // setLoading(false)
      })
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const getSeverity = (data) => {
    switch (data) {
        case "Belum terverifikasi":
          return "primary";

        case "Aktif":
          return "info";
        
        case "Selesai":
          return "success";
        
        default:
            return "danger";
    }
  }

  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    sesiTerlaksana: { value: null, matchMode: FilterMatchMode.CUSTOM },
    sesiBelumTerlaksana: { value: null, matchMode: FilterMatchMode.CUSTOM},
    statusKelas: { value: null, matchMode: FilterMatchMode.EQUALS},
    subjekKelas: { value: null, matchMode: FilterMatchMode.CONTAINS},
    'guru.nama': { value: null, matchMode: FilterMatchMode.CONTAINS},
    jadwal: { value: null, matchMode: FilterMatchMode.CONTAINS},
    jumlahSiswa: { value: null, matchMode: FilterMatchMode.EQUALS}
  }) 

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const classChange = (event) => {
    const { name, value } = event.target

    setDetailClass((currentClass) => ({
      ...currentClass,
      [name]: value
    }))
  }

  useEffect(() => {
    getAllTeachers()
    getDetailCount()
    getAllClass()
    getAllFinishClass()
  }, [refreshTable])

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
                <p className={styles.cardTitle}>Daftar Kelas</p>
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    <Button className={styles.buttonAddClass} onClick={() =>showDialog(null ,"Tambah Data Kelas")}>Tambah Kelas +</Button>
                </span>
            </div>
        </div>
    );
  };

  const statusClassTemplate = (data) => {
    return <Tag value={data} severity={getSeverity(data)}></Tag>
  }

  const actionBodyTemplate = (rowdata) => {
    return (
        <React.Fragment>
            <Button icon="pi pi-pencil" rounded outlined className='mr-2' onClick={() => showDialog(rowdata, "Edit Data Kelas")}/>
            <Button icon="pi pi-trash" severity='danger' onClick={() => showDeleteDialog(rowdata, 'Delete')} />
            <a href={`/classes/${rowdata._id}`}><Button icon="pi pi-eye" className='ml-2' rounded outlined severity='success' /></a>
        </React.Fragment>
    )
  }

  const actionBodyTemplateUnverified = (rowdata) => {
    return (
        <React.Fragment>
            <Button className={styles.labelButton} icon="pi pi-check-circle" label='Verifikasi' severity='success' onClick={() => showDialog(rowdata ,'Verifikasi Kelas')}/>
            <Button className={styles.labelButton} icon="pi pi-eraser" label='Tolak' outlined severity='danger' onClick={() => showDeleteDialog(rowdata, 'Reject')}/>
        </React.Fragment>
    )
  }

  const actionBodyTemplateUnfinished = (rowdata) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className='mr-2' onClick={() => showDialog(rowdata, "Edit Data Kelas")}/>
        <Button icon="pi pi-trash" severity='danger' onClick={() => showDeleteDialog(rowdata, 'Delete')} />
        <a href={`/classes/${rowdata._id}`}><Button icon="pi pi-eye" className='mx-2' rounded outlined severity='success' /></a>
        {
          rowdata.followUp == 'belum' ? 
            <Button icon='pi pi-times' severity='warning' tooltip="Follow-Up Kelas" tooltipOptions={{ position: 'top' }}  onClick={() => showDialog(rowdata, "Update Follow Up")} rounded /> :
            <Button icon='pi pi-verified' severity='success'  tooltip="Kelas Sudah diFollow-Up" tooltipOptions={{ position: 'top' }} onClick={() => showDialog(rowdata, "Update Follow Up")}  rounded />
        }
      </React.Fragment>
    )
  }

  const showDialog = (data, titleDialog) => {
    setDialogTitle(titleDialog)
    
    if (data != null) {
      setDetailClass({
        _id: data._id,
        kodeBatch: data.kodeBatch,
        kodeKelas: data.kodeKelas,
        namaKelas: data.namaKelas,
        level: data.level,
        materiKelas: data.materiKelas,
        jenisKelas: data.jenisKelas,
        jadwal: longStringToDate(data.jadwal),
        guru: data.guru._id,
        murid: data.murid,
        absensi: data.absensi,
        statusKelas: data.statusKelas,
        followUp: data.followUp,
        keterangan: data.keteranganFollowUp
      })
    }
    
    if (titleDialog == "Edit Data Kelas" || titleDialog == "Tambah Data Kelas") setAddUpdateClassDialog(true)

    if (titleDialog == "Update Status Kelas") {
        setDetailClass((currentData) => ({
            ...currentData,
            statusKelas: data.statusKelas === "Aktif" ? "Berhenti" : "Aktif"
        }))

        setUpdateStatusDialog(true)
    }

    if (titleDialog == "Update Follow Up") {
      console.log(detailClass)
      setFollowUpDialog(true)
    }

    if (titleDialog == "Verifikasi Kelas") {
        setDetailClass((currentData) => ({
            ...currentData,
            statusKelas: "Aktif"
        }))
        setVerifClassDialog(true)
    }
  }

  const showDeleteDialog = (data, type) => {
    setDetailClass({
      _id: data._id,
      kodeKelas: data.kodeKelas,
      namaKelas: data.namaKelas
    })

    if (data.murid.length !== 0) {
      setErrorDialog({
        status: true,
        title: 'Gagal Hapus Kelas',
        message: `Kelas ${data.kodeBatch}.${data.kodeKelas} ${data.namaKelas} gagal dihapus karena kelas sedang berjalan dan memiliki murid`
      })
    } else {
      if (type === 'Reject') setRejectClassDialog(true)
      else setDeleteClassDialog(true)
    }
  }

  const hideDialog = (typeDialog) => {
    if (typeDialog === "AddUpdate") setAddUpdateClassDialog(false)
    if (typeDialog === "Verification") setVerifClassDialog(false)
    if (typeDialog === "UpdateStatus") setUpdateStatusDialog(false)
    if (typeDialog === "Delete") setDeleteClassDialog(false)
    if (typeDialog === "Reject") setRejectClassDialog(false)
    if (typeDialog === "Error") setErrorDialog({
      status: false,
      title: '',
      message: ''
    })
    if (typeDialog === "FollowUp") setFollowUpDialog(false)

    resetDetailClass()
  }

  const addUpdateDialogFooter = () => (
      <React.Fragment>
          <Button label="Cancel" icon="pi pi-times" outlined onClick={() => hideDialog("AddUpdate")} className='mr-3'/>
          <Button label="Save" icon="pi pi-check" onClick={() => saveHandler()}/>
      </React.Fragment>
  );

  const followUpDialogFooter = () => {
    const status = ["belum", "1 hari", "2 hari"]
        if (detailClass.followUp == "Selesai") {
          return <Button label="Tutup" icon="pi pi-times" outlined onClick={() => hideDialog("FollowUp")} className='mr-3'/>
        } else if (status.find((status) => status == detailClass.followUp)) {
          return (
            <React.Fragment>
              <Button label="Cancel" icon="pi pi-times" outlined onClick={() => hideDialog("FollowUp")} className='mr-3'/>
              <Button label="Save" icon="pi pi-check" onClick={() => followUp(detailClass)}/>
            </React.Fragment>
          )
        } 
  } 

  const saveHandler = () => {
    if (detailClass._id === '') {
      addClass()
    } else {
      updateClass()
    }
  }

  const rejectDialogFooter = (type) => (
      <React.Fragment>
          <div className='flex-auto text-center'>
              <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog(type)}/>
              <Button label="Yakin" icon="pi pi-check" severity="danger" onClick={() => deleteClass()}/>
          </div>
      </React.Fragment>
  )

  const verifDialogFooter = () => (
      <React.Fragment>
          <div className='flex-auto text-center'>
              <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog("Verification")}/>
              <Button label="Yakin" icon="pi pi-check" severity="primary" onClick={() => updateClass("Verification")}/>
          </div>
      </React.Fragment>
  )

  const errorDialogFooter = () => (
    <div className='flex-auto text-center'>
        <Button label="Oke" className='mr-3' severity='warning' onClick={() => hideDialog("Error")}/>
    </div>
  )

  const followUp = async () => {
    hideDialog("FollowUp")
    setLoading(true)

    const optionsFollowUp = {
      method: 'put',
      url: `${BASE_API_URL}/classes/set-follow-up-complete`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        classId: detailClass._id,
        keterangan: detailClass.keterangan
      }
    }

    await axios.request(optionsFollowUp)
      .then(() => {
        setRefreshTable(!refreshTable)
        setTimeout(() => {
          setLoading(false)
        }, 3000)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const deleteClass = async () => {
    hideDialog("Delete")
    hideDialog("Reject")
    setLoading(true)

    const optionsDeleteClass = {
      method: 'delete',
      url: `${BASE_API_URL}/class/${detailClass._id}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionsDeleteClass)
      .then(() => {
        setRefreshTable(!refreshTable)
      })
      .catch((error) => {
        console.log(error)
      })

    setLoading(false)
  }

  const updateClass = async () => {
    hideDialog("AddUpdate")
    hideDialog("Verification")
    setLoading(true)

    const optionsUpdateClass = {
      method: 'put',
      url: `${BASE_API_URL}/class/${detailClass._id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dialogTitle == "Verifikasi Kelas" ? {
        statusKelas: detailClass.statusKelas
      } : detailClass
    }

    console.log(detailClass)

    await axios.request(optionsUpdateClass)
      .then(() => {
        resetDetailClass()
        getAllClass()
      })
      .catch((error) => {
        console.log(error)
      })
    
    setLoading(false)
  }

  const addClass = async () => {
    hideDialog("AddUpdate")
    console.log(detailClass)
    const optionsUpdateClass = {
      method: 'post',
      url: `${BASE_API_URL}/newclass`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      },
      data: {
        kodeBatch: detailClass.kodeBatch,
        kodeKelas: detailClass.kodeKelas,
        namaKelas: detailClass.namaKelas,
        jenisKelas: detailClass.jenisKelas,
        jadwal: detailClass.jadwal,
        level: detailClass.level,
        materiKelas: detailClass.materiKelas,
        guru: detailClass.guru
      }
    }

    await axios.request(optionsUpdateClass)
      .then(() => {
        resetDetailClass()
        setRefreshTable(!refreshTable)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const headerBadge = (title, length) => (
    <div className='d-flex gap-2 align-items-center'>
      {title}
      <Badge value={length} severity="warning"/>
    </div>
  )

  // filter program 
  const sesiTerlaksanaRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null];

    return (
        <div className="flex gap-1 align-items-center">
            <InputNumber value={from} onChange={(e) => options.filterApplyCallback([e.value, to])} className="w-full" placeholder="from" />
            <p style={{margin: '0'}}>-</p>
            <InputNumber value={to} onChange={(e) => options.filterApplyCallback([from, e.value])} className="w-full" placeholder="to" />
        </div>
    );
  };

  const sesiBelumTerlaksanaRowFilterTemplate = (options) => {
    const [from, to] = options.value ?? [null, null];

    return (
        <div className="flex gap-1 align-items-center">
            <InputNumber value={from} onChange={(e) => options.filterApplyCallback([e.value, to])} className="w-full" placeholder="from" />
            <p style={{margin: '0'}}>-</p>
            <InputNumber value={to} onChange={(e) => options.filterApplyCallback([from, e.value])} className="w-full" placeholder="to" />
        </div>
    );
  };

  const statusKelasRowFilterTemplate = (options) => {
    return (
        <Dropdown value={options.value} options={statusKelas} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusClassTemplate} 
         placeholder="Choose" className="p-column-filter" style={{maxWidth: '10rem' }} showClear />
    );
  };

  const subjekKelasRowFilterTemplate = (options) => {
    return (
      <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Input subject"/>
    )
  }

  const paginatorLeft = <Button type='button' icon="pi pi-refresh" text onClick={() => getAllClass()}></Button>
  const paginatorRight = <Button type='button' icon="pi pi-download" text onClick={() => exportCSV()}></Button>

  return (
    <ContainerPage>
        <Loading visible={loading}/>
        <Dialog visible={addUpdateClassDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                footer={addUpdateDialogFooter} onHide={() => hideDialog("AddUpdate")} header={dialogTitle}>
          <div className='flex flex-wrap gap-2 p-fluid mb-3'>
            <div className='flex-auto'>
              <label htmlFor="kodeKelas" className='font-bold block mb-2'>Kode Kelas</label>
              <div className={styles.classCodeLabel}>
                <InputNumber id='kodeBatch' name='kodeBatch' value={detailClass.kodeBatch} useGrouping={false} onValueChange={classChange} required autoFocus minFractionDigits={0}/>
                <span className={styles.separateDot}>.</span>
                <InputNumber id='kodeKelas' name='kodeKelas' value={detailClass.kodeKelas} useGrouping={false} onValueChange={classChange} required autoFocus minFractionDigits={0}/>
              </div>
            </div>
            <div className='flex-auto'>
              <label htmlFor="class-type" className='font-bold block mb-2'>Type Kelas</label>
              <Dropdown id='id' name='jenisKelas' options={classType} value={detailClass.jenisKelas} optionValue='name' optionLabel="name" filter 
              placeholder="Masukkan tipe kelas" onChange={classChange} className='w-full'/>
            </div>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="name" className='font-bold block mb-2'>Subjek Kelas</label>
            <Dropdown value={detailClass.namaKelas} id='namaKelas' name='namaKelas' onChange={classChange} options={listSubject} optionValue='code' optionLabel='name' 
              editable placeholder="Choose Subject Class" className="w-full" />
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="level" className='font-bold block mb-2'>Level</label>
            <InputNumber id='level' name='level' value={detailClass.level} onValueChange={classChange} className='w-full' required autoFocus min={1} max={20} minFractionDigits={0} maxFractionDigits={2}/>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="materiKelas" className='font-bold block mb-2'>Level Materi</label>
            <InputNumber id='materiKelas' name='materiKelas' value={detailClass.materiKelas} onValueChange={classChange} className='w-full' required autoFocus min={1} max={20} minFractionDigits={0} maxFractionDigits={2}/>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="jadwal-rilis" className='font-bold block mb-2'>Jadwal Mulai</label>
            <Calendar className='w-full' name='jadwal' value={detailClass.jadwal} onChange={classChange} 
            showTime hourFormat='24' showIcon dateFormat='dd MM yy -'/>
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="jadwal-rilis" className='font-bold block mb-2'>Guru</label>
            <Dropdown id='guru' name='guru' value={detailClass.guru} filter onChange={detailClass._id === '' ? classChange : false} options={listTeachers} optionLabel='nama' 
              optionValue='_id' className='w-full'/>
          </div>
        </Dialog>

        <Dialog visible={followUpDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                footer={followUpDialogFooter} onHide={() => hideDialog("FollowUp")} header={dialogTitle}>
            <div className='flex flex-wrap gap-2 p-fluid mb-3'>
              <div className='flex-auto'>
                <label htmlFor="keterangan" className='font-bold block mb-2'>Keterangan Setelah DiFollow-Up</label>
                <InputTextarea id='keterangan' name='keterangan' value={detailClass.keterangan} onChange={classChange} required autoFocus/>
              </div>
            </div>
        </Dialog>

        <Dialog visible={rejectClassDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Tolak Verifikasi Kelas" footer={rejectDialogFooter("Reject")} onHide={() => hideDialog("Reject")}>
            <div className="confirmation-content text-center">
                <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                <h5>Yakin ingin menolak verifikasi "<strong>{detailClass.kodeKelas}</strong> {detailClass.namaKelas}"?</h5>
            </div>
        </Dialog>

        <Dialog visible={deleteClassDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Hapus Kelas" footer={rejectDialogFooter("Delete")} onHide={() => hideDialog("Delete")}>
            <div className="confirmation-content text-center">
                <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                <h5>Yakin ingin menghapus kelas "<strong>{detailClass.kodeKelas}</strong> {detailClass.namaKelas}"?</h5>
            </div>
        </Dialog>

        <Dialog visible={verifClassDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Verifikasi Pendaftaran Guru" footer={verifDialogFooter} onHide={() => hideDialog("Verification")}>
            <div className="confirmation-content text-center">
                <i className="pi pi-check-circle mb-4" style={{ fontSize: '6rem', color: 'var(--primary-color)' }}/>
                <h5>Yakin ingin memverifikasi pendaftaran "<strong>{detailClass.kodeKelas}</strong> {detailClass.namaKelas}"?</h5>
            </div>
        </Dialog>

        <Dialog visible={updateStatusDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Perubahan Status Guru" footer={verifDialogFooter} onHide={() => hideDialog("UpdateStatus")}>
            <div className="confirmation-content text-center">
                <i className="pi pi-check-circle mb-4" style={{ fontSize: '6rem', color: 'var(--primary-color)' }}/>
                <h5>Yakin ingin mengubah status guru ?</h5>
            </div>
        </Dialog>

        <Dialog visible={errorDialog.status} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
         header={errorDialog.title} footer={errorDialogFooter} onHide={() => hideDialog('Error')}>
          <div className='text-center'>
            <i className="pi pi-exclamation-circle" style={{ fontSize: '6rem', color: 'var(--yellow-400)' }}></i>
            <p style={{fontSize: '22', fontWeight: '500'}} className='mt-3 mb-0'>{errorDialog.message}</p>
          </div>
        </Dialog>

        <div className={styles.containerNotif}>
          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Kelas Aktif</p>
              <button onClick={() => setActiveKey(0)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-sync'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <p className={styles.cardValue}>{listActiveClass !== null? listActiveClass.length : 0} Kelas</p>
              </div>
            </Card.Body>
          </Card>

          <Card  className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Belum PTC</p>
              <button onClick={() => setActiveKey(1)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
          <Card.Body className={styles.cardNotifContent}>
              <img src={IconFamily} alt="" />
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className='d-flex gap-2'>
                  <p className={styles.cardValue}>{listNeedPTCClaSS !== null? listNeedPTCClaSS.length : 0} Kelas</p>
                  <p className={styles.cardValue}>{listDetailCountClass !== null? listDetailCountClass.totalStudentNotPTC : 0} Siswa</p>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Belum RR</p>
              <button onClick={() => setActiveKey(2)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-exclamation-triangle'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className="d-flex gap-2">
                  <p className={styles.cardValue}>{listNeedRRClaSS !== null? listNeedRRClaSS.length : 0} Kelas</p>
                  <p className={styles.cardValue}>{listDetailCountClass !== null? listDetailCountClass.totalStudentNotRR : 0} Siswa</p>
                </div>
              </div>
            </Card.Body>
          </Card>
          
          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Belum Diselesaikan</p>
              <button onClick={() => setActiveKey(3)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-minus-circle'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className='d-flex gap-2'>
                <p className={styles.cardValue}>{listNeedEndClaSS !== null? listNeedEndClaSS.length : 0} Kelas</p>
                <p className={styles.cardValue}>{listDetailCountClass !== null? listDetailCountClass.totalStudentNotCompleted : 0} Siswa</p>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Kelas Selesai</p>
              <button onClick={() => setActiveKey(4)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
            <i className='pi pi-check'></i>
              <div>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className='d-flex gap-2'>
                  <p className={styles.cardValue}>{listFinishClass !== null? listFinishClass.length : 0} Kelas</p>
                  <p className={styles.cardValue}>{listDetailCountClass !== null? listDetailCountClass.totalStudentCompleted : 0} Siswa</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <Card>
            <Card.Body>
                <Tabs defaultActiveKey={0} className={styles.myTabs} activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                    <Tab eventKey={0} title="Kelas Terverifikasi">
                      <div>
                          <DataTable value={listVerifiedClaSS} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                              rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                              globalFilterFields={['subjekKelas', 'namaKelas', 'guru.nama', 'kodeKelas', 'level', 'jadwal', 'jenisKelas', 'statusKelas', 'namaGuru', 'jumlahSiswa', 'siswaLanjut', 'sesiTerlaksana']}
                              header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt} filterDisplay="row"
                          >
                              <Column frozen header="No" body={(data, options) => options.rowIndex + 1}></Column>
                              <Column field="subjekKelas" frozen sortable header="Subjek Kelas" style={{ minWidth: '17rem', fontWeight: 'bold' }} filter filterPlaceholder='Search by subject'></Column>
                              <Column field="guru.nama" sortable header="Nama Guru" style={{ minWidth: '17rem' }} filter filterPlaceholder='Search by teacher'></Column>
                              <Column field="jadwal" sortable header="Jadwal Mulai" style={{ minWidth: '16rem' }} filter filterPlaceholder='Search by schedule'></Column>
                              <Column field="statusKelas" sortable header="Status" body={(e) => statusClassTemplate(e.statusKelas)} filter filterElement={statusKelasRowFilterTemplate} showFilterMenu={false}
                               style={{ minWidth: '7rem' }}></Column>
                              <Column field="sesiTerlaksana" sortable header="Sesi Terlaksana" filter filterElement={sesiTerlaksanaRowFilterTemplate} showFilterMenu={false} 
                                style={{ minWidth: '13rem', textAlign: 'center' }}></Column>
                              <Column field="sesiBelumTerlaksana" sortable header="Sesi Belum Terlaksana" filter filterElement={sesiBelumTerlaksanaRowFilterTemplate} showFilterMenu={false}
                                style={{ minWidth: '13rem',  textAlign: 'center'  }}></Column>
                              <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '7rem' }} filter showFilterMenu={false}></Column>
                              <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                          </DataTable>
                      </div>
                    </Tab>
                    <Tab eventKey={1} title="Belum PTC">
                      <div>
                          <DataTable value={listNeedPTCClaSS} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                              rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                              globalFilterFields={['subjekKelas', 'namaKelas', 'guru.nama', 'kodeKelas', 'level', 'jadwal', 'jenisKelas', 'statusKelas', 'namaGuru', 'jumlahSiswa', 'siswaLanjut']}
                              header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                          >
                              <Column field="index" frozen sortable header="No"></Column>
                              <Column field="subjekKelas" frozen sortable header="Subjek Kelas" style={{ minWidth: '17rem', fontWeight: 'bold' }}></Column>
                              <Column field="guru.nama" sortable header="Nama Guru" style={{ minWidth: '12rem' }}></Column>
                              <Column field="jadwal" sortable header="Jadwal Mulai" style={{ minWidth: '15rem' }}></Column>
                              <Column field="statusKelas" sortable header="Status" body={(e) => statusClassTemplate(e.statusKelas)} style={{ minWidth: '10rem' }}></Column>
                              <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '5rem' }}></Column>
                              <Column body={actionBodyTemplateUnfinished} style={{minWidth: '17rem'}} frozen alignFrozen='right'></Column>
                          </DataTable>
                      </div>
                    </Tab>
                    <Tab eventKey={2} title="Belum RR">
                      <div>
                          <DataTable value={listNeedRRClaSS} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                              rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                              globalFilterFields={['subjekKelas', 'namaKelas', 'guru.nama', 'kodeKelas', 'level', 'jadwal', 'jenisKelas', 'statusKelas', 'namaGuru', 'jumlahSiswa', 'siswaLanjut']}
                              header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                          >
                              <Column field="index" frozen sortable header="No"></Column>
                              <Column field="subjekKelas" frozen sortable header="Subjek Kelas" style={{ minWidth: '17rem', fontWeight: 'bold' }}></Column>
                              <Column field="guru.nama" sortable header="Nama Guru" style={{ minWidth: '12rem' }}></Column>
                              <Column field="jadwal" sortable header="Jadwal Mulai" style={{ minWidth: '15rem' }}></Column>
                              <Column field="statusKelas" sortable header="Status" body={(e) => statusClassTemplate(e.statusKelas)} style={{ minWidth: '10rem' }}></Column>
                              <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '5rem' }}></Column>
                              <Column body={actionBodyTemplateUnfinished} style={{minWidth: '17rem'}} frozen alignFrozen='right'></Column>
                          </DataTable>
                      </div>
                    </Tab>
                    <Tab eventKey={3} title="Belum Diselesaikan">
                      <div>
                          <DataTable value={listNeedEndClaSS} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                              rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                              globalFilterFields={['subjekKelas', 'namaKelas', 'guru.nama', 'kodeKelas', 'level', 'jadwal', 'jenisKelas', 'statusKelas', 'namaGuru', 'jumlahSiswa', 'siswaLanjut']}
                              header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                          >
                              <Column field="index" frozen sortable header="No"></Column>
                              <Column field="subjekKelas" frozen sortable header="Subjek Kelas" style={{ minWidth: '17rem', fontWeight: 'bold' }}></Column>
                              <Column field="guru.nama" sortable header="Nama Guru" style={{ minWidth: '12rem' }}></Column>
                              <Column field="jadwal" sortable header="Jadwal Mulai" style={{ minWidth: '15rem' }}></Column>
                              <Column field="statusKelas" sortable header="Status" body={(e) => statusClassTemplate(e.statusKelas)} style={{ minWidth: '10rem' }}></Column>
                              <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '5rem' }}></Column>
                              <Column body={actionBodyTemplateUnfinished} style={{minWidth: '17rem'}} frozen alignFrozen='right'></Column>
                          </DataTable>
                      </div>
                    </Tab>
                    <Tab eventKey={4} title="Kelas Selesai">
                      <div>
                          <DataTable value={listFinishClass} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                              rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                              globalFilterFields={['subjekKelas', 'namaKelas', 'guru.nama', 'kodeKelas', 'level', 'jadwal', 'jenisKelas', 'statusKelas', 'namaGuru', 'jumlahSiswa', 'siswaLanjut']}
                              header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                          >
                              <Column field="index" frozen sortable header="No"></Column>
                              <Column field="subjekKelas" frozen sortable header="Subjek Kelas" style={{ minWidth: '17rem', fontWeight: 'bold' }}></Column>
                              <Column field="guru.nama" sortable header="Nama Guru" style={{ minWidth: '12rem' }}></Column>
                              <Column field="jadwal" sortable header="Jadwal Mulai" style={{ minWidth: '15rem' }}></Column>
                              <Column field="statusKelas" sortable header="Status" body={(e) => statusClassTemplate(e.statusKelas)} style={{ minWidth: '10rem' }}></Column>
                              <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '5rem' }}></Column>
                              <Column body={actionBodyTemplate} style={{minWidth: '12rem'}} frozen alignFrozen='right'></Column>
                          </DataTable>
                      </div>
                    </Tab>
                    <Tab eventKey={5} title={headerBadge("Butuh Verifikasi", (listUnVerifiedClass == null? 0: listUnVerifiedClass.length))}>
                      <div>
                          <DataTable value={listUnVerifiedClass} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                              rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                              globalFilterFields={['subjekKelas', 'namaKelas', 'guru.nama', 'level', 'jadwal', 'jenisKelas', 'statusKelas', 'namaGuru', 'jumlahSiswa', 'siswaLanjut']}
                              header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                          >
                              <Column field="index" frozen sortable header="No"></Column>
                              <Column field="subjekKelas" frozen sortable header="Subjek Kelas" style={{ minWidth: '17rem', fontWeight: 'bold' }}></Column>
                              <Column field="guru.nama" sortable header="Nama Guru" style={{ minWidth: '12rem' }}></Column>
                              <Column field="jadwal" sortable header="Jadwal Mulai" style={{ minWidth: '15rem' }}></Column>
                              <Column field="statusKelas" sortable header="Status" body={(e) => statusClassTemplate(e.statusKelas)} style={{ minWidth: '10rem' }}></Column>
                              <Column field="jumlahSiswa" sortable header="Siswa" style={{ minWidth: '5rem' }}></Column>
                              <Column body={actionBodyTemplateUnverified} style={{minWidth: '14rem'}} frozen alignFrozen='right'></Column>
                          </DataTable>   
                      </div>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    </ContainerPage>
  )
}

export default ListClass