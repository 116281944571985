import React, { useEffect, useRef, useState } from 'react'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import Loading from '../../components/Loading'
import styles from './UnPaidClass.module.css'
import { Card } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { BASE_API_URL } from '../../helpers/general'
import axios from 'axios'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

const UnPaidClass = () => {
  const [ loading, setLoading ] = useState(false);
  const [ listUnPaidClass, setListUnPaidClass ] = useState(null);
  const [ listClass, setListClass ] = useState(null);
  const dt = useRef(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [ detailKelasDialog, setDetailKelasDialog ] = useState(false);

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getUnPaidClass = async () => {
    setLoading(true)

    const optionUnPaidClass = {
        method: 'GET',
        url: `${BASE_API_URL}/unpaid-students`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionUnPaidClass)
      .then((response) => {
        const listUnPaidClass = response.data.students;

        listUnPaidClass.map((unPaidClass) => {
            unPaidClass.jumlahKelas = unPaidClass.kelasBelumTerbayar.length;
        })

        setListUnPaidClass(listUnPaidClass)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getUnPaidClass()
  }, [])

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const refreshTable = () => {
    getUnPaidClass()
  }

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Daftar Murid Belum Membayar</p>
            </div>
            <div>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
              </span>
            </div>
        </div>
    );
  };

  const renderHeaderDetailKelas = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Detail Kelas</p>
            </div>
            <div>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
              </span>
            </div>
        </div>
    );
  };

  const labelWA = (data) => {
    return (
      <a href={`https://wa.me/${data.nomor}`} target="_blank" className={styles.buttonLabel}>
        <div className={styles.myButton}>
          <i className='pi pi-whatsapp'></i>
          <span>{data.nomor}</span>
        </div>
      </a>
    )
  }

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={() => refreshTable()} />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => exportCSV(false)} />;
  
  const actionBodyTemplate = (rowdata) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-eye" label='Detail Kelas' severity='info' outlined className='mr-2' onClick={() => showDialog("Detail Kelas", rowdata)} style={{borderRadius: '10px'}}/>
        <Button label='Follow Up?' severity='danger' onClick={() => ('')} style={{borderRadius: '10px'}}/>
      </React.Fragment>
    )
  }

  const showDialog = (type, data) => {
    if (type == "Detail Kelas") {
        setListClass(data.kelasBelumTerbayar)
        setDetailKelasDialog(true)
    }
  }

  const hideDialog = (type) => {
    if (type == "Detail Kelas") {
        setListClass(null)
        setDetailKelasDialog(false)
    }
  }

  return (
    <ContainerPage>
        <Loading visible={loading}/>

        <Dialog visible={detailKelasDialog} style={{ width: '40rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
         onHide={() => hideDialog("Detail Kelas")} header="Detail Kelas">
            <DataTable value={listClass} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} globalFilterFields={['namaKelas', 'guru']}
            >
                <Column field='namaKelas' sortable header="Nama Kelas" style={{ minWidth: '15rem' }}></Column>
                <Column field='namaGuru' sortable header="Nama Guru" style={{ minWidth: '15rem' }}></Column>
            </DataTable>
        </Dialog>

        <Card>
            <Card.Body>    
                <DataTable value={listUnPaidClass} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                    rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} globalFilterFields={['nama', 'nomor', 'jumlahKelas']} header={renderHeader()}
                    ref={dt} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                >
                    <Column field="nama" sortable header="Nama Murid" style={{ minWidth: '15rem' }}></Column>
                    <Column field="nomor" sortable header="Nomor Telepon" body={labelWA} style={{ minWidth: '15rem' }}></Column>
                    <Column field="jumlahKelas" sortable header="Jumlah Kelas" style={{ minWidth: '8rem' }}></Column>
                    <Column body={actionBodyTemplate} style={{minWidth: '15rem'}}></Column>
                </DataTable>
            </Card.Body>
        </Card>
    </ContainerPage>
  )
}

export default UnPaidClass
