import React, { useEffect, useRef, useState } from 'react'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import styles from './ListStudent.module.css'
import { Card } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { BASE_API_URL, formattedDate, formattedDateWithOutTime, stringToDate } from '../../helpers/general'
import axios from 'axios'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'

const ListStudent = () => {
  const [ studentDetail, setStudentDetail ] = useState({
    _id: null,
    nie : null,
    nama: '',
    nomor: null,
    tanggalLahir: null,
    domisili: '',
    sekolah: '',
    ibu: '',
    pekerjaanIbu: '',
    pekerjaanAyah: '',
    funnel: '',
    waktuMendaftar: null
  }) 

  const listFunnel = [
    {
        id: 'orgn',
        name: 'Organik'
    },
    {
        id: 'dmig',
        name: 'DM IG'
    },
    {
        id: 'ikig',
        name: 'Iklan IG'
    },
    {
        id: 'ikfb',
        name: 'Iklan FB'
    }
  ]
  
  const dt = useRef(null)
  const [ listStudent, setListStudent ] = useState(null)
  const [ dialogStudent, setDialogStudent ] = useState(false)
  const [ dialogDelete, setDialogDelete ] = useState(false)
  const [ dialogTitle, setDialogTitle ] = useState(null)
  const [ statusRefreshTable, setStatusRefreshTable] = useState(false)
  
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  const userChange = (event) => {
    const { name, value } = event.target
    setStudentDetail((currentValue) => ({
      ...currentValue,
      [name]: value
    }))
  }

  const getAllStudent = async () => {
    const optionsGetStudent = {
        method: 'get',
        url: `${BASE_API_URL}/students`,
        headers: {
            'authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionsGetStudent)
      .then((response) => {
        if(response.status == '200'){
            setListStudent(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getAllStudent()
  }, [statusRefreshTable])

  const resetDetailStudent = () => {
    setStudentDetail({
        _id: null,
        nie : null,
        nama: '',
        nomor: null,
        tanggalLahir: null,
        domisili: '',
        sekolah: '',
        ibu: '',
        pekerjaanIbu: '',
        pekerjaanAyah: '',
        funnel: '',
        waktuMendaftar: null
    })
  }

  const showDialog = (data, titleDialog) => {
    if(titleDialog == 'Tambah Kelas') {
        setDialogTitle(titleDialog)
        setDialogStudent(true)
    } else {
        setDialogTitle(titleDialog)
        setStudentDetail({
            _id: data._id,
            nama: data.nama,
            nie : data.nie,
            nomor: data.nomor,
            tanggalLahir: stringToDate(data.tanggalLahir),
            domisili: data.domisili,
            sekolah: data.sekolah,
            ibu: data.ibu,
            pekerjaanIbu: data.pekerjaanIbu,
            pekerjaanAyah: data.pekerjaanAyah,
            funnel: data.funnel,
            waktuMendaftar: stringToDate(data.waktuMendaftar)
        })
        setDialogStudent(true)
        console.log(data.tanggalLahir)
    }
  }

  const showDeleteDialog = (data) => {
    setStudentDetail({
        _id: data._id,
        nama: data.nama,
        nomor: data.nomor,
    })
    setDialogDelete(true)
  }

  const hideDialog = () => {
    resetDetailStudent()
    setDialogStudent(false)
  }

  const hideDeleteDialog = () => {
    resetDetailStudent()
    setDialogDelete(false)
  }

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Daftar Murid</p>
            </div>
            <div>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
              </span>
              <button className={styles.buttonAddClass} onClick={() => showDialog(null,'Tambah Kelas')}>Tambah Murid +</button>
            </div>
        </div>
    );
  };

  const newFormatDate = (rowDate) => {
    const newDate = formattedDateWithOutTime(rowDate.tanggalLahir)
    return newDate;
  }

  const actionBodyTemplate = (rowdata) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className='mr-2' onClick={() => showDialog(rowdata ,'Edit Data Murid')}/>
        <Button icon="pi pi-trash" rounded outlined severity='danger' onClick={() => showDeleteDialog(rowdata)}/>
        <a href={`/students/${rowdata._id}`}><Button icon="pi pi-eye" className='ml-2' rounded outlined severity='success' /></a>
      </React.Fragment>
    )
  }

  const studentDialogFooter = () => (
    <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} className='mr-3'/>
        <Button label="Save" icon="pi pi-check" onClick={saveButtonHandler}/>
    </React.Fragment>
  );

  const deleteDialogFooter = () => (
    <React.Fragment>
      <div className='flex-auto text-center'>
        <Button label="No" icon="pi pi-times" outlined className='mr-3' onClick={hideDeleteDialog}/>
        <Button label="Yes" icon="pi pi-check" severity="danger" onClick={() => deleteStudent(studentDetail._id)}/>
      </div>
    </React.Fragment>
  )

  const refreshTable = () => {
    getAllStudent()
  }

  const saveButtonHandler = () => {
    if (studentDetail._id !== null) { 
      updateStudent()
    }
    else 
      addStudent()
  }

  const addStudent = async () => {
    const dataNewStudent = studentDetail

    const optionAddStudent = {
        method: 'post',
        url: `${BASE_API_URL}/students`,
        data: dataNewStudent,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    await axios.request(optionAddStudent)
      .then((response) => {
        if (response.status == 201) {
            refreshTable()
            hideDialog()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteStudent = async (studentID) => {
    const optionDeleteStudent = {
        method: 'delete',
        url: `${BASE_API_URL}/student/${studentID}`,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    await axios.request(optionDeleteStudent)
      .then((response) => {
        if (response.status == 200) {
            setStatusRefreshTable(!statusRefreshTable)
            hideDeleteDialog()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateStudent = async () => {
    const dataUpdateStudent = studentDetail

    const optionUpdateStudent = {
        method: 'put',
        url: `${BASE_API_URL}/student/${studentDetail._id}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: dataUpdateStudent
    }

    axios.request(optionUpdateStudent)
      .then((response) => {
        if (response.status == 200) {
            refreshTable()
            hideDialog()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const getSeverity = (data) => {
    switch(data) {
      case "Aktif" :
        return 'info';

      case "Keluar":
        return 'danger';

      case "Selesai":
        return 'primary';

      default:
        return null;
    }
  }

  const statusLabel = (data) => {
    return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>
  }

  const labelWA = (data) => {
    return (
      <a href={`https://wa.me/${data.nomor}`} target="_blank" className={styles.buttonLabel}>
        <div className={styles.myButton}>
          <i className='pi pi-whatsapp'></i>
          <span>{data.nomor}</span>
        </div>
      </a>
    )
  }

  const paginatorLeft = () => {
    return (
      <div className='d-flex align-items-center gap-2'>
        <Button type="button" icon="pi pi-refresh" text onClick={() => refreshTable()} />
        <a href="/students/unpaid-class"><Button label='Kelas Belum Terbayar' severity='danger' style={{borderRadius: '10px', paddingBlock: '5px', fontSize: '14px'}}/></a>
      </div>
    )
  }
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => exportCSV(false)} />;
  
  return (
    <ContainerPage>
        <Card>
            <Card.Body>
                <div>
                    <div>
                        <DataTable value={listStudent} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                          rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id'
                          globalFilterFields={['nie','nama', 'nomor', 'tanggalLahir', 'domisili', 'sekolah', 'ibu', 'pekerjaanIbu', 'funnel']} header={renderHeader()}
                          paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                        >
                            <Column field="nie" sortable header="Nomer Induk" style={{ minWidth: '15rem' }}></Column>
                            <Column field="nama" sortable header="Nama Murid" style={{ minWidth: '15rem' }}></Column>
                            <Column field="nomor" sortable header="No. Handphone" body={labelWA} style={{ minWidth: '13rem' }}></Column>
                            <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '5rem' }}></Column>
                            <Column field="tanggalLahir" sortable header="Tanggal Lahir" body={newFormatDate} style={{ minWidth: '12rem' }}></Column>
                            <Column field="domisili" sortable header="Kota Asal" style={{ minWidth: '10rem' }}></Column>
                            <Column field="sekolah" sortable header="Sekolah" style={{ minWidth: '12rem' }}></Column>
                            <Column field="ibu" sortable header="Nama Ibu" style={{ minWidth: '12rem' }}></Column>
                            <Column field="pekerjaanIbu" sortable header="Pekerjaan Ibu" style={{ minWidth: '12rem' }}></Column>
                            <Column field="funnel" sortable header="Funnel" style={{ minWidth: '8rem' }}></Column>
                            <Column body={actionBodyTemplate} style={{minWidth: '15rem'}}></Column>
                        </DataTable>
                    </div>
                    <Dialog visible={dialogStudent} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                            footer={studentDialogFooter} onHide={hideDialog} header={dialogTitle}>
                        <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                            <div className='flex-auto'>
                                <label htmlFor="nomerInduk" className='font-bold block mb-2'>Nomer Induk</label>
                                <InputText id='nomerInduk' name='nomerInduk' value={studentDetail.nie} disabled onChange={userChange} required autoFocus/>
                            </div>

                            <div className='flex-auto'>
                                <label htmlFor="nama" className='font-bold block mb-2'>Nama Murid</label>
                                <InputText id='nama' name='nama' value={studentDetail.nama} onChange={userChange} required autoFocus/>
                            </div>
                            
                            <div className='flex-auto'>
                                <label htmlFor="nomor" className='font-bold block mb-2'>Nomor Telepon</label>
                                <InputNumber id='nomor' name='nomor' value={studentDetail.nomor} onValueChange={userChange} required autoFocus useGrouping={false} minFractionDigits={0}/>
                            </div>

                            <div className='flex-auto mb-3'>
                                <label htmlFor="tanggalLahir" className='font-bold block mb-2'>Tanggal Lahir</label>
                                <Calendar id='tanggalLahir' name='tanggalLahir' className='w-full' value={studentDetail.tanggalLahir} onChange={userChange} showIcon dateFormat='dd MM yy'/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="domisili" className='font-bold block mb-2'>Kota Asal</label>
                                <InputText id='domisili' name='domisili' value={studentDetail.domisili} onChange={userChange} required autoFocus/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="sekolah" className='font-bold block mb-2'>Sekolah</label>
                                <InputText id='sekolah' name='sekolah' value={studentDetail.sekolah} onChange={userChange} required autoFocus/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="ibu" className='font-bold block mb-2'>Nama Ibu</label>
                                <InputText id='ibu' name='ibu' value={studentDetail.ibu} onChange={userChange} required autoFocus/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="ibu" className='font-bold block mb-2'>Pekerjaan Ibu</label>
                                <InputText id='pekerjaanIbu' name='pekerjaanIbu' value={studentDetail.pekerjaanIbu} onChange={userChange} required autoFocus/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="pekerjaanAyah" className='font-bold block mb-2'>Pekerjaan Ayah</label>
                                <InputText id='pekerjaanAyah' name='pekerjaanAyah' value={studentDetail.pekerjaanAyah} onChange={userChange} required autoFocus/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="funnel" className='font-bold block mb-2'>Funnel</label>
                                <Dropdown id='funnel' name="funnel" options={listFunnel} value={studentDetail.funnel} optionValue='name' optionLabel="name" filter 
                                placeholder="Masukkan jenis funnel" onChange={userChange} className='w-full'/>
                            </div>
                            <div className='flex-auto'>
                                <label htmlFor="waktuMendaftar" className='font-bold block mb-2'>Waktu Mendaftar</label>
                                <Calendar id='waktuMendaftar' name='waktuMendaftar' value={studentDetail.waktuMendaftar} onChange={userChange} required dateFormat='dd-mm-yy'/>
                            </div>
                        </div>
                        
                    </Dialog>

                    <Dialog visible={dialogDelete} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                     header="Hapus Murid" footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                        <div className="confirmation-content text-center">
                            <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem' }} />
                            <h5>Yakin ingin menghapus "{studentDetail.nama}"?</h5>
                        </div>
                    </Dialog>
                </div>
            </Card.Body>
        </Card>
    </ContainerPage>
  )
}

export default ListStudent
