import React from 'react'
import styles from './NavBar.module.css'

const NavbarLogin = () => {
  return (
    <div className={styles.navbar}>
      <h4>Selamat datang, Admin</h4>
    </div>
  )
}

export default NavbarLogin
