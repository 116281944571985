import './App.css';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api'
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Login from './pages/Authorization/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import ListStudent from './pages/ListStudent/ListStudent';
import ListTeacher from './pages/ListTeacher/ListTeacher';
import ListClass from './pages/ListClass/ListClass';
import ListRoom from './pages/ListRoom/ListRoom';
import Preference from './pages/Preference/Preference';
import DetailClass from './pages/DetailClass/DetailClass';
import DetailStudent from './pages/DetailStudent/DetailStudent';
import DetailTeacher from './pages/DetailTeacher/DetailTeacher';
import ListSalary from './pages/ListSalary/ListSalary';
import DetailSalary from './pages/DetailSalary/DetailSalary';
import SalaryReport from './pdf/SalaryReport';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Attendance from './pages/Attendance/Attendance';
// import TransferClasss from './pages/ClassManagement/TransferClasss';
// import StudentRR from './pages/ClassManagement/StudentRR';
import ClassManagement from './pages/ClassManagement/ClassManagement';
import UnPaidClass from './pages/UnPaidClass/UnPaidClass';
import ShortLinksPage from './pages/ShortLink/ShortLink';

function App() {
  return (
    <PrimeReactProvider>
      <Router>
        <Routes>
          <Route 
            path='/'
            element = {
              <PublicRoute>
                <Login/>
              </PublicRoute>
            }
          />
          <Route 
            path='/dashboard'
            element = {
              <PrivateRoute>
                <Dashboard/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/students'
            element = {
              <PrivateRoute>
                <ListStudent/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/students/unpaid-class'
            element = {
              <PrivateRoute>
                <UnPaidClass/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/students/:id'
            element = {
              <PrivateRoute>
                <DetailStudent/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/teachers'
            element = {
              <PrivateRoute>
                <ListTeacher/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/teachers/:id'
            element = {
              <PrivateRoute>
                <DetailTeacher/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/classes'
            element = {
              <PrivateRoute>
                <ListClass/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/classes/:id'
            element = {
              <PrivateRoute>
                <DetailClass/>
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/transfer-class"
            element = {
              <PrivateRoute>
                <TransferClasss/>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/class-management"
            element = {
              <PrivateRoute>
                <ClassManagement/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/rooms'
            element = {
              <PrivateRoute>
                <ListRoom/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/preference'
            element = {
              <PrivateRoute>
                <Preference/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/attendance'
            element = {
              <PrivateRoute>
                <Attendance/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/salary'
            element = {
              <PrivateRoute>
                <ListSalary/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/salary/:id'
            element = {
              <PrivateRoute>
                <DetailSalary/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/report-salary'
            element = {
              <PrivateRoute>
                <SalaryReport/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/reset-password'
            element = {
              <PrivateRoute>
                <ResetPassword/>
              </PrivateRoute>
            }
          />
          <Route 
            path='/short-link'
            element = {
              <PrivateRoute>
                <ShortLinksPage/>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </PrimeReactProvider>
  );
}

const PublicRoute = ({children}) => {
  const isLoggedIn = localStorage.getItem('accessToken')
  return isLoggedIn ? <Navigate to="/dashboard" replace={true} /> : children
}

const PrivateRoute = ({children}) => {
  const isLoggedIn = localStorage.getItem('accessToken')
  return isLoggedIn ? children : <Navigate to="/" replace={true} />
} 

export default App;
