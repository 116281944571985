import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import Loading from '../../components/Loading'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card } from 'react-bootstrap'
import { BASE_API_URL, formatDecimal, getSeverity } from '../../helpers/general'
import axios from 'axios'
import {Chart} from 'primereact/chart'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { isArray } from 'chart.js/helpers'
import { Tag } from 'primereact/tag'
import { plugins } from 'chart.js'

const Dashboard = () => {
  const [ infoNeedSalary, setInfoNeedSalary ] = useState({
    qtyPaidTeacher: 0,
    needToPaySalary: 0,
    detail: null
  })
  const documentStyle = getComputedStyle(document.documentElement);
  const [ unverifiedClass, setUnverifiedClass ] = useState(0)
  const [ unverifiedTeacher, setUnverifiedTeacher ] = useState(0)
  const [ rankTeacher, setRankTeacher ] = useState(null)
  const [ generalInfoRoom, setGeneralInfoRoom ] = useState({
    usedRoom: 0,
    idleRoom: 0,
    maintenanceRoom: 0
  })
  const [ teacherDataGraph, setTeacherDataGraph ] = useState({
    labels: [],
    datasets: []
  })

  const [ teacherClassify, setTeacherClassify ] = useState({
    labels: [],
    datasets: []
  })

  const [ loading, setLoading ] =useState(false)

  const getNeedSalary = async () => {
    const optionGetNeedSalary = {
      method: 'get',
      url: `${BASE_API_URL}/need-pay-salary`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      }
    }

    await axios.request(optionGetNeedSalary)
      .then((response) => {
        setInfoNeedSalary(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUnverifiedTeacher = async () => {
    const optionGetUnverifiedTeacher = {
      method: 'get',
      url: `${BASE_API_URL}/unverifiedTeachers`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      }
    }

    await axios.request(optionGetUnverifiedTeacher)
      .then((response) => {
        setUnverifiedTeacher(response.data.length)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getUnverifiedClass = async () => {
    const optionGetUnverifiedClass = {
      method: 'get',
      url: `${BASE_API_URL}/unverifiedClass`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      }
    }

    await axios.request(optionGetUnverifiedClass)
      .then((response) => {
        setUnverifiedClass(response.data.length)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getGeneralInfoRoom = async () => {
    const optionGeneralInfo = {
      method: 'get',
      url: `${BASE_API_URL}/rooms-detail`,
      headers: {
        'Authorization': localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionGeneralInfo)
      .then((response) => {
        setGeneralInfoRoom(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getDataTeacherGraph = async () => {
    const optionGetDataTeacherGraph = {
      method: 'get',
      url: `${BASE_API_URL}/teachers-graph`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accesToken')
      }
    }

    await axios.request(optionGetDataTeacherGraph)
      .then((response) => {
        const dataTeacherGrpah = response.data
        const label = []
        const volunteerTeacher = []
        const superTeacher = []
        const premiumTeacher = []

        dataTeacherGrpah.map((data) => {
          label.push(`${data.month} ${data.year}`)
          volunteerTeacher.push(data.volunteer)
          superTeacher.push(data.super)
          premiumTeacher.push(data.premium)
        })

        setTeacherDataGraph({
          labels: label,
          datasets: [
            {
              type: 'bar',
              label: 'Volunteer',
              backgroundColor: documentStyle.getPropertyValue('--yellow-400'),
              data: volunteerTeacher
            },
            {
              type: 'bar',
              label: 'Super',
              backgroundColor: documentStyle.getPropertyValue('--blue-400'),
              data: superTeacher
            },
            {
              type: 'bar',
              label: 'Premium',
              backgroundColor: documentStyle.getPropertyValue('--red-400'),
              data: premiumTeacher
            },
          ]
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getRankTeacher = async () => {
    const optionRankTeacher = {
      method: 'get',
      url: `${BASE_API_URL}/teachers-rank`,
      headers: {
        'Authorization': localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionRankTeacher)
      .then((response) => {
        const result = response.data
        result.map((rank, index) => {
          rank['rank'] = index + 1

          if (rank.superTeacher) rank['tipe'] = "Super Teacher"
          else if (rank.volunteerTeacher) rank['tipe'] = "Volunteer Teacher"
          else rank['tipe'] = "Premium Teacher"
        })

        setRankTeacher(result)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getTeacherClassify = async () => {
    const optionTeacherClassify = {
      method: 'get',
      url: `${BASE_API_URL}/teachers-classification`,
      headers: {
        'Authorization': localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionTeacherClassify)
      .then((response) => {
        const resultData = response.data
        const dataChart = {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor:[],
          labels: []
        }
        
        
        if (resultData.volunteerTeacher > 0) {
          dataChart.data.push(resultData.volunteerTeacher)
          dataChart.labels.push("Volunteer")
          dataChart.backgroundColor.push(documentStyle.getPropertyValue('--blue-500'))
          dataChart.hoverBackgroundColor.push(documentStyle.getPropertyValue('--blue-400'))
        }

        if (resultData.premiumTeacher > 0) {
          dataChart.data.push(resultData.premiumTeacher)
          dataChart.labels.push("Premium")
          dataChart.backgroundColor.push('#dfdf11')
          dataChart.hoverBackgroundColor.push(documentStyle.getPropertyValue('--yellow-200'))
        }

        if (resultData.superTeacher > 0) {
          dataChart.data.push(resultData.superTeacher)
          dataChart.labels.push("Super")
          dataChart.backgroundColor.push(documentStyle.getPropertyValue('--green-700'))
          dataChart.hoverBackgroundColor.push(documentStyle.getPropertyValue('--green-400'))
        
        }

        setTeacherClassify({
          labels: dataChart.labels,
          datasets: [
            {
              data: dataChart.data,
              backgroundColor: dataChart.backgroundColor,
              hoverBackgroundColor: dataChart.hoverBackgroundColor
            }
          ]
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getTeacherClassify()
    getNeedSalary()
    getUnverifiedTeacher()
    getUnverifiedClass()
    getGeneralInfoRoom()
    getDataTeacherGraph()
    getRankTeacher()
  }, [])

  const optionsTeacherGraph = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
        tooltips: {
            mode: 'index',
            intersect: false
        },
        legend: {
            labels: {
                color: 'grey',
            }
        },
        title: {
          display: true,
          text: "Guru Edufic (6 Bulan Terakhir)",
          font: {
            size: 18,
            weight: 'bold',
            color: 'black'
          }
        },
        datalabels: {
          color: "#FFFFFF",
          font: {
            size: '18px'
          }
        }
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        }
    },
  };

  const optionsTeacherClassify = {
    plugins: {
      legend: {
        labels: {
            usePointStyle: true
        },
        position: 'bottom'
      },
      title: {
        display: true,
        text: "Persebaran Guru Edufic",
        font: {
          size: 20,
          weight: 'bold',
          color: 'black'
        }
      },
      datalabels: {
        color: "#FFFFFF",
        font: {
          size: '18px'
        }
      }
    }
  };

  const getIconRank = (rank) => {
    let rankIcon

    if (rank == 1) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height={100} width={100}>
          <path fill="#FFD43B" d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/>
        </svg>
      )
    }

    if (rank == 2) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height={100} width={100}>
          <path fill="#c0c0c0" d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/>
        </svg>
      )
    }

    if (rank == 3) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" height={100} width={100}>
          <path fill="#cd7f32" d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/>
        </svg>
      )
    }
  }

  const teacherType = (data) => (
    <Tag value={data} style={{color: 'white', backgroundColor: getSeverity("teacherType", data)}}/>
  )

  return (
    <ContainerPage>
      <Loading visible={loading} />

      <div className={styles.containerNotif}>
        <Card className={styles.cardNotif}>
          <Card.Header className={styles.cardNotifHeaderLayout}>
            <p className={styles.cardNotifHeader}>Manajemen Gaji</p>
            <a href="/salary"><i className='pi pi-external-link' style={{color: '#609af8'}}></i></a>
          </Card.Header>
          <Card.Body className={styles.cardNotifContent}>
            <i className='pi pi-dollar'></i>
            <div>
              <p className={styles.subjectCard}>Belum Dibayar</p>
              <p className={styles.cardValue}>{infoNeedSalary.needToPaySalary} Guru</p>
            </div>
          </Card.Body>
        </Card>

        <Card  className={styles.cardNotif}>
          <Card.Header className={styles.cardNotifHeaderLayout}>
            <p className={styles.cardNotifHeader}>Guru</p>
            <a href="/teachers"><i className='pi pi-external-link' style={{color: '#609af8'}}></i></a>
          </Card.Header>
        <Card.Body className={styles.cardNotifContent}>
            <i className='pi pi-id-card'></i>
            <div>
              <p className={styles.subjectCard}>Butuh Verifikasi</p>
              <p className={styles.cardValue}>{unverifiedTeacher} Akun</p>
            </div>
          </Card.Body>
        </Card>

        <Card className={styles.cardNotif}>
          <Card.Header className={styles.cardNotifHeaderLayout}>
            <p className={styles.cardNotifHeader}>Kelas</p>
            <a href="/classes"><i className='pi pi-external-link' style={{color: '#609af8'}}></i></a>
          </Card.Header>
          <Card.Body className={styles.cardNotifContent}>
            <i className='pi pi-microsoft'></i>
            <div>
              <p className={styles.subjectCard}>Butuh Verifikasi</p>
              <p className={styles.cardValue}>{unverifiedClass} Kelas</p>
            </div>
          </Card.Body>
        </Card>
        
        <Card className={styles.cardNotif}>
          <Card.Header className={styles.cardNotifHeaderLayout}>
            <p className={styles.cardNotifHeader}>Room</p>
            <a href="/rooms"><i className='pi pi-external-link' style={{color: '#609af8'}}></i></a>
          </Card.Header>
          <Card.Body className={styles.cardNotifContent}>
            <div>
              <p className={styles.subjectCard}>Used</p>
              <p className={styles.cardValue}>{generalInfoRoom.usedRoom}</p>
            </div>
            <div>
              <p className={styles.subjectCard}>Idle</p>
              <p className={styles.cardValue}>{generalInfoRoom.idleRoom}</p>
            </div>
            <div>
              <p className={styles.subjectCard}>Repair</p>
              <p className={styles.cardValue}>{generalInfoRoom.maintenanceRoom}</p>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className={styles.containerGraphict}>
        <Card>
          <Card.Body>
            <Chart type="bar" data={teacherDataGraph} options={optionsTeacherGraph} />
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            
          </Card.Body>
        </Card>
      </div>

      <div className={styles.containerTeacher}>
        <Card>
          <Card.Body>
            <h4>Rangking Guru</h4>
            <div className={styles.rankLayout}>
              <div className={styles.top3}>
                <Avatar className={`p-overlay-badge ${styles.rankIcon}`} style={{width: '80px', height: '80px'}} icon={getIconRank(2)} size="xlarge">
                  <Badge value="#2" severity='info'/>
                </Avatar>
                <p>{isArray(rankTeacher) ? rankTeacher[1].nama : "User"}</p>
                <p>RR ({ isArray(rankTeacher) ? formatDecimal(rankTeacher[1].retentionRate) : 0}%)</p>
              </div>
              <div className={styles.top3}>
                <Avatar className={`p-overlay-badge ${styles.rankIcon}`} style={{width: '100px', height: '100px'}} icon={getIconRank(1)} size="xlarge">
                  <Badge value="#1" severity='danger'/>
                </Avatar>
                <p>{isArray(rankTeacher) ? rankTeacher[0].nama : "User"}</p>
                <p>RR ({ isArray(rankTeacher) ? formatDecimal(rankTeacher[0].retentionRate) : 0}%)</p>
              </div>
              <div className={styles.top3}>
                <Avatar className={`p-overlay-badge ${styles.rankIcon}`} icon={getIconRank(3)} size="xlarge">
                  <Badge value="#3" severity='secondary'/>
                </Avatar>
                <p>{isArray(rankTeacher) ? rankTeacher[2].nama : "User"}</p>
                <p>RR ({ isArray(rankTeacher) ? formatDecimal(rankTeacher[2].retentionRate) : 0}%)</p>
              </div>
            </div>
            <DataTable value={isArray(rankTeacher)? rankTeacher.slice(3) : rankTeacher} scrollable scrollHeight='220px'>
                <Column field="rank" header="Rank" style={{maxWidth: '1rem'}}></Column>
                <Column field="nama" header="Nama Guru" style={{maxWidth: '5rem'}}></Column>
                <Column field="tipe" header="Tipe" body={(row) => teacherType(row.tipe)} style={{maxWidth: '4rem'}}></Column>
                <Column field="retentionRate" body={(row) => formatDecimal(row.retentionRate)} 
                 header="RR (%)" style={{maxWidth: '3rem'}}></Column>
            </DataTable>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Chart type="pie" data={teacherClassify} options={optionsTeacherClassify} plugins={[ChartDataLabels]} />
          </Card.Body>
        </Card>
      </div>
    </ContainerPage>
  )
}

export default Dashboard