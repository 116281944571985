import React, { useEffect, useState } from 'react'
import styles from './DetailTeacher.module.css'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card, Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { BASE_API_URL, formatDecimal, formatTTL, formattedDateWithDay, formattedDateWithOutTime, getSeverity } from '../../helpers/general'
import Loading from '../../components/Loading'
import axios from 'axios'
import { IconEdufic } from '../../assets'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import { Tag } from 'primereact/tag'
import { Chart } from 'primereact/chart'
import { Badge } from 'primereact/badge'
import { Dialog } from 'primereact/dialog'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DetailTeacher = () => {
  const {id} = useParams()
  const [ loading, setLoading ] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [ dialogRR, setDialogRR ] = useState(false)
  const [ dialog, setDialog] = useState({
    error: false,
  })
  const [ detailDialog, setDetailDialog ] = useState({
    header: null,
    message: null,
    footer: null,
    icon: null
  })
  const [ detailTeacher, setDetailTeacher ] = useState({
    _id: id,
    teacherEmail: null,
    teacherName: null,
    teacherTTL: null,
    teacherPhone: null,
    teacherAddress: null,
    teacherEducation: null,
    teacherBank: null,
    teacherBankAccount: null,
    teacherStatus: null,
    rateRetention: null,
  })
  const documentStyle = getComputedStyle(document.documentElement)
  const textColor = documentStyle.getPropertyValue('--text-color')
  const [ dataChartRR, setDataChartRR ] = useState({})
  const optionsChartRR = useState({
    maintainAspectRatio: false,
    aspectRatio: 0.6,
  })
  const [ listClass, setListClass ] = useState(null)
  const [ filterClass, setFilterClass ] = useState(null)

  const getDetailTeacher = async () =>  {
    setLoading(true)

    const optionsGetDetailTeacher = {
        method: 'get',
        url: `${BASE_API_URL}/teachers/${id}`,
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionsGetDetailTeacher)
      .then((response) => {
        const teacher = response.data

        setDetailTeacher((currentData) => ({
            ...currentData,
            teacherEmail: teacher.email,
            teacherName: teacher.nama,
            teacherPhone: teacher.nomor,
            teacherTTL: formatTTL(teacher.tempatLahir, teacher.tanggalLahir),
            teacherEducation: teacher.pendidikan,
            teacherBank: teacher.namaBank,
            teacherBankAccount: teacher.rekening,
            teacherAddress: teacher.domisili,
            teacherStatus: teacher.status,
            rateRetention: teacher.retentionRate
        }))

        dataRRModify(response.data.historyRR)
        setLoading(false)
      })
      .catch((error) => {
        setDetailDialog({
            header: 'Gagal  Memuat Detail Teacher',
            message: error.response.data.message,
            icon: 'pi pi-exclamation-triangle',
        })

        setDialog((currentData) => ({...currentData, error: true}))
      })
  }

  const getTeacherClass = async () => {
    const optionGetTeacherClass = {
        method: 'get',
        url: `${BASE_API_URL}/teacher-class/${id}`,
        headers:{
            'Authorization': localStorage.getItem('accessToken')
        },
    }

    await axios.request(optionGetTeacherClass)
      .then((response) => {
        setListClass(response.data)
        setFilterClass(response.data)
      })
      .catch((error) => {
        setDetailDialog({
            header: 'Gagal  Memuat List Kelas Guru',
            message: error.response.data.message,
            icon: 'pi pi-exclamation-triangle',
        })

        setDialog((currentData) => ({...currentData, error: true}))
      })
  }

  const dataChartAttendance = (chartData) => {
    const { hadir, tidakHadir, sakit, izin } = chartData.detailAttendance

    const dataModify = {
        labels: ['Hadir', 'Izin', 'Sakit', 'Tidak Hadir'],
        datasets: [
            {
                data: [hadir, izin, sakit, tidakHadir],
                backgroundColor: [
                    'rgb(0, 0, 255)',
                    'rgb(255, 255, 0)',
                    'rgb(128, 128, 128)',
                    'rgb(255, 0, 0)'
                ],
                hoverBackgroundColor: [
                    'rgb(114, 114, 229)',
                    'rgb(247, 247, 146)',
                    'rgb(175, 175, 175)',
                    'rgb(251, 112, 112)'
                ],
                borderWidth: 1,
            }
        ]
    };
    
    return dataModify
  }

  const dataChartSession = (chartData) => {
    const { sesiTerlaksana, sesiBelumTerlaksana } = chartData

    const dataSession = {
        labels: ['Terlaksana', 'Belum Terlaksana'],
        datasets: [
            {
                data: [sesiTerlaksana, sesiBelumTerlaksana ],
                backgroundColor: [
                    'rgb(0, 0, 255)',
                    'rgb(255, 0, 0)'
                ],
                hoverBackgroundColor: [
                    'rgb(114, 114, 229)',
                    'rgb(251, 112, 112)'
                ]
            }
        ]
    }

    return dataSession
  }

  const dataRRModify = (historyRR) => {
    const tanggalRR = [] 
    const score = []
    
    score.push(0)
    tanggalRR.push("Start")
    historyRR.map((RR) => {
        tanggalRR.push(formattedDateWithOutTime(RR.tanggalRR))
        score.push(formatDecimal(RR.score))
    })

    const dataChart = {
        labels: tanggalRR,
        datasets: [
            {
                label: 'RR',
                data: score,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                tension: 0.4
            }
        ],
        
    }

    setDataChartRR(dataChart)
  }

  useEffect(() => {
    getDetailTeacher()
    getTeacherClass()
  }, [])

  useEffect(() => {
    const newListClass = []
    setFilterClass(listClass)

    if (searchValue != "") {
        listClass.map((detailClass) => {
            const classIdentity = (`${detailClass.namaKelas} - ${detailClass.kodeBatch}.${detailClass.kodeKelas} (Lvl.${detailClass.level}) ${detailClass.jenisKelas}`).toLowerCase();
            if (classIdentity.includes(searchValue.toLowerCase())) newListClass.push(detailClass)
        })

        setFilterClass(newListClass)
    }
  }, [searchValue])

  const headerClassPanel = (rowData) => {
    return (
        <div className={styles.titleCardLayout}>
            <h5>{`${rowData.namaKelas} - ${rowData.kodeBatch}.${rowData.kodeKelas} (Lvl.${rowData.level})`}</h5>
            <Button label={rowData.jenisKelas} severity={getSeverity("classType", rowData.jenisKelas)}/>
        </div>
    )
  }

  const options = {
    scales: {
        y: {
            beginAtZero: true
        },
    },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        },
        title: {
          display: true,
          text: "Grafik Presensi",
          font: {
            size: 18,
            weight: 'bold',
            color: 'black'
          }
        }
      }
    
  };

  const optionsSession = {
    plugins: {
      legend: {
          labels: {
              usePointStyle: true
          }
      },
      title: {
        display: true,
        text: "Grafik Sesi",
        font: {
          size: 18,
          weight: 'bold',
          color: 'black'
        }
      },
      datalabels: {
        color: '#FFFFFF',
        font: {
            size: '18px'
        }
      }
    }
  };

  const cardPanel = (rowData) => {
    return (
        <Panel className='mt-3' header={headerClassPanel(rowData)} toggleable collapsed key={rowData._id}>
            <div className='row'>
                <div className='col-5'>
                    <div className={styles.classInformation}>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width: '30%'}}>Mulai Kelas</td>
                                    <td>:</td>
                                    <td>{formattedDateWithDay(rowData.jadwal)}</td>
                                </tr>
                                <tr>
                                    <td>Kelas terakhir</td>
                                    <td>:</td>
                                    <td>{rowData.sesiBerakhir == "-" ? rowData.sesiBerakhir : formattedDateWithDay(rowData.sesiBerakhir)}</td>
                                </tr>
                                <tr>
                                    <td>Status PTC</td>
                                    <td>:</td>
                                    <td><div className={styles.statusButtonClass}>
                                        {
                                            rowData.ptc.length > 0 ? 
                                                <div className={styles.statusButtonClass}>
                                                    <Tag value='PTC Berlangsung' className={styles.statusPTC}/><span>{`x ${rowData.ptc.length}`}</span>
                                                </div>
                                            :
                                                <div className={styles.statusButtonClass}>
                                                    <Tag value='Belum PTC' severity='danger'/>
                                                </div>
                                        }
                                    </div></td>
                                </tr>
                                <tr>
                                    <td>Status Kelas</td>
                                    <td>:</td>
                                    <td><Tag value={rowData.statusKelas} severity={getSeverity("classStatus", rowData.statusKelas)} /></td>
                                </tr>
                                <tr>
                                    <td>Rate Retention</td>
                                    <td>:</td>
                                    <td><div className={styles.rateRetention}>{`${formatDecimal(rowData.kelasRR) || '-'}%`}</div></td>
                                </tr>
                                <tr>
                                    <td>Sesi Bulan Ini</td>
                                    <td>:</td>
                                    <td><div className={styles.monthlySession}>{rowData.sesiBulanIni}</div></td>
                                </tr>
                                <tr>
                                    <td>Sesi Bulan Lalu</td>
                                    <td>:</td>
                                    <td><div className={styles.monthlySession}>{rowData.sesiBulanLalu}</div></td>
                                </tr>
                                <tr>
                                    <td>List Murid</td>
                                    <td>:</td>
                                    <td>{rowData.listMurid}</td>
                                </tr>
                            </tbody>
                        </table>
                        <Button label='Detail Kelas' severity='primary' className='w-full' onClick={() => (window.location.href = `/classes/${rowData._id}`)}/>
                    </div>
                </div>
                <div className='col-7'>
                    <div  className={styles.classChart}>
                        <div className={styles.divider}></div>
                        <Chart type='pie' data={dataChartSession(rowData)} options={optionsSession} plugins={[ChartDataLabels]} className='md:w-20rem' />
                        <Chart type="bar" data={dataChartAttendance(rowData)} options={options} height='22rem' width='50%'/>
                    </div>
                </div>
            </div>
        </Panel>
    )
  }

  const listCardTeacher = (listClassData) => {
    const listCardView = []
    console.log(listClassData)
    
    if (listClassData !== null && listClassData.length !== 0) {
        listClassData.map((detailClass) => {
            const listMurid = []
            // console.log(detailClass)

            detailClass.murid.map((detailMurid) => {
                listMurid.push(detailMurid.nama)
            })

            detailClass['listMurid'] = listMurid.join(", ")
            listCardView.push(cardPanel(detailClass))
        })
    } 

    return listCardView
  }

  return (
    <ContainerPage>
        <Card>
            <Card.Body>
                <div className={styles.menuLayout}>
                    <h4>Profile Pengajar</h4>
                    <Button label={detailTeacher.teacherStatus} severity={getSeverity("teacherStatus", detailTeacher.teacherStatus)} />
                </div>

                <div className={styles.cardProfileLayout}>
                <div className={styles.profileLayout}>
                    <Image src={IconEdufic} className={styles.profile} />
                    <div className={styles.id}>{detailTeacher.teacherEmail}</div>
                </div>
                <div>
                    <table className={styles.tableLayout}>
                    <tbody>
                        <tr>
                            <td className={styles.infoTitle}>Nama</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherName}</td>
                        </tr>
                        <tr>
                            <td className={styles.infoTitle}>TTL</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherTTL}</td>
                        </tr>
                        <tr>
                            <td className={styles.infoTitle}>Kontak</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherPhone}</td>
                        </tr>
                        <tr>
                            <td className={styles.infoTitle}>Domisili</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherAddress}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div>
                    <table className={styles.tableLayout}>
                    <tbody>
                        <tr>
                            <td className={styles.infoTitle}>Pendidikan</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherEducation}</td>
                        </tr>
                        <tr>
                            <td className={styles.infoTitle}>No. Rekening</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherBankAccount}</td>
                        </tr>
                        <tr>
                            <td className={styles.infoTitle}>Nama Bank</td>
                            <td className={styles.separatial}>:</td>
                            <td>{detailTeacher.teacherBank}</td>
                        </tr>
                        <tr>
                            <td className={styles.infoTitle}>Rate Retention</td>
                            <td className={styles.separatial}>:</td>
                            <td>
                                <div className='flex gap-2 align-items-center'>
                                    <strong>{`${formatDecimal(detailTeacher.rateRetention)}%`}</strong>
                                    <Button icon='pi pi-eye' onClick={() => setDialogRR(true)} style={{borderRadius: '10px', padding: '3px'}} severity='info'></Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </Card.Body>
        </Card>
        <div className='mt-3 flex justify-content-between align-items-center'>
            <div className='flex justify-content-between align-items-center gap-2'>
                <h4 style={{margin: 0, padding: 0}}>Daftar Kelas</h4>
                <Badge value={listClass !== null ? listClass.length : 0} className={styles.badge}></Badge>
            </div>
            <div className="p-inputgroup flex-1" style={{maxWidth: '20rem'}}>
                <InputText placeholder="Cari Kelas" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
                <Button icon="pi pi-search" className="p-button-warning"/>
            </div>
        </div>
        {listCardTeacher(filterClass)}
        
        <Dialog visible={dialogRR} style={{ width: '40rem', height: '20rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
         header="Grafik Rate Retention" onHide={() => setDialogRR(false)}>
            <div className="card" style={{height:'100%'}}>
                <Chart type="line" data={dataChartRR} options={optionsChartRR} height='100%'/>
            </div>
        </Dialog>
    </ContainerPage>
  )
}

export default DetailTeacher
