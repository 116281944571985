import React from 'react';
import EduButton from '../Button';
import { InputText } from 'primereact/inputtext';
import './style.css';

const EduTableHeader = ({
  button=true, 
  labelButton, 
  actionButton= () => (""), 
  title="", 
  search=true, 
  filterValue, 
  filterChange = () => (""), 
  searchKey
}) => {
  const searchBar = () => (
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText value={filterValue} onChange={filterChange} placeholder={searchKey} />
    </span>
  )
    
  return (
    <div className='searchBarLayout'>
      <div className='cardHeader'>
        <p className='cardTitle'>{title}</p>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
        {(search && searchBar())}
        {(button && <EduButton type='add' label={labelButton} actionButton={actionButton}/>)}
      </div>
    </div>
  )
}

export default EduTableHeader
