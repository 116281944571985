import React from 'react'
import A4 from './LayoutPDF/A4';
import { Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { LogoEdufic } from '../assets';
import { useLocation } from 'react-router-dom';
import { formatAccounting, formatIDRCurrency, formattedDate, formattedDateWithOutTime } from '../helpers/general';

const SalaryReport = () => {
    // Create styles
    const location = useLocation()
    const dataReport = location.state
    const {sesi, guru} = dataReport

    const styles = StyleSheet.create({
        header: {
            width: '100%',

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            marginBottom: '40px',
        },

        image: {
            width: '20%',
        },

        textHeader: {
            width: '80%',
            fontFamily: 'Times-Bold',
            fontSize: '14px',
            textAlign: 'center',
        },

        tHead: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',

            fontFamily: 'Times-Bold',
            fontSize: 13,
        },

        tRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },

        tColSubject: {
            width: '40%'
        },

        tColQtySession: {
            width: '20%',
            textAlign: 'center'
        },

        tColDetailSession: {
            width: '25%',
        },

        tColSalaryValue: {
            width: '20%',
            textAlign: 'right'
        },

        totalValue: {
            width: '100%',
            backgroundColor: 'rgb(189, 189, 189)',

            paddingVertical: '3px',
            paddingHorizontal: '10px',
            marginBottom: '20px',

            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Times-Bold',
        },

        detailValue: {
            width: '100%',
            textAlign: 'center'
        },

        signatureField: {
            height: '90px',
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

            textAlign: 'right',
            marginBottom: '20px'
        },
    });

    return (
        <A4>
            <View style={styles.header}>
                <Image style={styles.image} src={LogoEdufic}/>
                <View style={styles.textHeader}>
                    <Text style={styles.textHeader}>Slip Gaji Pengajar Edufic</Text>
                    <Text style={styles.textHeader}>Masa KBM {sesi[0].bulanGaji}</Text>
                </View>
            </View>

            <View style={{marginBottom: '40px'}}>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Text style={{width: '20%', marginRight: '20px'}}>Nama</Text>
                    <Text>: {guru.nama}</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Text style={{width: '20%', marginRight: '20px'}}>Jabatan</Text>
                    <Text>: Pengajar</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Text style={{width: '20%', marginRight: '20px'}}>Status</Text>
                    <Text>: {guru.superTeacher ? "Premium" : "Reguler"} Teacher</Text>
                </View>
            </View>

            <View style={{marginBottom: '15px'}}>
                <View style={styles.tHead}>
                    <Text style={styles.tColSubject}>Keterangan Penghasilan</Text>
                    <Text style={styles.tColQtySession}>Sesi</Text>
                    <Text style={styles.tColDetailSession}>Rincian</Text>
                    <Text style={styles.tColSalaryValue}>Nominal (Rp)</Text>
                </View>
                { sesi.map((detailData) => (
                    <View style={styles.tRow}>
                        <Text style={styles.tColSubject}>{detailData.subjectClass}</Text>
                        <Text style={styles.tColQtySession}>{detailData.qtySession}</Text>
                        <Text style={styles.tColDetailSession}>{detailData.listSession.join(", ")}</Text>
                        <Text style={styles.tColSalaryValue}>{formatAccounting(detailData.nominal)}</Text>
                    </View>
                )) }
            </View>

            <View style={styles.totalValue}>
                <Text style={styles.detailValue}>Penerimaan Bersih</Text>
                <Text style={styles.detailValue}>{formatIDRCurrency(dataReport.sesi[0].totalGaji)}</Text>
            </View>


            <View style={styles.signatureField}>
                <Text>Surabaya, {formattedDateWithOutTime(new Date)}</Text>
                <View>
                    <Text>Manager Operasional</Text>
                    <Text>Haris Al Hamdi, S.T.</Text>
                </View>
            </View>
        </A4>
    )   
}

export default SalaryReport
