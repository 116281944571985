import React, {useEffect, useRef, useState} from 'react'
import ContainerPage from "../../layouts/ContainerPage/ContainerPage";
import {Card} from "primereact/card";
import Loading from "../../components/Loading";
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Toast} from "primereact/toast";
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { BASE_API_URL } from '../../helpers/general'
import axios from "axios";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

const getSchedule = async () => {

    const options = {
        method: 'get',
        url: `${BASE_API_URL}/get-available-schedule`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios.request(options)
        .then(({data}) => {
            return data.flatMap(item =>
                item.jadwal.map(jadwal => {
                  const _start = new Date(jadwal.jamAwal).toLocaleTimeString('it-IT', {hour: "2-digit", minute: "2-digit"})
                  const _end = new Date(jadwal.jamAkhir).toLocaleTimeString('it-IT', {hour: "2-digit", minute: "2-digit"})

                  let jenisKelas = item.jenisKelas
                  if (Array.isArray(jenisKelas)) {
                    jenisKelas.sort()
                  }

                  let usia = item.usia
                  if (Array.isArray(usia)) {
                      usia.sort()
                  }

                  let subjek = item.subjek;
                  if (Array.isArray(subjek)) {
                    subjek.sort();
                  }

                  return ({
                    _id: item._id,
                    usia,
                    jenisKelas,
                    subjek,
                    hari: jadwal.hari,
                    jamAwal: jadwal.jamAwal,
                    jamAwalFilter: _start,
                    jamAkhir: jadwal.jamAkhir,
                    jamAkhirFilter: _end,
                    jadwal_id: jadwal._id,
                    guru: item.guru.nama
                })})
            );
        })
        .catch((error) => {
            console.error(error);
            return null;
        });

}

const delSchedule = async (scheduleId, timeId) => {
    const options = {
        method: "delete",
        url: `${BASE_API_URL}/remove-time-available-schedule`,
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            "scheduleId": `${scheduleId}`,
            "timeId": `${timeId}`
        },
    };

    return axios.request(options)
        .then(({data}) => {
            return data
        })
        .catch((error) => {
            console.error(error);
            return null;
        });
}

const Preference =()=>{
    const [preference, setPreference] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        guru: { value: null, matchMode: FilterMatchMode.CONTAINS },
        hari: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subjek: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jamAwalFilter: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jamAkhirFilter: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jenisKelas: { value: null, matchMode: FilterMatchMode.CONTAINS },
        usia: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [jenisKelas] = useState(['private', 'small']);
    const [usia] = useState(['5 - 7', '8 - 9', '10 - 12']);
    const [subjek] = useState([
      "Adab",
      "AFQ",
      "IC Atas",
      "IC Bawah",
      "ICWS",
      "IE",
      "IL & SoIP",
      "NI",
      "QT",
    ]);
    const toast = useRef(null);

    const usiaSeverity = (status) => {
        switch (status) {
            case '5 - 7':
                return 'danger';

            case '8 - 9':
                return 'success';

            case '10 - 12':
                return 'info';
        }
    };

    const kelasSeverity = (status) => {
        switch (status) {
            case 'private':
                return 'warning';

            case 'small':
                return 'success';
        }
    };

    const subjekSeverity = (status) =>{
        switch (status) {
          case "AFQ":
            return "danger";

          case "Adab":
            return "success";

          case "QT":
            return "success";

          case "IC Atas":
            return "warning";

          case "IC Bawah":
            return "info";

          case "ICWS":
            return "danger";

          case "IE":
            return "success";

          case "IL & SoIP":
            return "warning";

          case "NI":
            return "info";
        }
    }

    useEffect(() => {
        getSchedule().then((data)=>{
          setPreference(data);
          setLoading(false);
        })
    }, [refresh]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </div>
        );
    };

    const timeBodyTemplate = (rowData, field) => {
        const time = new Date(rowData[field])
        return time.toLocaleTimeString('it-IT', {hour: "2-digit", minute: "2-digit"})
    };

    const filterItemTemplate = (option) => {
        return <span>{option}</span>
    };

    const usiaBodyTemplate = (rowData) => {
        return <>{rowData.usia.map((item)=><Tag value={item} key={item} className="mr-2" severity={usiaSeverity(item)} />)}</>
    };

    const subjekBodyTemplate = (rowData) => {
        return <>{rowData.subjek.map((item)=><Tag value={item} key={item} className="mr-2" severity={subjekSeverity(item)} />)}</>
    };

    const kelasBodyTemplate = (rowData) => {
        return <>{rowData.jenisKelas.map((item)=><Tag value={item} key={item} className="mr-2" severity={kelasSeverity(item)} />)}</>
    };

    const jenisKelasFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={jenisKelas}
                itemTemplate={filterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value.sort())}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const usiaFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={usia}
                itemTemplate={filterItemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const subjekFilterTemplate = (options) => {
      return (
        <MultiSelect
          value={options.value}
          options={subjek}
          itemTemplate={filterItemTemplate}
          onChange={(e) => options.filterApplyCallback(e.value)}
          placeholder="Any"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: "14rem" }}
        />
      );
    };

    const actionTemplate = (rowData) => {
        const handleDelete = (rowData) => {
            const accept = () => {
                delSchedule(rowData._id, rowData.jadwal_id).then(() => {
                    toast.current.show({
                        severity: 'info',
                        summary: 'Confirmed',
                        detail: 'Jadwal Berhasil Dihapus',
                        life: 3000
                    });
                    setRefresh(prev => !prev)

                }).catch((error) => {
                    toast.current.show({
                        severity: 'danger',
                        summary: 'Error',
                        detail: `${error}`,
                        life: 3000
                    })
                })
            }

            confirmDialog({
                message: 'Apakah anda yakin ingin menghapusnya?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept
            });
        };

        return <Button type="button" icon="pi pi-trash"
                       onClick={() => handleDelete(rowData)}
                       severity="danger"
                       rounded />
    }

    const header = renderHeader();

    return (
      <ContainerPage>
        <Toast ref={toast}/>
        <ConfirmDialog/>
        <Card title="Preferensi Guru">
            <Loading visible={loading}/>
            <DataTable value={preference} paginator rows={10} filters={filters} filterDisplay="row" loading={loading}
                       globalFilterFields={['guru', 'hari', 'subjek', 'jamAwalFilter', 'jamAkhirFilter', 'usia','jenisKelas']}
                       header={header} sortMode="multiple" emptyMessage="No customers found.">
                <Column field="guru" sortable header="Nama Guru" filter filterPlaceholder="Search by name" style={{ minWidth: '20rem' }} />
                <Column field="hari" sortable header="Hari" filter filterPlaceholder="Search by day" style={{ minWidth: '20rem' }} />
                <Column field="subjek" sortable header="Subjek" showFilterMenu={false} filterMenuStyle={{ width: '12rem' }} style={{ minWidth: '12rem' }} body={subjekBodyTemplate} filter filterElement={subjekFilterTemplate} />
                <Column header="Jam Mulai" sortable field="jamAwalFilter" filterField="jamAwalFilter" showFilterMenu={false} style={{ minWidth: '12rem' }} body={(data)=>timeBodyTemplate(data,'jamAwal')} filter filterPlaceholder="Search by country" />
                <Column header="Jam Selesai" sortable field="jamAkhirFilter" filterField="jamAkhirFilter" showFilterMenu={false} style={{ minWidth: '12rem' }} body={(data)=>timeBodyTemplate(data,'jamAkhir')} filter filterPlaceholder="Search by country" />
                <Column field="jenisKelas" sortable header="Jenis Kelas" showFilterMenu={false} filterMenuStyle={{ width: '12rem' }} style={{ minWidth: '12rem' }} body={kelasBodyTemplate} filter filterElement={jenisKelasFilterTemplate} />
                <Column field="usia" sortable header="Rentang Usia" showFilterMenu={false} filterMenuStyle={{ width: '12rem' }} style={{ minWidth: '12rem' }} body={usiaBodyTemplate} filter filterElement={usiaFilterTemplate} />
                <Column body={actionTemplate} style={{width: '30%'}}/>
            </DataTable>
        </Card>
      </ContainerPage>
    );
}

export default Preference