import React from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'
import { LogoEdufic } from '../../assets'
import styles from './NavBar.module.css'
import { SplitButton } from 'primereact/splitbutton'

const NavBar = () => {
  const items = [
    {
        label: 'Contact Faiq',
        icon: 'pi pi-whatsapp',
        command: () => {
            
        }
    },
    {
        label: 'Contact Haidar',
        icon: 'pi pi-whatsapp',
        command: () => {
            
        }
    },
  ];
  
  return (
    <Navbar>
      <Container>
        <Navbar.Brand href='https://edufic.id/'>
          <img 
            src={LogoEdufic} 
            alt="Edufic"
            height="40"
          />
        </Navbar.Brand>
        <Nav>
          <a href="/" className={styles.passiveButton}>Beranda</a>
          <a href="#" className={styles.passiveButton}>Bantuan</a>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default NavBar
