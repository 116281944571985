const listFinanceAccount = [
    {
        bank_id: 'ID_BCA',
        type: 'BANK',
        name: 'Bank Central Asia (BCA)'
    },
    {
        bank_id: 'ID_BCA_SYR',
        type: 'BANK',
        name: 'Bank Central Asia (BCA) Syariah'
    },
    {
        bank_id: 'ID_BJB',
        type: 'BANK',
        name: 'Bank BJB'
    },
    {
        bank_id: 'ID_BJB_SYR',
        type: 'BANK',
        name: 'Bank BJB Syariah'
    },
    {
        bank_id: 'ID_BNC',
        type: 'BANK',
        name: 'Bank Neo Commerce'
    },
    {
        bank_id: 'ID_BNI',
        type: 'BANK',
        name: 'Bank Negara Indonesia (BNI)'
    },
    {
        bank_id: 'ID_MDR',
        type: 'BANK',
        name: 'Bank Mandiri'
    },
    {
        bank_id: 'ID_BRI',
        type: 'BANK',
        name: 'Bank Rakyat Indonesia (BRI)'
    },
    {
        bank_id: 'ID_BSI',
        type: 'BANK',
        name: 'Bank Syariah Indonesia (BSI)'
    },
    {
        bank_id: 'ID_BTN',
        type: 'BANK',
        name: 'Bank Tabungan Negara (BTN)'
    },
    {
        bank_id: 'ID_BTN_UUS',
        type: 'BANK',
        name: 'Bank Tabungan Negara (BTN) Syariah'
    },
    {
        bank_id: 'ID_BUKOPIN',
        type: 'BANK',
        name: 'Bank Bukopin'
    },
    {
        bank_id: 'ID_CIMB',
        type: 'BANK',
        name: 'Bank CIMB Niaga'
    },
    {
        bank_id: 'ID_CIMB_UUS',
        type: 'BANK',
        name: 'Bank CIMB Niaga Syariah'
    },
    {
        bank_id: 'ID_CITIBANK',
        type: 'BANK',
        name: 'Citibank'
    },
    {
        bank_id: 'ID_DANAMON',
        type: 'BANK',
        name: 'Bank Danamon'
    },
    {
        bank_id: 'ID_JAGO',
        type: 'BANK',
        name: 'Bank Jago'
    },
    {
        bank_id: 'ID_MEGA',
        type: 'BANK',
        name: 'Bank Mega'
    },
    {
        bank_id: 'ID_OCBC',
        type: 'BANK',
        name: 'Bank OCBC NISP'
    },
    {
        bank_id: 'ID_PANIN',
        type: 'BANK',
        name: 'Bank Panin'
    },
    {
        bank_id: 'ID_PANIN_SYR',
        type: 'BANK',
        name: 'Bank Panin Syariah'
    },
    {
        bank_id: 'ID_PERMATA',
        type: 'BANK',
        name: 'Bank PERMATA'
    },
    {
        bank_id: 'ID_PERMATA_UUS',
        type: 'BANK',
        name: 'Bank Permata Syariah'
    },
    {
        bank_id: 'ID_SEABANK',
        type: 'BANK',
        name: 'Bank Seabank Indonesia'
    },
    {
        bank_id: 'ID_SINARMAS',
        type: 'BANK',
        name: 'Bank Sinarmas'
    },
    {
        bank_id: 'ID_SINARMAS_UUS',
        type: 'BANK',
        name: 'Bank Sinarmas Syariah'
    },
    {
        bank_id: 'ID_DANA',
        type: 'E-WALLET',
        name: 'DANA'
    },
    {
        bank_id: 'ID_GOPAY',
        type: 'E-WALLET',
        name: 'GoPay'
    },
    {
        bank_id: 'ID_LINKAJA',
        type: 'E-WALLET',
        name: 'LinkAja'
    },
    {
        bank_id: 'ID_OVO',
        type: 'E-WALLET',
        name: 'OVO'
    },
    {
        bank_id: 'ID_SHOPEEPAY',
        type: 'E-WALLET',
        name: 'Shopeepay'
    },
]

export { listFinanceAccount }