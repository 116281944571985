import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import { BASE_API_URL } from '../../helpers/general'
import axios from 'axios'
import { Card, Tab } from 'react-bootstrap'
import { Dialog } from 'primereact/dialog';
import { IconEdufic } from '../../assets'
import styles from './ClassManagement.module.css'
import { Dropdown } from 'primereact/dropdown'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'

const ClassManagement = () => {
    const [ listClass, setLIstClass ] = useState([]) 
    const [ listTeacher, setListTeacher ] = useState([])
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ activeMenu, setActiveMenu ] = useState('TransferClass')
    const [ loading, setLoading ] = useState(false)
    const [ dataTransferClass, setDataTransferClass ] = useState({
      classId: null,
      guruBaru: null
    })
    const [ dataStudentRR, setDataStudentRR ] = useState({
        classId: null,
        continuationString: ''
      });
    const [ transferClassDialog, setTransferClassDialog ] = useState(false)
    const [ studentRRDialog, setStudentRRDialog ] = useState(false)
    const [ validationDialog, setValidationDialog ] = useState(false)
    const [ successTransferDialog, setSuccessTransferDialog ] = useState(false)
    const [ errorDialog, setErrorDialog ] = useState(false)
  
    const getAllClass = async (req, res) => {
      setLoading(true)
  
      const optionGetAllClass = {
          method: 'get',
          url: `${BASE_API_URL}/class`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('accessToken')
          }
      }
  
      await axios.request(optionGetAllClass)
        .then((response) => {
          const daftarKelas = response.data
          daftarKelas.map((kelas) => {
              kelas["subjek"] = `[${kelas.guru.nama}] ${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl ${kelas.level}`
          })
  
          setLIstClass(response.data)
          setLoading(false)
        })
        .catch((err) => {
          setErrorMessage(err)
          setErrorDialog(true)
          setLoading(false)
        }) 
    }
  
    const getAllTeacher = async (req, res) => {
      setLoading(true)
  
      const optionGetAllTeacher = {
          method: 'get',
          url: `${BASE_API_URL}/teachers`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('accessToken')
          }
      }
  
      await axios.request(optionGetAllTeacher)
        .then((response) => {
          setListTeacher(response.data)
          setLoading(false)
        })
        .catch((err) => {
          setErrorMessage(err)
          setErrorDialog(true)
          setLoading(false)
        }) 
    }
  
    const transferClass = async (req, res) => {
      setLoading(true)
      hideDialog("TransferClass")
  
      const optionTransferrClass = {
          method: 'put',
          url: `${BASE_API_URL}/transfer-class`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('accessToken')
          },
          data: dataTransferClass
      }
  
      await axios.request(optionTransferrClass)
        .then((response) => {
          if (response.status == 200) {
              setLoading(false)
              resetDataTransfer()
              showDialog("SuccessTransfer")
              getAllClass()
          }
        })
        .catch((err) => {
          console.log(err)
          setTransferClassDialog(false)
          setErrorMessage(err.response.data.message)
          setErrorDialog(true)
          setLoading(false)
        })
    }

    const StudentRR = async (req, res) => {
        setLoading(true)
        hideDialog("StudentRR")

        const optionStudentRR = {
            method: 'put',
            url: `${BASE_API_URL}/update-status-string`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('accessToken')
            },
            data: dataStudentRR
        }

        await axios.request(optionStudentRR)
          .then((response) => {
            setLoading(false)
            resetDataStudentRR()
            showDialog("SuccessTransfer")
          })
          .catch((err) => {
            console.log(err)
            setErrorMessage(err.response.data.message)
            setErrorDialog(true)
            setLoading(false)
          })
    }
  
    useEffect(() => {
      getAllClass()
      getAllTeacher()
    }, [])
  
    const dataTransferChange = (event) => {
      const {name, value} = event.target
  
      setDataTransferClass((currentData) => ({
          ...currentData,
          [name]: value
      }))
    }

    const dataStudentRRChange = (event) => {
        const {name, value} = event.target;
    
        setDataStudentRR((currentData) => ({
            ...currentData,
            [name]: value
        }));
    }
  
    const resetDataTransfer = () => {
      setDataTransferClass({
          classId: null,
          teacherId: null
      })
    }
    
    const resetDataStudentRR = () => {
        setDataStudentRR({
            classId: null,
            continuationString: ''
        })
    }
  
    const showDialog = (type) => {
      if (type == "TransferClass") {
          if (dataTransferClass.classId !== null && dataTransferClass.guruBaru !== null) {
              setTransferClassDialog(true)
          } else {
              setValidationDialog(true)
          }
          
      }
      if (type  == "StudentRR") {
        if (dataStudentRR.classId !== null && dataStudentRR.continuationString !== "") {
            setStudentRRDialog(true)
        } else {
            setValidationDialog(true)
        }
      }
      if (type == "SuccessTransfer") setSuccessTransferDialog(true)
      if (type == "FailValidation") setValidationDialog(true)
    }
  
    const hideDialog = (type) => {
      if (type == "TransferClass") setTransferClassDialog(false)
      if (type == "StudentRR") setStudentRRDialog(false)
      if (type == "SuccessTransfer") setSuccessTransferDialog(false)
      if (type == "FailValidation") setValidationDialog(false)
      if (type == "Error") setErrorDialog(false)
    }
  
    const transferClassFooter = (type) => (
      <React.Fragment>
          <div className='flex-auto text-center'>
              <Button label="Batal" icon="pi pi-times" outlined  severity='danger' className='mr-3' onClick={() => hideDialog(type)}/>
              <Button label="Yakin" icon="pi pi-check" severity="danger" onClick={() => transferClass()}/>
          </div>
      </React.Fragment>
    )

    const studentRRFooter = (type) => (
        <React.Fragment>
            <div className='flex-auto text-center'>
                <Button label="Batal" icon="pi pi-times" outlined  severity='danger' className='mr-3' onClick={() => hideDialog(type)}/>
                <Button label="Yakin" icon="pi pi-check" severity="danger" onClick={() => StudentRR()}/>
            </div>
        </React.Fragment>
      )
  
    const validationFooter = (type) => (
      <React.Fragment>
          <div className='flex-auto text-center'>
              <Button label="Tutup" icon="pi pi-times" severity='danger' className='mr-3' onClick={() => hideDialog(type)}/>
          </div>
      </React.Fragment>
    )
  
    const errorFooter = (type) => (
      <React.Fragment>
          <div className='flex-auto text-center'>
              <Button label="Tutup" icon="pi pi-times" severity='danger' className='mr-3' onClick={() => hideDialog(type)}/>
          </div>
      </React.Fragment>
    )
  
    const successFooter = (type) => (
      <React.Fragment>
          <div className='flex-auto text-center'>
              <Button label="Tutup" icon="pi pi-times" severity='info' className='mr-3' onClick={() => hideDialog(type)}/>
          </div>
      </React.Fragment>
    )
  
    return (
      <ContainerPage>
          <Loading visible={loading}/>
          <Dialog header="Transfer Kelas" visible={transferClassDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
            footer={() => transferClassFooter("TransferClass")} onHide={() => hideDialog("TransferClass")}
          > 
              <div className='text-center'>
                  <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                  <h5>Yakin ingin melakukan transfer kelas ?</h5>
              </div>
          </Dialog>

          <Dialog header="Student RR" visible={studentRRDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
            footer={() => studentRRFooter("StudentRR")} onHide={() => hideDialog("StudentRR")}
          > 
              <div className='text-center'>
                  <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                  <h5>Yakin ingin melakukan Student RR ?</h5>
              </div>
          </Dialog>
  
          <Dialog header="Validasi Gagal" visible={validationDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
            footer={() => validationFooter("FailValidation")} onHide={() => hideDialog("FailValidation")}
          >
              <div className='text-center'>
                  <i className="pi pi-megaphone mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                  <h5>Pastikan semua data sudah terisi!</h5>
              </div>
          </Dialog>
  
          <Dialog header="Gagal Transfer Kelas" visible={errorDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
            footer={() => errorFooter("Error")} onHide={() => hideDialog("Error")}
          >
              <div className='text-center'>
                  <i className="pi pi-times-circle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                  <h5>{errorMessage}</h5>
              </div>
          </Dialog>
  
          <Dialog header="Transfer Kelas Berhasil" visible={successTransferDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
            footer={() => successFooter("SuccessTransfer")} onHide={() => hideDialog("SuccessTransfer")}
          >
              <div className='text-center'>
                  <i className="pi pi-thumbs-up mb-3" style={{ fontSize: '7rem', color:'var(--blue-400)' }} />
                  <h5>Yeay, berhasil mengeksekusi fitur!!!</h5>
              </div>
          </Dialog>
          
          <div className='d-flex align-items-center gap-2 mb-3'>
            <Button label='Transfer Kelas' icon='pi pi-arrow-right-arrow-left' outlined={activeMenu == 'TransferClass'? false : true}
             severity='danger' style={{borderRadius: '10px', paddingBlock: '5px'}} onClick={() => setActiveMenu('TransferClass')} />
            <Button label='Student RR' icon='pi pi-map' severity='success' outlined={activeMenu == 'StudentRR'? false : true} 
             style={{borderRadius: '10px', paddingBlock: '5px'}} onClick={() => setActiveMenu('StudentRR')}/>
            <Button label='Other' icon='pi pi-arrow-right-arrow-left' outlined={activeMenu == 'Another'? false : true} 
             severity='info' style={{borderRadius: '10px', paddingBlock: '5px'}} onClick={() => setActiveMenu('Another')}/>
          </div>

          <Card style={activeMenu == 'TransferClass'? {display: 'block'} : {display: 'none'}}>
              <Card.Title className='d-flex gap-2 align-items-end justify-content-center mt-3'>
                  <img src={IconEdufic} alt="Icon-Edufic" height='60'/>
                  <h2>Transfer Kelas</h2>
              </Card.Title>
              <Card.Body className='d-flex flex-column gap-3'>
                  <div className="flex-auto">
                      <label htmlFor="classId" className="font-bold block mb-2">Daftar Kelas</label>
                      <Dropdown value={dataTransferClass.classId} options={listClass} id="classId" name="classId" filter
                       optionValue='_id' optionLabel='subjek' placeholder='Masukkan Kelas' className='w-full' onChange={dataTransferChange}/>
                  </div>
                  <div className="flex-auto">
                      <label htmlFor="guruBaru" className="font-bold block mb-2">Guru Pengganti</label>
                      <Dropdown value={dataTransferClass.guruBaru} options={listTeacher} id="guruBaru" name="guruBaru" filter
                       optionValue='_id' optionLabel='nama'  placeholder='Masukkan Nama Guru Pengganti' className='w-full' onChange={dataTransferChange}/>
                  </div>
  
                  <div className='text-center'>
                      <button type='submit' className={styles.mybutton} onClick={() => showDialog('TransferClass')}>
                          Transfer Kelas
                      </button>
                  </div>
              </Card.Body>
          </Card>

          <Card style={activeMenu == 'StudentRR'? {display: 'block'} : {display: 'none'}}>
            <Card.Title className='d-flex gap-2 align-items-end justify-content-center mt-3'>
                <img src={IconEdufic} alt="Icon-Edufic" height='60'/>
                <h2>Student RR</h2>
            </Card.Title>
            <Card.Body className='d-flex flex-column gap-3'>
                <div className="flex-auto">
                    <label htmlFor="classId" className="font-bold block mb-2">Daftar Kelas</label>
                    <Dropdown value={dataStudentRR.classId} options={listClass} id="classId" name="classId" filter
                     optionValue='_id' optionLabel='subjek' placeholder='Masukkan Kelas' className='w-full' onChange={dataStudentRRChange}/>
                </div>
                <div className="flex-auto">
                    <label htmlFor="guruBaru" className="font-bold block mb-2">Rincian Kelanjutan</label>
                    <InputTextarea value={dataStudentRR.continuationString} id="continuationString" name="continuationString" placeholder='Masukkan rincian kelanjutan siswa' 
                     className='w-full' rows={4} onChange={dataStudentRRChange}/>
                </div>

                <div className='text-center'>
                    <button type='submit' className={styles.mybutton} onClick={() => showDialog('StudentRR')}>
                        Submit
                    </button>
                </div>
            </Card.Body>
          </Card>
      </ContainerPage>
    )
}

export default ClassManagement
