import React, { useEffect, useState } from 'react'
import styles from './DetailStudent.module.css'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { BASE_API_URL, formatIDRCurrency, formattedDate, formattedDateWithDay, getAgeByDate, stringToDate } from '../../helpers/general'
import axios from 'axios'
import Loading from '../../components/Loading'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Chart } from 'primereact/chart'
import { Tag } from 'primereact/tag'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'

const DetailStudent = () => {
  const { id } = useParams()
  const [ loading, setLoading ] = useState(false)
  const [ showDialogPayment, setShowDialogPayment ] = useState(false)
  const [ listStudentClass, setListStudentClass ] = useState(null)
  const [ chartData, setChartData ] = useState(null)
  const [ chartOptions, setChartOptions ] = useState(null)
  const [ chartClassData, setChartClassData ] = useState(null)
  const [ listPembayaran, setListPembayaran ] = useState(null)
  const [ chartClassOptions, setChartClassOptions ] = useState(null)
  const [ detailStudent, setDetailStudent ] = useState({
    _id: id,
    nama: '',
    nomor: null,
    usia: null,
    domisili: '',
    sekolah: '',
    ibu: '',
    pekerjaanIbu: '',
    pekerjaanAyah: '',
    status: '',
    funnel: '',
  })
  const [ detailDialog, setDetailDialog ] = useState({
    status: {
        error: 'false',
    },
    title: '',
    message: '',
    icon: '',
    severity: '',
    footer: null,
  })
  const [ detailPayment, setDetailPayment ] = useState({
    studentId: id,
    paymentIndex: null,
    waktuBayar: null,
    terBayar: 0,
    statusBayar: ""
  })

  const detailPaymentChange = (event) => {
    const {name, value} = event.target

    setDetailPayment((currentData) => ({
        ...currentData,
        [name]: value
    }))
  }

  const getDetailStudent = async () => {
    setLoading(true)

    const optionGetDetailStudent = {
        method: 'get',
        url: `${BASE_API_URL}/student/${id}`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        }
    }

    await axios.request(optionGetDetailStudent)
      .then((response) => {
        setDetailStudent((currentData) => ({
            ...currentData,
            nama: response.data.nama,
            nomor: response.data.nomor,
            usia: getAgeByDate(response.data.tanggalLahir),
            domisili: response.data.domisili,
            sekolah: response.data.sekolah,
            ibu: response.data.ibu,
            pekerjaanIbu: response.data.pekerjaanIbu,
            pekerjaanAyah: response.data.pekerjaanAyah,
            funnel: response.data.funnel,
            status: response.data.status,
        }))
        setListPembayaran(response.data.pembayaran)
        getListClass(response.data.kelas)
      })
      .catch((error) => {
        setDetailDialog({
            status: {error: true},
            title: 'Gagal Memuat Data',
            message: 'Gagal memuat detail murid karena '+error.response.data.message,
            icon: 'pi-exclamation-triangle',
            severity: 'danger',
            footer: errorDialogFooter
        })
      })
    
    setLoading(false)
  }

  const getListClass = async (dataClassList) => {
    const dataPayload = {
        idKelas: dataClassList
    }

    const optionsListClass = {
        method: 'post',
        url: `${BASE_API_URL}/classes`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('accessToken')
        },
        data: dataPayload 
    }

    await axios.request(optionsListClass)
      .then((response) => {
        setListStudentClass(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSeverity = (data) => {
    switch (data) {
        case 'Aktif':
            return 'primary';

        case 'Keluar': 
            return 'danger';
    
        case 'Pasif':
            return 'warning';

        default:
            return 'success';
    }
  }

  useEffect(() => {
    getDetailStudent()
  }, [])

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const data = {
        labels: ['ICWS 10.1 (2)', 'ICWS 11.5 (5)', 'IE 12.2 (3)', 'IE 13.7 (7)', 'IC 15.9 (2)', 'IC 17.2 (5)'],
        datasets: [
            {
                type: 'bar',
                label: 'Hadir',
                backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                data: [11, 8, 12, 6, 9, 10]
            },
            {
                type: 'bar',
                label: 'Sakit',
                backgroundColor: documentStyle.getPropertyValue('--green-600'),
                data: [1, 2, 0, 2, 1, 0]
            },
            {
                type: 'bar',
                label: 'Izin',
                backgroundColor: documentStyle.getPropertyValue('--yellow-400'),
                data: [0, 1, 0, 2, 2, 1]
            },
            {
                type: 'bar',
                label: 'Tidak Hadir',
                backgroundColor: documentStyle.getPropertyValue('--red-500'),
                data: [0, 1, 0, 2, 0, 1]
            }
        ]
    };
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                stacked: true,
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  const updateDetailPayment = async () => {
    setShowDialogPayment(false)
    setLoading(true)
    const reformat = detailPayment.waktuBayar.split("-")
    const newDate = `${reformat[2]}-${reformat[1]}-${reformat[0]}`

    const optionUpdatePayment = {
        method: 'put',
        url: `${BASE_API_URL}/updateStudentPayment`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        },
        data: {
            studentId: detailPayment.studentId,
            paymentIndex: detailPayment.paymentIndex,
            waktuBayar: new Date(newDate),
            terBayar: detailPayment.terBayar,
            statusBayar: detailPayment.terBayar > 0 ? "Sudah dibayar" : "Belum dibayar"
        }
    }

    await axios.request(optionUpdatePayment)
      .then(() => {
        getDetailStudent()
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  } 

  const hideDialog = () => {
    setDetailDialog({
        status: {
            error: 'false',
        },
        title: '',
        message: '',
        icon: '',
        severity: '',
        footer: null,
    })
  }

  const errorDialogFooter = () => (
    <div className='flex-auto text-center'>
        <Button label="Oke" className='mr-3' severity='danger' onClick={() => hideDialog("Error")}/>
    </div>
  )

  const statusStudentLabel = (data) => (
    <Tag value={data} severity={getSeverity(data)} />
  )

  const cardBody = (data) => {
    const labelPembayaran = listPembayaran[data.index] != null ? 
                            listPembayaran[data.index].statusBayar : 
                            "Belum dibayar"
    return (
        <Card>
            <Card.Body>
                <div className={styles.cardClassLayout}>
                    <div className='flex justify-content-between align-items-center'>
                        <h3 style={{margin: 0}}>{data.namaKelas}</h3>
                        <a href={`/classes/${data._id}`} style={{textDecoration: 'none'}}>
                            <Button label='Detail' severity='primary' style={{borderRadius: '10px', height: '40px'}}/>
                        </a>
                    </div>

                    <p className={styles.teacherName}><strong>Guru : </strong><i>{data.guru.nama}</i></p>
                    <div className='flex justify-content-between'>
                        <div>
                            <p>Jadwal Mulai</p>
                            <h5>{formattedDateWithDay(data.jadwal)}</h5>
                        </div>
                        <div className='text-center'>
                            <p>Level</p>
                            <h5 className={styles.level}>{data.level}</h5>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', gap: '15px', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Button label={labelPembayaran} severity={labelPembayaran == "Sudah dibayar"? 'success' : 'danger'} style={{borderRadius: '10px', height: '40px'}} onClick={() => {
                        const waktuBayar = new Date(listPembayaran[data.index].waktuBayar)
                        const newYear = waktuBayar.getFullYear()
                        const newMonth = (waktuBayar.getMonth()+1).toString().length ? `0${waktuBayar.getMonth()+1}`: waktuBayar.getMonth()+1
                        const newDate = waktuBayar.getDate()

                        setDetailPayment((currentData) => ({
                            ...currentData, 
                            paymentIndex: data.index,
                            terBayar: listPembayaran[data.index].terBayar,
                            waktuBayar: `${newDate}-${newMonth}-${newYear}`
                        }))
                        setShowDialogPayment(true) 
                      }
                    }/>
                    {
                        labelPembayaran == "Belum dibayar" ? "" : <p style={{margin: 0}}>{formatIDRCurrency(listPembayaran[data.index].terBayar)}</p>
                    }
                </div>
                
            </Card.Body>
        </Card>
    )    
  }

  const cardClassLayout = (dataList) => {
    const listCard = []

    if (Array.isArray(dataList)) {
        dataList.map((cardStudent, index) => {
            cardStudent['index'] = index
            listCard.push( cardBody(cardStudent) )
        })
    }
    return listCard
  }

  const updatePaymentDialogFooter = () => (
    <React.Fragment>
        <Button label="Batal" icon="pi pi-times" outlined onClick={() => setShowDialogPayment(false)} className='mr-3'/>
        <Button label="Bayar" icon="pi pi-check" onClick={() => updateDetailPayment()}/>
    </React.Fragment>
  );



  return (
    <ContainerPage>
        <Loading visible={loading} />
        
        <Dialog header="Detail Pembayaran" visible={showDialogPayment} onHide={() => setShowDialogPayment(false)}
            style={{ width: '25rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }} footer={updatePaymentDialogFooter}
        >
            <div className="flex-auto">
                <label htmlFor="buttondisplay" className="font-bold block mb-2">
                    Tgl Pembayaran
                </label>
                <InputMask id='waktuBayar' name='waktuBayar' value={detailPayment.waktuBayar} onChange={detailPaymentChange} 
                 mask='99-99-9999' placeholder='dd-mm-yyyy' className='w-full' />
                {/* <Calendar id="payValue" value={detailPayment.waktuBayar} onChange={detailPaymentChange} dateFormat='dd-mm-yy' showIcon /> */}
            </div>

            <div className="flex-auto">
                <label htmlFor="buttondisplay" className="font-bold block mb-2">
                    Nominal
                </label>
                <InputNumber id='terBayar' name='terBayar' value={detailPayment.terBayar} onValueChange={detailPaymentChange} 
                 mode="currency" currency="IDR" locale="id-ID" className='w-full' min={0}/>
            </div>
        </Dialog>

        <Card>
            <Card.Body>
                <div className={styles.detailClassLayout}>
                    <h4>Detail Murid</h4>
                    <div className='flex gap-5 justify-content-between'>
                        <table className={styles.tableLayout}>
                            <tbody>
                                <tr>
                                    <td className={styles.infoTitle}>Nama</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailStudent.nama}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Usia</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailStudent.usia} Tahun</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Domisili</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailStudent.domisili}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Sekolah</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailStudent.sekolah}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Nomor</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>
                                        <a href={`https://wa.me/${detailStudent.nomor}`} target='_blank' style={{textDecoration: 'none'}}>
                                            <div className={styles.myButton}>
                                                <i className='pi pi-whatsapp'></i><span>{detailStudent.nomor}</span>
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Nama Ibu</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{detailStudent.ibu}</td>
                                </tr>
                                <tr>
                                    <td className={styles.infoTitle}>Status</td>
                                    <td className={styles.separatial}>:</td>
                                    <td>{statusStudentLabel(detailStudent.status)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{width: '50rem'}}>
                            <Chart type="bar" data={chartData} options={chartOptions} className='w-full'/>
                        </div>
                    </div>
                    
                </div>
            </Card.Body>
        </Card>

        <h5 className='mt-3'>Daftar Kelas</h5>
        <div className={styles.listClassLayout}>
            {cardClassLayout(listStudentClass)}
        </div>
    </ContainerPage>
  )
}

export default DetailStudent
