import React from 'react'
import NavBar from '../NavBar/NavBar'
import { Container } from 'react-bootstrap'
import styles from './ContainerPage.module.css'
import SideBar from '../SideBar/SideBar'
import MainContent from '../MainContent/MainContent'

const ContainerPage = (props) => {
  const isLoggin = localStorage.getItem('accessToken')
  return (
    isLoggin ? 
      <div className={styles.layouts}>
        <div className={styles.mainContent}>
          <SideBar/>
          <MainContent>{props.children}</MainContent>
        </div>
      </div>
      : 
      <div className={styles.layouts}>
        <NavBar/>
        <Container>{props.children}</Container>
      </div> 
  )
}

export default ContainerPage
