import React, { useEffect, useRef } from 'react'
import styles from './ListSalary.module.css'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import Loading from '../../components/Loading'
import { useState } from 'react'
import { BASE_API_URL, formatAccounting, getSeverity } from '../../helpers/general'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import axios from 'axios'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import months from '../../helpers/listMonth.js'

const ListSalary = () => {
  const [ loading, setLoading ] = useState(false)
  const [ listSalary, setListSalary ] = useState(null)
  const salaryRef = useRef(null)
  const [ addTeacherSalaryDialog, setAddTeachersalaryDialog ] = useState(false)
  const [ dialogAttribute, setDialogAttribute ] = useState({
    header: '',
    message: '',
    icon: ''
  })
  const [ listTeachers, setListTeachers ] = useState(null)
  const [ detailTeacher, setDetailTeacher ] = useState({
    id: '',
    nama: '',
    rekening: '',
    domisili: '',
    pendidkan: ''
  })
  const [ month, setMonth ] = useState(new Date().getMonth()-1)

  const [ generalInfoSalary, setGeneralInfoSalary ] = useState({
    bulan: month,
    gajiTerbayar: 0,
    gajiBelumTerbayar: 0,
    totalGaji: 0
  })

  //attribute for search function 
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getAllListSalary = async () => {
    setLoading(true)

    const optionGetAllListSalary = {
        method: 'get',
        url: `${BASE_API_URL}/getSalary`,
        headers: {
            'Content-Type': 'application/json'
        }
    }

    await axios.request(optionGetAllListSalary)
      .then((response) => {
        const listData = []

        if (response.data !== null || !response.data) {
            const data = response.data

            data.map((rowData, index) => {
                const lastIndexGaji = rowData.listGaji.length > 0 ? rowData.listGaji.length - 1 : -1
                const lastMonthGaji = rowData.listGaji.length > 0 ? rowData.listGaji[lastIndexGaji].jumlahGaji : 0

                rowData['index'] = index + 1
                rowData['qtyKelas'] = rowData.guru.kelas.length
                rowData['rekening'] = `${rowData.guru.namaBank} - ${rowData.guru.rekening}`
                rowData['lastIndexGaji'] = lastIndexGaji
                rowData['lastMonthSalary'] = lastMonthGaji
                listData.push(rowData)
            })
        }

        setListSalary(listData)
        setLoading(false)
      })
      .catch((error) => {
        setDialogAttribute((currentData) => ({
            ...currentData,
            header: 'Gagal Memuat Daftar Gaji',
            message: error
        }))

        setLoading(false)
      })

  }

  const getGeneralInfoSalary = async () => {
    setLoading(true)
    const optionGeneralInfoSalary = {
      method: 'put',
      url: `${BASE_API_URL}/general-info-salary`,
      headers: {
        'Authorization': localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      data: {
        month: month
      }
    }

    await axios.request(optionGeneralInfoSalary)
      .then((response) => {
        console.log(response.data)
        setGeneralInfoSalary(response.data.response)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const getAllTeacher = async () => {
    setLoading(true)

    const optionGetAllTeacher = {
        method: 'get',
        url: `${BASE_API_URL}/teachers`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionGetAllTeacher)
      .then((response) => {
        setListTeachers(response.data)
        setLoading(false)
      })
      .catch((error) => {
        setDialogAttribute((currentData) => ({
            ...currentData,
            header: 'Gagal Memuat Daftar Guru',
            message: error
        }))

        setLoading(false)
      })
  }

  const addTeacherDataSalary = async () => {
    setAddTeachersalaryDialog(false)
    setLoading(true)
    
    const addTeacherDataSalaryOption = {
        method: 'post',
        url: `${BASE_API_URL}/new-salary`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        },
        data: {
            idTeacher: detailTeacher.id
        }
    }

    await axios.request(addTeacherDataSalaryOption)
      .then(() => {
        refreshTable()
        setLoading(false)
      })
      .catch((error) => {
        setDialogAttribute((currentData) => ({
            ...currentData,
            header: 'Gagal Tambah Data Gaji Guru',
            message: error
        }))

        setLoading(false)
      })
  }

  useState(() => {
    getAllListSalary()
    getAllTeacher()
    getGeneralInfoSalary()
  }, [])

  useEffect(() => {
    getGeneralInfoSalary()
  }, [month])
  const refreshTable = () => {
    getAllListSalary()
  }

  const resetDetailTeacher = () => {
    setDetailTeacher({
        nama: '',
        rekening: '',
        domisili: '',
        pendidkan: ''
    })
  }

  const showDialog = (typeDialog) => {
    if (typeDialog == "AddTeacher") {
        setDialogAttribute((currentAttribute) => ({
            ...currentAttribute,
            header: 'Data Gaji Guru'
        }))
        setAddTeachersalaryDialog(true)
    }
  }
 
  const hideDialog = (typeDialog) => {
    if (typeDialog == "AddTeacher") {
        resetDetailTeacher()
        setAddTeachersalaryDialog(false)
    } 
  }

  const headerSalaryTemplate = () => (
    <div className={styles.searchBarLayout}>
        <div className={styles.cardHeader}>
            <p className={styles.cardTitle}>Daftar Gaji Guru</p>
        </div>
        <div>
            <span className='p-input-icon-left'>
                <i className='pi pi-search'></i>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search"/>
            </span>
            <button className={styles.buttonAdd} onClick={() => showDialog("AddTeacher")}>Data Gaji Guru +</button>
        </div>
    </div>
  )

  const salaryFormat = (rowData) => {
    const newFormattedData = rowData.lastMonthSalary.toLocaleString("id-ID", {style:"currency", currency:"IDR"});
    return newFormattedData
  }

  const statusSalaryLabel = (rowData) => {
    const gaji = rowData.lastMonthSalary
    const dibayar = rowData.lastIndexGaji == -1 ? 0 : rowData.listGaji[rowData.lastIndexGaji].dibayar
    let label

    if (gaji > 0 && dibayar == gaji) label = 'Terbayar'
    if (gaji > 0 && dibayar < gaji && dibayar > 0) label = 'Diangsur'
    if (gaji > 0 && dibayar == 0) label = 'Belum dibayar'
    if (gaji > 0 && dibayar > gaji) label = 'Data Spesial'
    if (gaji == 0) label = 'No Action'

    return <Tag value={label} severity={getSeverity('Salary', label)}></Tag>

  }

  const actionBodyTemplate = (rowData) => (
    <React.Fragment>
        <a href={`/salary/${rowData._id}`}><Button icon="pi pi-eye" className='ml-2' outlined severity='success' style={{borderRadius: '10px', padding: '5px'}}/></a>
    </React.Fragment>
  )

  const footerAddTeacherSalary = () => (
    <React.Fragment>
        <div className='flex-auto text-center'>
            <Button label="Batal" icon="pi pi-times" outlined className='mr-3' severity='danger' onClick={() => hideDialog("AddTeacher")}/>
            <Button label="Tambahkan" icon="pi pi-check" severity="primary" onClick={() => addTeacherDataSalary()}/>
        </div>
    </React.Fragment>
  )

  return (
    <ContainerPage>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <h3 style={{fontWeight: 'bold'}}>Management Gaji</h3>
          <Dropdown value={month} options={months} placeholder='Pilih bulan' optionLabel='nameMonth' optionValue='id'
           onChange={(e) => setMonth(e.target.value)} />
        </div>
        <div className={styles.containerNotif}>
          <Card  className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Total Gaji</p>
            </Card.Header>
          <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-dollar' style={{backgroundColor: "#609af8"}}></i>
              <div className='text-end'>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className='d-flex gap-2'>
                  <p className={styles.cardValue}>Rp. {generalInfoSalary.totalGaji !== null? formatAccounting(generalInfoSalary.totalGaji) : 0}</p>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Gaji Belum Dibayar</p>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-exclamation-triangle' style={{backgroundColor: "#dc3545"}}></i>
              <div className='text-end'>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className="d-flex gap-2" >
                  <p className={styles.cardValue}>Rp. {generalInfoSalary.gajiBelumTerbayar !== null? formatAccounting(generalInfoSalary.gajiBelumTerbayar) : 0}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
          
          <Card className={styles.cardNotif}>
            <Card.Header className={styles.cardNotifHeaderLayout}>
              <p className={styles.cardNotifHeader}>Gaji Terbayar</p>
            </Card.Header>
            <Card.Body className={styles.cardNotifContent}>
              <i className='pi pi-check-circle' style={{backgroundColor: "#41a275"}}></i>
              <div className='text-end'>
                <p className={styles.subjectCard}>Jumlah</p>
                <div className='d-flex gap-2'>
                  <p className={styles.cardValue}>Rp. {generalInfoSalary.gajiTerbayar !== null? formatAccounting(generalInfoSalary.gajiTerbayar) : 0}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <Card>
            <Card.Body>
                <DataTable value={listSalary} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                    rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} scrollable header={headerSalaryTemplate}
                    globalFilterFields={['guru.nama', 'guru.namaBank', 'guru.rekening', 'qtyKelas', 'lastMonthSalary']} ref={salaryRef}
                >   
                    <Column field='index' sortable header="No" style={{ minWidth: '1rem' }}></Column>
                    <Column field='guru.nama' sortable header="Nama Guru" style={{ minWidth: '10rem' }}></Column>
                    <Column field='qtyKelas' sortable header="Jumlah Kelas" className='text-center' style={{ minWidth: '12rem' }}></Column>
                    <Column field='rekening' sortable header="Rekening" style={{ minWidth: '12rem' }}></Column>
                    <Column field='lastMonthSalary' sortable header="Gaji Terakhir" body={salaryFormat} style={{ minWidth: '10rem' }}></Column>
                    <Column field='lastIndexGaji' sortable header="Status" body={statusSalaryLabel} style={{ minWidth: '10rem' }}></Column>
                    <Column body={actionBodyTemplate} frozen alignFrozen='right' style={{minWidth: '8rem'}}></Column>
                    
                </DataTable>
            </Card.Body>
        </Card>

        <Loading visible={loading}/>
        <Dialog visible={addTeacherSalaryDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={dialogAttribute.header} footer={footerAddTeacherSalary} onHide={() => hideDialog("AddTeacher")}
        >
          <div className='flex-auto mb-3'>
            <label htmlFor="namaGuru" className='font-bold block mb-2'>Subjek Kelas</label>
            <Dropdown 
                value={detailTeacher.nama} options={listTeachers} optionLabel="nama" placeholder="Pilih Guru" filter
                onChange={(e) => setDetailTeacher((currentData) => ({
                    ...currentData,
                    id: e.target.value._id, 
                    nama: e.value,
                    rekening: `${e.target.value.namaBank} - ${e.target.value.rekening}`,
                    domisili: e.target.value.domisili,
                    pendidkan: e.target.value.pendidikan
                }))} 
                className='w-full'
            />
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="domisli" className='font-bold block mb-2'>Domisili</label>
            <InputText value={detailTeacher.domisili} className='w-full' />
          </div>
          <div className='flex-auto mb-3'>
            <label htmlFor="Rekening" className='font-bold block mb-2'>Rekening</label>
            <InputText value={detailTeacher.rekening} className='w-full'/>
          </div>
        </Dialog>
    </ContainerPage>
  )
}

export default ListSalary
