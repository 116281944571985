import React from 'react'
import { EduficLoading } from '../assets'
import styles from './Loading.module.css'
import { Modal } from 'react-bootstrap'

const Loading = ({visible}) => {
  return (
    <Modal show={visible} centered>
      <Modal.Body>
        <div className={styles.layout}>
          <p style={{fontWeight: 'bold', fontSize: '25px'}}>Loading</p>
          <img src={EduficLoading} alt="Edufic-Loading" height={150} className='mb-3'/>
          <p style={{fontSize: '18px'}}>Mohon tunggu sebentar ...</p>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Loading
