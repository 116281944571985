import React, { useEffect, useState } from 'react'
import styles from './ResetPassword.module.css'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card } from 'react-bootstrap'
import { IconEdufic } from '../../assets'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Password } from 'primereact/password'
import { BASE_API_URL } from '../../helpers/general'
import axios from 'axios'
import Loading from '../../components/Loading'
import { Dialog } from 'primereact/dialog'
import { confirmDialog } from 'primereact/confirmdialog'

const ResetPassword = () => {
  const [ showPassword, setShowPassword ] = useState(false)
  const [ teachers, setTeachers ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ resetPasswordDialog, setResetPasswordDialog ] = useState(false)
  const [ successResetDialog, setSuccessResetDialog ] = useState(false)
  const [dataUser, setDataUser] = useState({
    teacher: '',
    password: ''
  })
  const [ errorDialog, setErrroDialog ] = useState({
    header: '',
    message: '',
    visibility: false
  })

  const userChange = (event) => {
    const { name, value } = event.target
    setDataUser((currentValue) => ({
      ...currentValue,
      [name]: value
    }))
  }

  const getAllTeacher = async () => {
    setLoading(true)
    const optionsGetAllTeacher = {
        method: 'get',
        url: `${BASE_API_URL}/teachers`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionsGetAllTeacher)
      .then((response) => {
        setLoading(false)
        setTeachers(response.data)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getAllTeacher()
  }, [])

  const resetPassword = async () => {
    setLoading(true)
    setResetPasswordDialog(false)

    const dataNewUser = {
        idTeacher: dataUser.teacher,
        newPassword: dataUser.password
    }

    const optionNewUser = {
        method: 'post',
        url: `${BASE_API_URL}/set-new-password`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('accessToken')
        },
        data: dataNewUser
    }

    await axios.request(optionNewUser)
      .then(() => {
        setLoading(false)
        setDataUser({
            teacher: '',
            password: ''
        })
        showDialog("succesReset")
      })
      .catch((error) => {
        setErrroDialog({
            header: "Gagal Reset Password",
            message: "Terjadi kegagalan saat mereset password guru" + error,
            visibility: true
        })
        setLoading(false)
      })
  }

  const showDialog = (data) => {
    if (data == "confirmReset") {
        if (dataUser.teacher == '' || dataUser.password == '') {
            setErrroDialog({
                header: "Gagal Mereset Password",
                message: "Guru atau New Password masih kosong",
                visibility: true,
            })
        } else {
            setResetPasswordDialog(true)
        }
    }

    if (data == "succesReset") {
        setSuccessResetDialog(true)
    }
  }

  const hideDialog = (data) => {
    if (data == "confirmReset") {
        setResetPasswordDialog(false)
    }

    if (data == "successReset") {
        setSuccessResetDialog(false)
    }

    if (data == "error"){
        setErrroDialog({
            header: "",
            message: "",
            visibility: false,
        })
    }
  }

  const verifDialogFooter = () => (
    <React.Fragment>
        <div className='flex-auto text-center'>
            <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog("confirmReset")}/>
            <Button label="Yakin" icon="pi pi-check" severity="danger" onClick={() => resetPassword()}/>
        </div>
    </React.Fragment>
  )

  const confirmDialogFooter = () => (
    <div className='flex-auto text-center'>
        <Button label="Oke" className='mr-3' severity='primary' onClick={() => hideDialog("successReset")}/>
    </div>
  )

  const errorDialogFooter = () => (
    <div className='flex-auto text-center'>
        <Button label="Tutup" className='mr-3' severity='danger' onClick={() => hideDialog("error")}/>
    </div>
  )

  return (
    <ContainerPage>
      <div>
        <Card>
          <Card.Body >
              <Card.Title className='text-center mb-3'>
                  <img 
                  src={IconEdufic} 
                  alt="Icon-Edufic" 
                  height='70'
                  />
                  <h2>Reset Password</h2>
              </Card.Title>
              <div className="flex flex-wrap gap-3 p-fluid">
                <div className="flex-auto">
                    <label htmlFor="teacher" className="font-bold block mb-2">Teacher</label>
                    <Dropdown value={dataUser.teacher} options={teachers} id="teacher" name="teacher" filter
                     optionValue='_id' optionLabel='nama'  placeholder='Masukkan Guru' onChange={userChange}/>
                </div>
                <div className="flex-auto">
                    <label htmlFor="password" className="font-bold block mb-2">Password</label>
                    <Password value={dataUser.password} id='password' name='password' placeholder='Pasword Baru' toggleMask onChange={userChange}/>
                </div>
              </div>
              <div className='text-center'>
                <button type='submit' className={styles.mybutton} onClick={() => showDialog('confirmReset')}>
                    Reset Password
                </button>
              </div>
          </Card.Body>
        </Card>
 
        <Dialog visible={successResetDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header="Berhasil reset password" footer={confirmDialogFooter} onHide={() => hideDialog('successReset')}>
            <div className='text-center'>
                <i className="pi pi-check-circle" style={{ fontSize: '6rem', color: 'var(--primary-color)' }}></i>
                <p style={{fontSize: '22', fontWeight: '500'}} className='mt-3 mb-0'>Berhasil reset password guru</p>
            </div>
        </Dialog>

        <Dialog visible={resetPasswordDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Reset Password Guru" footer={verifDialogFooter} onHide={() => hideDialog("confirmReset")}>
            <div className="confirmation-content text-center">
                <i className="pi pi-exclamation-circle mb-4" style={{ fontSize: '6rem', color: 'var(--red-400)' }}/>
                <h5>Yakin ingin mereset password guru ?</h5>
            </div>
        </Dialog>

        <Dialog visible={errorDialog.visibility} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header={errorDialog.header} footer={errorDialogFooter} onHide={() => hideDialog('error')}>
            <div className='text-center'>
                <i className="pi pi-exclamation-triangle mb-4" style={{ fontSize: '6rem', color: 'var(--danger-500)' }}></i>
                <p style={{fontSize: '22', fontWeight: '500'}} className='mt-3 mb-0'>{errorDialog.message}</p>
            </div>
        </Dialog>

        <Loading/>
      </div>
    </ContainerPage>
  )
}

export default ResetPassword
