const BASE_API_URL = "https://be.eduficportal.com";
const BASE_URL = "https://eduficportal.com";
// const BASE_API_URL = "http://127.0.0.1:5000"
//const BASE_API_URL = "http://localhost:5003"

const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"
];

const formattedDate = (date) => { 
    const newDate = new Date(date);
    
    const formattedDay = newDate.toLocaleString("id-ID", {
        weekday: "long"
    });

    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = formattedDay + ', ' + formattedDate + " - " + formattedTime;
    
    return fullDate
}

const longStringToDate = (stringDate) => {
    const dateProcessed = stringDate.split(', ')[1];

    const time = `${dateProcessed.split(" - ")[1]}:00.045+07:00`;
    const rawdate = dateProcessed.split(" - ")[0];
    const date = rawdate.split(" ")[0];
    const year = rawdate.split(" ")[2];
    let month = months.findIndex((detailmonth) => detailmonth === rawdate.split(" ")[1]) + 1;
    
    if (month.toString().length === 1) month = `0${month}`;
    
    const dateResult = new Date(`${year}-${month}-${date}T${time}`)
    return dateResult;
}


const formattedDate2 = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} (${formattedTime})`

    return fullDate
}

const formattedDateWithDay = (date) => {
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const formattedTime = newDate.toLocaleTimeString('it-IT', { hour: "2-digit", minute: "2-digit" })

    const fullDate = `${formattedDate} - ${formattedTime}`

    return fullDate
}

const formattedDateWithOutTime = (date) => { 
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

const stringToDate = (stringDate) => {
    const newDate = new Date(stringDate)
    return newDate
}

const newFormatDate = (currentDate) => {
    const newDate = new Date(currentDate)
    return newDate;
}

const formatMonth = (stringDate) => {
    const newDate = new Date(stringDate)
    const formattedDate = newDate.toLocaleString("id-ID", {
        month: "long",
        year: "numeric",
    })

    const fullDate = formattedDate
    return fullDate
}

const getAgeByDate = (theDate) => {
    const currentDate = new Date()
    const bornDate = new Date(theDate)
    let getAge = currentDate.getUTCFullYear() - bornDate.getUTCFullYear()
    let getMonth = currentDate.getUTCMonth() - bornDate.getUTCMonth()
    let getDate = currentDate.getUTCDate() - bornDate.getUTCDate()

    if (getMonth <= 0 && getDate <= 0) getAge--
    // console.log(getAge)
    return getAge
}

const formatTTL = (bornPlace, bornDate) => {
    const newBornDate = formattedDateWithOutTime(bornDate)
    const newTTL = `${bornPlace}, ${newBornDate}`

    return newTTL
}

const getSeverity = (type, data) => {
    if (type === "teacherStatus") {
        switch (data) {
            case 'Berhenti': 
                return 'danger'

            case 'Aktif': 
                return 'success'

            case 'Pasif': 
                return 'secondary'
        
            default:
                return 'info';
        }
    }

    if (type === "classType") {
        switch (data) {
            case 'Private':
                return 'warning'
            
            case 'Small': 
                return 'primary'
            
            default:
                return 'secondary'
        }
    }

    if (type === "classStatus") {
        switch (data) {
            case "Aktif":
                return 'info'
            
            case  "Berhenti": 
                return 'danger'
        
            case "Selesai":
                return 'success'
            
            default:
                return 'primary';
        }
    }

    if (type === "Salary") {
        switch (data) {
            case 'Terbayar':
                return 'success'
            
            case 'Diangsur':
                return 'warning'
            
            case 'Belum dibayar': 
                return 'danger'

            case 'Data Spesial':
                return 'primary'
        
            default:
                return 'info';
        }
    }

    if (type === "attendancePTC") {
        switch (data) {
            case "Hadir":
                return 'success'
            
            case "Belum Diabsen": 
                return 'info'
            
            case "Tidak Hadir":
                return 'danger'

            default:
                return 'info';
        }
    }

    if (type === "kelanjutan") {
        switch (data) {
            case "Berhenti":
                return 'danger'
            
            case "Lanjut": 
                return 'success'

            default:
                return 'warning';
        }
    }

    if (type === "teacherType") {
        switch (data) {
            case "Super Teacher":
                return "rgb(223, 223, 17)";
            
            case "Volunteer Teacher":
                return "var(--blue-400)";

            case "Premium Teacher":
                return "var(--green-700)";
                
            default:
                return "primary";
        }
    }
}

const formatDecimal = (data) => {
    const newData = parseFloat(data).toFixed(2)
    return newData
}

const formatIDRCurrency = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return newData
}

const formatAccounting = (data) => {
    const newData = data.toLocaleString("id-ID", {style:"currency", currency:"IDR"})
    return (newData.slice(2))
}

export {
    BASE_API_URL, 
    formattedDate, 
    formattedDateWithOutTime, 
    stringToDate, 
    newFormatDate, 
    formattedDate2,
    getAgeByDate,
    formattedDateWithDay,
    formatTTL,
    getSeverity,
    formatDecimal,
    formatIDRCurrency,
    formatMonth,
    formatAccounting,
    longStringToDate,
    BASE_URL
}