import React from 'react';
import { Page, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        color: "black",
        padding: "40px",
        fontFamily: 'Times-Roman',
        fontSize: '12px',
    },
    viewer: {
        width: "100vw", //the pdf viewer will take up all of the width and height
        height: "100vh"
    },
});

// Create Document Component
const A4 = (props) => {
    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View style={styles.section} wrap>
                        {props.children}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default A4