import React, { useState } from 'react'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { IconEdufic, LogoEdufic } from '../../assets'
import styles from './Login.module.css'
import { Form, Card } from 'react-bootstrap'
import axios from 'axios'
import { BASE_API_URL } from '../../helpers/general'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const [dataAdmin, setDataAdmin] = useState({
    username: '',
    password: ''
  })
  const [ showPassword, setShowPassword ] = useState(false)

  const showingPassword = () => {
    setShowPassword(!showPassword)
  }

  const userChange = (event) => {
    const { name, value } = event.target
    setDataAdmin((currentValue) => ({
      ...currentValue,
      [name]: value
    }))
  }

  const userLogin = async (e) => {
    e.preventDefault()
    const dataLogin = {
      username: dataAdmin.username,
      password: dataAdmin.password,
    }

    const optionsLogin = {
      method: 'post',
      url: `${BASE_API_URL}/login`,
      data: dataLogin
    }

    await axios.request(optionsLogin)
      .then((response) => {
        if(response.status == 200) {
          localStorage.setItem('accessToken', response.data.token)
          navigate('/dashboard')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <ContainerPage>
      <div className={styles.myContainer}>
        <Card className={styles.myCard}>
          <Card.Body >
              <Card.Title className='text-center mb-3'>
                  <img 
                  src={IconEdufic} 
                  alt="Icon-Edufic" 
                  height='70'
                  />
                  <h2>Admin Portal</h2>
              </Card.Title>
              <Form id='form-submit' onSubmit={userLogin}>
                  <Form.Group className="mb-3" controlId="formBasicUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control 
                          type='username' 
                          placeholder="Masukkan Username Anda"
                          name='username'
                          onChange={userChange}
                          required
                      />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control 
                          type= {showPassword ? 'text' : 'password'} 
                          placeholder="Masukkan Password Anda"
                          name='password'
                          onChange={userChange}
                          required
                      />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check 
                          type="checkbox" 
                          label="Tampilkan kata sandi"  
                          onChange={showingPassword}
                      />
                  </Form.Group>
                  <div className='text-center'>
                      <button type='submit' className={styles.mybutton} onClick={userLogin}>
                          Login
                      </button>
                  </div>
              </Form>
          </Card.Body>
        </Card>
      </div>
    </ContainerPage>
  )
}

export default Login
