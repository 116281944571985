import React, { useEffect, useRef, useState } from 'react'
import Loading from '../../components/Loading'
import styles from './ListRoom.module.css'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card } from 'react-bootstrap'
import { BASE_API_URL, formattedDate } from '../../helpers/general'
import axios from 'axios'
import { Tag } from 'primereact/tag'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { SelectButton } from 'primereact/selectbutton'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'

const ListRoom = () => {
  const dt = useRef(null)
  const [ loading, setLoading ] = useState(false)
  const [ listRoom, setListRoom ] = useState(null)
  const [ copySuccess, setCopySuccess ] = useState('')
  const [ roomDetail, setRoomDetail ] = useState({
    namaRoom: '',
    linkRoom: '',
    meetingID: '',
    passCode: '',
    hostKey: '',
    status: ''
  })
  const [ classDetail, setClassDetail ] = useState({
    kodeKelas: '',
    namaKelas: '',
    level: '',
    namaGuru: '',
    mulaiKelas: '',
    berakhirKelas: ''
  })
  const [ dataGenerateRoom, setDataGenerateRoom ] = useState({
    idKelas: null,
    mulaiKelas: new Date(),
    durasi: 0,
  })
  const [ addUpdateDialog, setAddUpdateDialog ] = useState(false)
  const [ deleteDialog, setDeleteDialog ] =  useState(false)
  const [ generateRoom, setGenerateRoom ] =  useState(false)
  const [ detailDialog, setDetailDialog ] =  useState(false)
  const [ errorDialog, setErrorDialog ] = useState(false)
  const [ refreshTable, setRefreshTable ] = useState(false)
  const [ dialogTitle, setDialogTitle ] = useState(null) 
  const [ dialogMessage, setDialogMessage ] = useState(null)
  const [ listClass, setListClass ] = useState(null)
  const statusMaintenance = [
    {
      label: "Iya",
      value: "Maintenance"
    },
    {
      label: "Tidak",
      value: "Idle"
    }
  ]
  const [ successDialog, setSuccessDialog ] = useState({
    visibility: false,
    _id: null,
    namaRoom: '',
    linkRoom: '',
    meetingID: '',
    passCode: '',
    hostKey: '',
  })

  const roomChange = (event) => {
    const { name, value } = event.target
    
    setRoomDetail((currentData) => ({
        ...currentData,
        [name]: value
    }))
  }

  const generateChange = (event) => {
    const { name, value } = event.target
    
    setDataGenerateRoom((currentData) => ({
        ...currentData,
        [name]: value
    }))
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({selectionOnly})
  }

  const resetDetailRoom = () => {
    setRoomDetail({
        namaRoom: '',
        linkRoom: '',
        meetingID: '',
        passCode: '',
        hostKey: '',
        status: ''
    })
  }

  const showDialog = (dataRow, typeDialog) => {
    if (typeDialog === "Add Room") {
        setDialogTitle("Tambah Room")
        setAddUpdateDialog(true)
    } else if (typeDialog === "Generate Room") {
      setDialogTitle("Generate Room")
      setDataGenerateRoom((currentData) => ({
        ...currentData,
        mulaiKelas: new Date()
      }))
      setGenerateRoom(true)
    } else {
        setRoomDetail({
            _id: dataRow._id,
            namaRoom: dataRow.namaRoom,
            linkRoom: dataRow.linkRoom, 
            meetingID: dataRow.meetingID,
            passCode: dataRow.passCode,
            hostKey: dataRow.hostKey,
            status: dataRow.status
        })

        if (typeDialog === "Update Room") {
            setDialogTitle("Update Room")
            setAddUpdateDialog(true)
        }

        if (typeDialog === "Delete Room") {
            setDialogTitle("Hapus Room")
            setDeleteDialog(true)
        }

        if (typeDialog === "Detail Room") {
            setDialogTitle("Detail Kelas")
            getDetailClass(dataRow)
        }
    }
  }

  const hideDialog = () => {
    if (dialogTitle === "Tambah Room" ) setAddUpdateDialog(false)
    if (dialogTitle === "Hapus Room") setDeleteDialog(false)
    if (dialogTitle === "Eror") setErrorDialog(false)
    if (dialogTitle === "Update Room") setAddUpdateDialog(false)
    if (dialogTitle === "Detail Kelas") setDetailDialog(false)
    if (dialogTitle === "Generate Room") setGenerateRoom(false)
    
    resetDetailRoom()
  }

  const saveButtonHandler = () => {
    if (roomDetail._id == null) createRoom()
    else updateRoom() 
  }

  const getAllClass = async () => {
    setLoading(true)

    const optionsGetAllClass = {
        method: 'get',
        url: `${BASE_API_URL}/class`,
        headers:{
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        }
    }

    await axios.request(optionsGetAllClass)
     .then((response) => {
        setDialogTitle("Detail Kelas")
        
        const classes = response.data

        classes.map((kelas) => {
          kelas['subject'] = `[${kelas.guru.nama}] ${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl. ${kelas.level}`
        })

        setListClass(classes)
        setLoading(false)
     })
     .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Memuat List Kelas")
        setDialogMessage(error.response.data.message)
        setErrorDialog(true)
     })
  }

  const getAllRoom = async () => {
    setLoading(true)
    const optionGetAllRoom = {
        method: 'get',
        url: `${BASE_API_URL}/rooms`,
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionGetAllRoom)
     .then((response) => {
        setListRoom(response.data)
        setLoading(false)
     })
     .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Memuat Room")
        setDialogMessage(error.response.data.message)
        setErrorDialog(true)
     })
  }

  const createRoom = async () => {
    setAddUpdateDialog(false)
    setLoading(true)

    const dataRoom = roomDetail
    const optionCreateRoom = {
        method: 'post',
        url: `${BASE_API_URL}/rooms`,
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        },
        data: dataRoom
    }

    await axios.request(optionCreateRoom)
     .then(() => {
        setRefreshTable(!refreshTable)
        setLoading(false)
     })
     .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Menambah Room")
        setDialogMessage(error.response.data.message)
        setErrorDialog(true)
     })
  }

  const updateRoom = async () => {
    setAddUpdateDialog(false)
    setLoading(true)

    const dataUpdate = roomDetail
    const optionUpdateRoom = {
        method: 'put',
        url: `${BASE_API_URL}/rooms/${roomDetail._id}`,
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        },
        data: dataUpdate
    }

    await axios.request(optionUpdateRoom)
     .then(() => {
        setRefreshTable(!refreshTable)
        setLoading(false)
     })
     .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Update Room")
        setDialogMessage(error.response.data.message)
        setErrorDialog(true)
     })
  }

  const deleteRoom = async () => {
    setDeleteDialog(false)
    setLoading(true)

    const optionDeleteRoom = {
        method: 'delete',
        url: `${BASE_API_URL}/rooms/${roomDetail._id}`,
        headers: {
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionDeleteRoom)
     .then(() => {
        setRefreshTable(!refreshTable)
        setLoading(false)
     })
     .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Menghapus Room")
        setDialogMessage(error.response.data.message)
        setErrorDialog(true)
     })
  }

  const getDetailClass = async (rowData) => {
    setLoading(true)

    const optionsGetDetailClass = {
        method: 'get',
        url: `${BASE_API_URL}/class/${rowData.kelasPengguna}`,
        headers:{
            'Authorization': localStorage.getItem('accessToken')
        }
    }

    await axios.request(optionsGetDetailClass)
     .then((response) => {
        setDialogTitle("Detail Kelas")
        setClassDetail({
            kodeKelas: `${response.data.kodeBatch}.${response.data.kodeKelas}`,
            namaKelas: response.data.namaKelas,
            level: response.data.level,
            namaGuru: response.data.namaGuru,
            mulaiKelas: formattedDate(rowData.mulaiKelas),
            berakhirKelas: formattedDate(rowData.berakhirKelas)
        })
        setLoading(false)
        setDetailDialog(true)
     })
     .catch((error) => {
        setLoading(false)
        setDialogTitle("Gagal Memuat Detail Kelas")
        setDialogMessage(error.response.data.message)
        setErrorDialog(true)
     })

  }

  const setClipboard = (dataSuccessGenerateRoom) => {
    navigator.clipboard.writeText(
      `*Detail Room*\nNama Room:\t${successDialog.namaRoom}\nLink Room:\t${successDialog.linkRoom}\nMeeting ID:\t${successDialog.meetingID}\nPassCode:\t${successDialog.passCode}\n\nUntuk data dibawah ini hanya untuk teachers, ya\nHost Key:\t${successDialog.hostKey}`
    )

    setCopySuccess('Copied!')
  }

  const generateClassRoom = async () => {
    setLoading(true)
    setGenerateRoom(false)
    const dataGenerateClassRoom = dataGenerateRoom

    const optionsGenerateClassRoom = {
      method: 'put',
      url: `${BASE_API_URL}/booking-room`,
      headers: {
        'Authorization': localStorage.getItem('accessToken')
      },
      data: dataGenerateClassRoom
    }

    await axios.request(optionsGenerateClassRoom)
     .then((response) => {
      const room = response.data.newUpdateRoom

      setSuccessDialog({
        visibility: true,
        _id: room._id,
        namaRoom: room.namaRoom,
        linkRoom: room.linkRoom,
        meetingID: room.meetingID,
        passCode: room.passCode,
        hostKey: room.hostKey,
      })

      setRefreshTable(!refreshTable)
      setLoading(false)
     })
     .catch((error) => {
      setDialogTitle("Gagal Mendapatkan Ruangan Kelas")
      setDialogMessage(error.message)
      setLoading(false)
      setErrorDialog(true)
      setLoading(false)
      console.log(error)
     })
  }

  useEffect(() => {
    getAllRoom()
    getAllClass()
  }, [refreshTable])

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess('')
    }, 5000)
  }, [copySuccess])

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const linkLabel = (data) => {
    return (
        <a style={{textDecoration: 'none'}} href={data.linkRoom} target='_blank'><Tag 
          value={data.linkRoom}  
          severity="primary"  
        /></a>
    )
  }

  const statusLabel = (data) => {
    const getSeverity = (data) => {
        switch (data) {
            case 'Idle':
                return "success"
            case 'Maintenance':
                return "danger"
            default:
                return "warning";
        }
    }
    
    return (
        <Tag 
          value={data.status} 
          severity={getSeverity(data.status)}
        />
    )
  }

  const resetSuccess = () => {
    setSuccessDialog({
      visibility: false,
      _id: null,
      namaRoom: '',
      linkRoom: '',
      meetingID: '',
      passCode: '',
      hostKey: '',
    })
  }

  const classUserLabel = (data) => {
    return (
        data.kelasPengguna === null ? 
        <Tag value="Tidak Ada Pengguna" severity='success'/>
        :
        <a style={{textDecoration: 'none'}} href={`/classes/${data.kelasPengguna}`}>
            <Tag value={data.kelasPengguna} severity="warning"/>
        </a>

    )
  }

  const headerTemplate = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Daftar Room</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
              </span>
              <button className={styles.buttonAdd} onClick={() => showDialog(null, "Add Room")}>Tambah Room +</button>
              <Button icon='pi pi-cog' style={{borderRadius: '5px', height: '44px'}} onClick={() => showDialog(null, "Generate Room")}/>
            </div>
        </div>
    );
  }

  const actionBodyTemplate = (rowdata) => {
    let listProperties = {}
    rowdata.status !== "Used" ? listProperties= {
      outlined: false,
      icon: "pi pi-eye-slash",
      disabled: true,
    } : listProperties = {
      outlined: true,
      icon: "pi pi-eye",
      disabled: false,
    }

    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className='mr-2' onClick={() => showDialog(rowdata, "Update Room")}/>
        <Button icon="pi pi-trash" rounded outlined severity='danger'  onClick={() => showDialog(rowdata, "Delete Room")}/>
        <Button icon={listProperties.icon} className='ml-2' rounded outlined={listProperties.outlined} disabled={listProperties.disabled}
         severity='success' onClick={() => showDialog(rowdata, "Detail Room")}/>
      </React.Fragment>
    )
  }

  const addUpdateDialogFooter = () => (
    <React.Fragment>
        <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} className='mr-3'/>
        <Button label="Save" icon="pi pi-check" onClick={saveButtonHandler}/>
    </React.Fragment>
  )

  const deleteDialogFooter = () => (
    <React.Fragment>
        <div className='flex-auto text-center'>
            <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog()}/>
            <Button label="Yakin" icon="pi pi-check" severity="danger" onClick={() => deleteRoom()}/>
        </div>
    </React.Fragment>
  )

  const generateRoomDialogFooter = () => (
    <React.Fragment>
        <div className='flex-auto text-center'>
            <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog()}/>
            <Button label="Generate" icon="pi pi-cog" severity="success" onClick={() => generateClassRoom()}/>
        </div>
    </React.Fragment>
  )

  const detailDialogFooter = () => (
    <div className='text-center'>
         <Button label="Oke" icon="pi pi-times" severity='warning' className='mr-3' onClick={() => hideDialog()}/>
    </div>
  )

  const successDialogFooter = () => (
    <div className='d-flex justify-content-center align-items-center'>
         <Button label="Tutup" icon="pi pi-times" severity='primary' className='mr-3' onClick={() => resetSuccess()}/>
         <Button label="Copy Data" icon="pi pi-copy" outlined onClick={() => setClipboard()} />
         <p className='ml-2' style={{fontWeight: 'normal', color: 'grey', margin: 0}}>{copySuccess}</p>
    </div>
  )

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={() => setRefreshTable(!refreshTable)} />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => exportCSV(false)} />;
  
  const getValueStatus = (rowData) => {
    let value = rowData

    if (rowData !== "Maintenance") value = "Idle"

    return value
  }

  return (
    <ContainerPage>
        <Card>
            <Card.Body>
                <Dialog visible={addUpdateDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                 footer={addUpdateDialogFooter} onHide={hideDialog} header={dialogTitle}>
                    <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                        <div className='flex-auto'>
                            <label htmlFor="namaRoom" className='font-bold block mb-2'>Nama Room</label>
                            <InputText id='namaRoom' name='namaRoom' className='w-full' value={roomDetail.namaRoom} onChange={roomChange} required autoFocus/>
                        </div>
                        
                        <div className='flex-auto'>
                            <label htmlFor="linkRoom" className='font-bold block mb-2'>Link Room</label>
                            <InputText id='linkRoom' name='linkRoom' className='w-full' value={roomDetail.linkRoom} onChange={roomChange} required autoFocus/>
                        </div>

                        <div className='flex-auto mb-3'>
                            <label htmlFor="meetingID" className='font-bold block mb-2'>Meeting ID</label>
                            <InputText id='meetingID' name='meetingID' className='w-full' value={roomDetail.meetingID} onChange={roomChange} required autoFocus/>
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="passCode" className='font-bold block mb-2'>Pass Code</label>
                            <InputText id='passCode' name='passCode' className='w-full' value={roomDetail.passCode} onChange={roomChange} required autoFocus/>
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="hostKey" className='font-bold block mb-2'>Host Key</label>
                            <InputText id='hostKey' name='hostKey' className='w-full' value={roomDetail.hostKey} onChange={roomChange} required autoFocus/>
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="maintenance" className='font-bold block mb-2'>Maintenance</label>
                            <SelectButton id='status' name='status' value={getValueStatus(roomDetail.status)} options={statusMaintenance} optionLabel='label' optionValue='value'
                              onChange={roomChange} disabled={roomDetail.status == "Used" ? true : false} required autoFocus/>
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={generateRoom} style={{ width: '55rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                  header={dialogTitle} footer={generateRoomDialogFooter} onHide={() => hideDialog()}  
                >
                  <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                    <div className='flex-auto' style={{width: '100%'}}>
                        <label htmlFor="namaKelas" className='font-bold block mb-2'>Nama Kelas</label>
                        <Dropdown id='idKelas' name='idKelas' className='w-full' value={dataGenerateRoom.idKelas} options={listClass}
                          optionValue='_id' optionLabel='subject' onChange={generateChange} filter placeholder='Pilih Kelas' required autoFocus
                        />
                    </div>
                    <div className='flex-auto'>
                        <label htmlFor="durasi" className='font-bold block mb-2'>Nama Kelas</label>
                        <div className='p-inputgroup'>
                          <InputNumber name='durasi' id='durasi' value={dataGenerateRoom.durasi} className='w-full' required  min={1.5} max={4} 
                            onValueChange={generateChange} minFractionDigits={0} maxFractionDigits={2}/>
                          <span className="p-inputgroup-addon">Jam</span>
                        </div>
                    </div>
                    <div className='flex-auto'>
                        <label htmlFor="mulaiKelas" className='font-bold block mb-2'>Mulai Kelas</label>
                        <Calendar disabled name='mulaiKelas' className='w-full' value={dataGenerateRoom.mulaiKelas}
                         showTime hourFormat='24' showIcon dateFormat='dd MM yy -'/>
                    </div>
                  </div>

                </Dialog>

                <Dialog visible={deleteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                    header={dialogTitle} footer={deleteDialogFooter} onHide={() => hideDialog()}
                >
                    <div className="confirmation-content text-center">
                        <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                        <h5>Yakin ingin menghapus room "{roomDetail.namaRoom}"?</h5>
                    </div>
                </Dialog>

                <Dialog visible={detailDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }} 
                    header={dialogTitle} footer={detailDialogFooter} onHide={() => hideDialog()}
                >
                    <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                        <div className='flex-auto'>
                            <label htmlFor="kodeKelas" className='font-bold block mb-2'>Kode Kelas</label>
                            <InputText id='namaRoom' name='namaRoom' className='w-full' value={classDetail.kodeKelas} />
                        </div>
                        
                        <div className='flex-auto'>
                            <label htmlFor="namaKelas" className='font-bold block mb-2'>Nama Kelas</label>
                            <InputText id='namaKelas' name='namaKelas' className='w-full' value={classDetail.namaKelas} />
                        </div>

                        <div className='flex-auto mb-3'>
                            <label htmlFor="level" className='font-bold block mb-2'>Level</label>
                            <InputText id='level' name='level' className='w-full' value={classDetail.level} />
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="namaGuru" className='font-bold block mb-2'>Nama Guru</label>
                            <InputText id='namaGuru' name='namaGuru' className='w-full' value={classDetail.namaGuru} />
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="mulaiKelas" className='font-bold block mb-2'>Agenda Kelas</label>
                            <div className={styles.stripDate}> 
                                <InputText id='mulaiKelas' name='mulaiKelas' className='w-full' value={classDetail.mulaiKelas}/>
                                <span style={{marginInline: '5px'}}> - </span>
                                <InputText id='berakhirKelas' name='berakhirKelas' className='w-full' value={classDetail.berakhirKelas}/>
                            </div>
                            
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={successDialog.visibility} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }} 
                    header={"Berhasil Menggenerate Room"} footer={successDialogFooter} onHide={() => resetSuccess()}
                >
                    <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                        <div className='flex-auto'>
                            <label htmlFor="namaRoom" className='font-bold block mb-2'>Nama Room</label>
                            <InputText id='namaRoom' name='namaRoom' className='w-full' value={successDialog.namaRoom} />
                        </div>

                        <div className='flex-auto mb-3'>
                            <label htmlFor="linkRoom" className='font-bold block mb-2'>Link Room</label>
                            <InputText id='linkRoom' name='linkRoom' className='w-full' value={successDialog.linkRoom} />
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="meetingID" className='font-bold block mb-2'>Meeting ID</label>
                            <InputText id='meetingID' name='meetingID' className='w-full' value={successDialog.meetingID} />
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="passCode" className='font-bold block mb-2'>Passcode</label>
                            <InputText id='passCode' name='passCode' value={successDialog.passCode} />
                        </div>

                        <div className='flex-auto'>
                            <label htmlFor="Host Key" className='font-bold block mb-2'>Host Key</label>
                            <InputText id='hostKey' name='hostKey' value={successDialog.hostKey} />
                        </div>
                    </div>
                </Dialog>

                <div>
                    <DataTable value={listRoom} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                        rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} scrollable
                        globalFilterFields={['namaRoom', 'linkRoomm', 'meetingID', 'passCode', 'hostKey', 'mulaiKelas', 'berakhirKelas', 'status', 'kelasPengguna']}
                        header={headerTemplate} ref={dt} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                    >
                        <Column field="namaRoom" frozen sortable header="Nama Room" style={{ minWidth: '10rem' }}></Column>
                        <Column field="linkRoom" sortable header="Link Room" body={linkLabel} style={{ minWidth: '15rem' }}></Column>
                        <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '5rem' }}></Column>
                        <Column field="meetingID" sortable header="Meeting ID" style={{ minWidth: '12rem' }}></Column>
                        <Column field="passCode" sortable header="Pass Code" style={{ minWidth: '10rem' }}></Column>
                        <Column field="hostKey" sortable header="Host Key" style={{ minWidth: '12rem' }}></Column>
                        <Column field="kelasPengguna" sortable header="Kelas Pengguna" body={classUserLabel} style={{ minWidth: '12rem' }}></Column>
                        <Column body={actionBodyTemplate} frozen alignFrozen='right' style={{minWidth: '15rem'}}></Column>
                    </DataTable>
                </div>

                <Loading visible={loading} />
            </Card.Body>
        </Card>
    </ContainerPage>
  )
}

export default ListRoom
