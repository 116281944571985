import React, { useState } from 'react'
import styles from './DetailSalary.module.css'
import Loading from '../../components/Loading'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_API_URL, formatIDRCurrency, formatMonth, formattedDate, formattedDateWithOutTime, getSeverity } from '../../helpers/general'
import axios from 'axios'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'


const DetailSalary = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(false)
  const [ listSalaryReport, setListSalaryReport ] = useState(null)
  const [ detailTeacher, setDetailTeacher ] = useState(null)
  const [ detailSessionDialog, setDetailSessionDialog ] = useState(false)
  const [ dateDialog, setDateDialog ] = useState(false)
  const [ paySalaryDialog,setPaySalaryDialog ] = useState(false)
  const [ deleteSalaryDialog, setDeleteSalaryDialog ] = useState(false)
  const [ updateNoteDialog, setUpdateNoteDialog ] =useState(false)
  const [ detailSession, setDetailSession ] = useState(null)
  const [ dialogAttribute, setDialogAttribute ] = useState({
    header: '',
    message: '',
    icon: ''
  })
  const [ generateSalaryAtt, setGenerateSalaryAtt ] = useState({
    idSalary:null,
    dataBulan: null,
  })
  const [ paySalaryData, setPaysalaryData ] = useState({
    jumlahDibayarkan: 0,
    indexGaji: null,
    idSalary: null,
  })

  const [ noteSalaryData, setNoteSalaryData ] = useState({
    idSalary: null,
    keterangan: null,
    indexGaji: null
  })

  //attribute for search function
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getListSalaryReport =async () => {
    setLoading(true)

    const getListSalaryOption = {
        method: 'post',
        url: `${BASE_API_URL}/detail-salary`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        },
        data: {
            idSalary: id
        }
    }

    await axios.request(getListSalaryOption)
      .then((response) => {
        const salaryReport = response.data.listGaji

        salaryReport.map((report, index) => {
            report["index"] = index+1
            report["bulanGaji"] = formattedDateWithOutTime(report.bulanGaji)
            report["keterangan"] = report["keterangan"] == undefined? "-" : report["keterangan"]
        })

        setListSalaryReport(response.data.listGaji)
        setDetailTeacher(response.data.guru)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const modifyDetailSession = (data) => {
    const listResult = []

    if (data !== null && data.sesi.length > 0) {
        const sesi = data.sesi
        sesi.map((detailData) => {
            const kelas = detailData.kelas
            const subjekKelas = `${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl.${kelas.level}`
            const listSession = []
            const bulanGaji =  formatMonth(data.bulanGaji)
            const totalGaji = data.jumlahGaji

    
            if (detailData.indexSesi.length > 0) {
                const newIndexSesi = detailData.indexSesi.split(",")
                
                newIndexSesi.map((index) => {
                    listSession.push(parseInt(index) + 1)
                })
            }
    
            const formatData = {
                _id: detailData._id,
                subjectClass: subjekKelas,
                qtySession: detailData.jumlahSesi,
                listSession: listSession,
                bulanGaji: bulanGaji,
                nominal: data.jumlahGaji / data.jumlahSesi * detailData.jumlahSesi,
                totalGaji: totalGaji
            }
    
            listResult.push(formatData)
        }) 
    }

    setDetailSession(listResult)
    return listResult
  }

  const showDialog = (typeDialog, data) => {
    if (typeDialog == "ListSession") {
        modifyDetailSession(data)
        setDialogAttribute((currentData) => ({
            ...currentData,
            header: "Detail Sesi"
        }))
        setDetailSessionDialog(true)
    }

    if (typeDialog == "GenerateSalary") {
        setGenerateSalaryAtt((currentData) => ({
            ...currentData,
            idSalary: data
        }))

        setDialogAttribute((currentData) => ({
            ...currentData,
            header: "Generate Gaji"
        }))

        setDateDialog(true)
    }

    if (typeDialog == "PaySalary") {
      setPaysalaryData((currentData) => ({
        ...currentData,
        idSalary: id,
        indexGaji: data.index - 1
      }))

      setDialogAttribute((currentData) => ({
        ...currentData,
        header: "Pembayaran Gaji"
      }))

      setPaySalaryDialog(true)
    }

    if (typeDialog == "DeleteSalary") {
      setPaysalaryData((currentData) => ({
        ...currentData,
        idSalary: id,
        indexGaji: data.index - 1
      }))

      setDeleteSalaryDialog(true)
    }

    if (typeDialog == "NoteUpdate") {
      setDialogAttribute((currentData) => ({
        ...currentData,
        header: "Tambahkan Berita Acara"
      }))

      setNoteSalaryData((currentData) => ({
        ...currentData,
        idSalary: id,
        indexGaji: data.index - 1,
        keterangan: data.keterangan
      }))

      setUpdateNoteDialog(true)
    }
  }

  const refreshTable = () => {
    getListSalaryReport()
  }

  const generateSalaryChange = (event) => {
    const {name, value} =  event.target

    setGenerateSalaryAtt((detailSalary) => ({
        ...detailSalary,
        [name]: value
    }))
  }

  const generateSalary = async () => {
    setDateDialog(false)
    setLoading(true)

    const generateSalaryOptions = {
        method: 'put',
        url: `${BASE_API_URL}/update-salary`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        },
        data: {
            idSalary: generateSalaryAtt.idSalary,
            dataBulan: generateSalaryAtt.dataBulan
        }
    }

    await axios.request(generateSalaryOptions)
      .then(() => {
        refreshTable()
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const paySalaryChange = (event) => {
    const {name, value} =  event.target

    setPaysalaryData((detailSalary) => ({
        ...detailSalary,
        [name]: value
    }))
  }

  useState(() => {
    getListSalaryReport()
  })

  const headerListsalaryReport = () => (
    <div>
        <p style={{fontWeight: 'bold', fontSize: '20px', marginBlockEnd: '15px'}}>Kak, {detailTeacher == null ? "Teacher" : detailTeacher.nama}</p>
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
                <p className={styles.cardTitle}>Daftar Gaji</p>
            </div>
            <div>
                <span className='p-input-icon-left'>
                    <i className='pi pi-search'></i>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search"/>
                </span>
                <button className={styles.buttonAdd} onClick={() => showDialog("GenerateSalary", id)}>Generate Gaji</button>
            </div>
        </div>
    </div>
  )

  const bodyCurrencyTemplate = (rowData) => {
    return formatIDRCurrency(rowData)
  }

  const bodyStatusTemplate = (rowData) => {
    return (
        <Tag value={rowData} severity={getSeverity("Salary", rowData)}></Tag>
    )
  }

  const listSesssionTemplate = (rowData) => {
    return rowData.join(", ");
  }

  const actionBodyTemplate = (rowData) => {
    let bayarAction = false

    if (rowData.dibayar < rowData.jumlahGaji && rowData.dibayar === 0) bayarAction = true
    return (
        <React.Fragment>
            <Button icon='pi pi-trash' className='ml-2' outlined severity='danger' style={{borderRadius: '10px', paddingBlock: '7px'}} onClick={() => showDialog("DeleteSalary", rowData)}/>
            <Button label='Bayar' className='ml-2' severity='success' visible={bayarAction} style={{borderRadius: '10px', paddingBlock: '7px'}} onClick={() => showDialog("PaySalary", rowData)}/>
            <Button icon='pi pi-eye' className='ml-2' outlined severity='info' style={{borderRadius: '10px', paddingBlock: '7px'}} onClick={() => showDialog("ListSession", rowData)}/>
            <Button label='Slip' icon='pi pi-file-pdf' className='ml-2' severity='danger' visible={!bayarAction} style={{borderRadius: '10px', paddingBlock: '7px'}} onClick={() => {
              navigate('/report-salary', {state: {sesi: modifyDetailSession(rowData), guru: detailTeacher}})
            }}/>
            <Button visible={!bayarAction} icon='pi pi-book' className='ml-2' style={{borderRadius: '10px', paddingBlock: '7px'}} onClick={() => showDialog("NoteUpdate", rowData)}></Button>
        </React.Fragment>
    )
  }

  const paySalary = async () => {
    setPaySalaryDialog(false)
    setLoading(true)

    const dataPaySalary = paySalaryData

    const optionPaySalary = {
      method: 'post',
      url: `${BASE_API_URL}/pay-salary`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      },
      data: dataPaySalary,
    }

    await axios.request(optionPaySalary)
      .then(() => {
        refreshTable()
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  } 

  const updateNoteSalary = async () => {
    setUpdateNoteDialog(false)
    setLoading(true)

   const optionUpdateNoteSalary = {
    method: 'put',
    url: `${BASE_API_URL}/salary-note/${noteSalaryData.idSalary}`,
    headers: {
      'Authorization': localStorage.getItem('accesToken'),
      'Content-Type': 'application/json'
    },
    data: noteSalaryData
   }

   await axios.request(optionUpdateNoteSalary)
     .then(() => {
      refreshTable()
      setLoading(false)
     })
     .catch((err) => {
      console.log(err)
      setLoading(false)
     })
    
    setNoteSalaryData({
      idSalary: null,
      keterangan: null,
      indexGaji: null
    })
  }

  const deleteListSalary = async () => {
    setDeleteSalaryDialog(false)
    setLoading(true)

    const dataDelete = {
      idSalary: paySalaryData.idSalary,
      indexGaji: paySalaryData.indexGaji
    }

    const optionDeleteListSalary = {
      method: 'put',
      url: `${BASE_API_URL}/delete-listgaji`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      },
      data: dataDelete
    }

    await axios.request(optionDeleteListSalary)
      .then(() => {
        refreshTable()
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  const GenerateSalaryDialogFooter = (type) => (
    <React.Fragment>
        <div className='flex-auto text-end'>
            <Button label="Batal" icon="pi pi-times" outlined severity='danger' className='mr-3' onClick={() => setDateDialog(false)}/>
            <Button label="Generate" icon="pi pi-check" severity="primary" onClick={() => generateSalary()}/>
        </div>
    </React.Fragment>
  )

  const updateNoteDialogFooter = (data) => (
    <React.Fragment>
        <div className='flex-auto text-end mt-3'>
            <Button label="Batal" icon="pi pi-times" outlined severity='primary' className='mr-3' onClick={() => setUpdateNoteDialog(false)}/>
            <Button label="Tambahkan" icon="pi pi-check" severity="primary" onClick={() => updateNoteSalary(data._Id)}/>
        </div>
    </React.Fragment>
  )

  const PaySalaryDialogFooter = () => (
    <React.Fragment>
        <div className='flex-auto text-end'>
            <Button label="Batal" icon="pi pi-times" outlined severity='danger' className='mr-3' onClick={() => setPaySalaryDialog(false)}/>
            <Button label="Bayar" icon="pi pi-check" severity="success" onClick={() => paySalary()}/>
        </div>
    </React.Fragment>
  )

  const DeleteDialogFooter = () => (
    <React.Fragment>
        <div className='flex-auto text-end'>
            <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => setPaySalaryDialog(false)}/>
            <Button label="Yakin" icon="pi pi-check" severity="danger" onClick={() => deleteListSalary()}/>
        </div>
    </React.Fragment>
  )
  
  return (
    <ContainerPage>
        <Card>
            <Card.Body>
                <DataTable value={listSalaryReport} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
                  rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} scrollable header={headerListsalaryReport}
                  globalFilterFields={['jumlahGaji', 'dibayar', 'statusGaji', 'bulanGaji', 'jumlahSesi']}
                >
                    <Column field='index' header="No"></Column>
                    <Column field='bulanGaji' header="Bulan Gaji" sortable style={{minWidth: '12rem'}}></Column>
                    <Column field='jumlahSesi' header="Qty Sesi" sortable style={{minWidth: '8rem'}}></Column>
                    <Column field='jumlahGaji' header="Nominal Gaji" body={(row) => bodyCurrencyTemplate(row.jumlahGaji)} style={{minWidth: '12rem'}}></Column>
                    <Column field='dibayar' header="Nominal Terbayar" body={(row) => bodyCurrencyTemplate(row.dibayar)} style={{minWidth: '12rem'}}></Column>
                    <Column field='statusGaji' header="Status" body={(row) => bodyStatusTemplate(row.statusGaji)} style={{minWidth: '10rem'}}></Column>
                    <Column field='keterangan' header="Berita Acara" style={{minWidth: '15rem'}}></Column>
                    <Column body={actionBodyTemplate} style={{minWidth: '20rem'}}></Column>
                </DataTable>
            </Card.Body>
        </Card>

        <Loading visible={loading}/>

        <Dialog visible={detailSessionDialog} style={{ width: '60rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
          header={dialogAttribute.header} onHide={() => setDetailSessionDialog(false)}
        >
            <DataTable value={detailSession} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={10} 
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
                <Column field='subjectClass' sortable header="Subjek kelas" style={{ minWidth: '10rem' }}></Column>
                <Column field='qtySession' sortable header="Jumlah Sesi" style={{ minWidth: '5rem' }}></Column>
                <Column field='listSession' sortable header="Rincian Sesi" body={(row) => listSesssionTemplate(row.listSession)} style={{ minWidth: '1rem' }}></Column>
            </DataTable>
        </Dialog>

        <Dialog visible={dateDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
          header={dialogAttribute.header} onHide={() => setDateDialog(false)} footer={GenerateSalaryDialogFooter}
        >
           <div className='flex-auto mb-3'>
                <label htmlFor="month-salary" className='font-bold block mb-2'>Tanggal Gajian</label>
                <Calendar className='w-full' id='namaBulan' name='dataBulan' value={generateSalaryAtt.dataBulan} onChange={generateSalaryChange} showIcon dateFormat='dd MM yy'/>
          </div>
        </Dialog>

        <Dialog visible={deleteSalaryDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Hapus Daftar Gaji" footer={DeleteDialogFooter} onHide={() => setDeleteSalaryDialog(false)}>
            <div className="confirmation-content text-center">
                <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                <h5>Yakin ingin menghapus detail gaji bulan ini ?</h5>
            </div>
        </Dialog>

        <Dialog visible={updateNoteDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header={dialogAttribute.header} footer={updateNoteDialogFooter} onHide={() => setUpdateNoteDialog(false)}>
              <div className='flex-auto'>
                <InputTextarea value={noteSalaryData.keterangan} onChange={(e) => setNoteSalaryData((currentData) => ({...currentData, keterangan: e.target.value}))} 
                  id='keterangan' name='keterangan' className='w-full mt-2' rows={5}/>
              </div>
              
        </Dialog>

        <Dialog visible={paySalaryDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
          header={dialogAttribute.header} onHide={() => setPaySalaryDialog(false)} footer={PaySalaryDialogFooter}
        >
           <div className='flex-auto mb-3'>
                <label htmlFor="jumlahDibayarkan" className='font-bold block mb-2'>Nominal Dibayarkan</label>
                <InputNumber className='w-full' id='jumlahDibayarkan' name='jumlahDibayarkan' value={paySalaryData.jumlahDibayarkan} onValueChange={paySalaryChange} mode="currency" currency="IDR" locale="id-ID"/>
          </div>
        </Dialog>
    </ContainerPage>
  )
}

export default DetailSalary
