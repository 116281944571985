import React from 'react'
import NavbarLogin from '../NavBar/NavbarLogin'
import styles from './MainContent.module.css'

const MainContent = (props) => {
  return (
    <div className={styles.layout}>
      <NavbarLogin/>
      <div className={styles.layoutMainContent}>{props.children}</div>
    </div>
  )
}

export default MainContent
