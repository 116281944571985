import React, { useEffect, useState } from 'react';
import ContainerPage from "../../layouts/ContainerPage/ContainerPage";
import Loading from "../../components/Loading";
import { Card } from "primereact/card";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import axios from "axios";
import { BASE_API_URL, BASE_URL } from '../../helpers/general';
import EduTableHeader from '../../components/TableHeader';
import EduButton from '../../components/Button';
import { Message } from 'primereact/message';
import { ReyImage } from '../../assets';

const ShortLinkPage = () => {
    const [links, setLinks] = useState([]);
    const [linkDialog, setLinkDialog] = useState(false);
    const [ copySuccess, setCopySuccess ] = useState({
        visibility: false,
        text: ""
    });
    const [currentLink, setCurrentLink] = useState({ link: '', shortString: '' });
    const [message, setMessage] = useState({
        visibility: false,
        status: false,
        severity: 'warning',
        text: ''
    });

    // Loading Handle
    const [loading, setLoading] = useState(false);

    // Filter Handle 
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    const fetchLinks = async () => {
        setLoading(true);
        try {
            await axios.get(`${BASE_API_URL}/short-links`)
            .then((response) => {
              setLoading(false)
              setLinks(response.data);
            });
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch links:', error);
        }
    };

    const openNewLinkDialog = () => {
        setCurrentLink({ link: '', shortString: '' });
        setLinkDialog(true);
        setMessage({
            severity: "",
            visibility: false,
            status: false,
            text: ""
        })
    };

    const hideDialog = (type) => {
        if (type === "UpSert") setLinkDialog(false);
        if (type === "Copy") {
            setCopySuccess({
                visibility: false,
                text: ""
            });
        }
    };

    const saveLink = async () => {
        setLoading(true);
        hideDialog("UpSert");

        try {
            if (currentLink._id) {
                await axios.put(`${BASE_API_URL}/short-link/${currentLink._id}`, currentLink);
            } else {
                await axios.post(`${BASE_API_URL}/short-link`, currentLink);
            }
            fetchLinks();
        } catch (error) {
            setLoading(false);
            console.error('Error saving link:', error);
        }
    };

    const editLink = (rowData) => {
        setCurrentLink({...rowData});
        setMessage({
            visibility: false,
            status: false,
            severity: '',
            text: ''
        })
        setLinkDialog(true);
    };

    const deleteLink = async (id) => {
        setLoading(true);
        try {
            await axios.delete(`${BASE_API_URL}/short-link/${id}`);
            fetchLinks();
        } catch (error) {
            setLoading(false);
            console.error('Error deleting link:', error);
        }
    };

    const copyLink = (rowData) => {
        const newLink = `${BASE_URL}/${rowData.shortString}`;
        navigator.clipboard.writeText(newLink);
        setCopySuccess({
            visibility: true,
            text: "Hiyaaa, link berhasil di-copy nih."
        });
    }

    const linkBodyTemplate = (rowData) => (
      <EduButton type='link' actionButton={rowData.link} label={rowData.link}/>
    );

    const shortenLinkTemplate = (rowData) => {
      const shortlink = `/${rowData.shortString}`
      return shortlink
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <EduButton type='action' icon='pi pi-pencil' severity='info' outline={true} actionButton={() => editLink(rowData)}/>
                <EduButton type='action' icon='pi pi-trash' severity='danger' actionButton={() => deleteLink(rowData._id)}/>
                <EduButton type='action' icon='pi pi-copy' severity='secondary' outline={true} actionButton={() => copyLink(rowData)}/>
            </React.Fragment>
        );
    };

    const renderHeader = () => {
      return (
        <EduTableHeader
          title='ShortLink'    
          button
          labelButton="New Link"
          actionButton={openNewLinkDialog}
          search
          filterValue={globalFilterValue}
          filterChange={onGlobalFilterChange}
          searchKey="Search shortlink"
        />
      );
    }

    const onChangeUrl = (e) => {
        if (links.find(link => link.shortString === e.target.value)) {
            setMessage({
                visibility: true,
                severity: 'error',
                text: 'Ooops, maaf link serupa sudah tersedia.',
                status: false
            })
        } else {
            setMessage({
                visibility: true,
                severity: 'success',
                text: 'Yeay, link bisa digunakan.',
                status: true
            })
        }
        setCurrentLink({ ...currentLink, shortString: e.target.value });
    }

    return (
      <ContainerPage>
        <Loading visible={loading}/>
        <Card>
            <DataTable 
              value={links} dataKey='_id'
              tablestyle={{minwidth:'50rem'}} removableSort 
              paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} 
              filters={filters} scrollable header={renderHeader()}
            >
                <Column field="shortString" header="Shorten Link" body={shortenLinkTemplate} sortable/>
                <Column field="link" header="URL" body={linkBodyTemplate} sortable/>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={linkDialog} style={{ width: '600px' }} header="Add New Short Link" modal className="p-fluid" onHide={() => hideDialog("UpSert")}>
                <div className="flex-auto mb-2">
                    <label htmlFor="shortString" className='font-bold block mb-1'>Short String</label>
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">{window.location.origin}/</span>
                        <InputText id="shortString" value={currentLink.shortString} onChange={(e) => onChangeUrl(e)} />
                    </div>
                </div>
                {message.visibility && <Message severity={message.severity} text={message.text} style={{height: '10px'}}/>}
                <div className="flex-auto mb-2">
                    <label htmlFor="link" className='font-bold block mb-1'>URL</label>
                    <InputText id="link" value={currentLink.link} onChange={(e) => setCurrentLink({ ...currentLink, link: e.target.value })} />
                </div>
                <div className='flex justify-content-center'>
                  <EduButton label='Save' actionButton={saveLink} icon='pi pi-check' width='40%' disable={!message.status}/>
                </div>
            </Dialog>

            <Dialog 
              visible={copySuccess.visibility} 
              header="Berhasil"
              style={{ width: '450px' }} 
              modal className="p-fluid" 
              onHide={() => hideDialog("Copy")}
            >
                <div className='text-center'>
                    <img src={ReyImage} alt="Mas Rey" height={200}/>
                    <p className='my-3' style={{fontSize: '20px', fontWeight: '500'}}>{copySuccess.text}</p>
                    <EduButton label='Oke' actionButton={() => hideDialog('Copy')} width='60%'/>
                </div>
            </Dialog>
        </Card>
      </ContainerPage>
    );
};

export default ShortLinkPage;
