import React, { useEffect, useState } from 'react'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import styles from './Attendance.module.css'
import Loading from '../../components/Loading'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { BASE_API_URL, getSeverity } from '../../helpers/general'
import axios from 'axios'
import { Column } from 'primereact/column'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { color } from 'chart.js/helpers'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { SelectButton } from 'primereact/selectbutton'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { SuccessPTC } from '../../assets'
import { InputTextarea } from 'primereact/inputtextarea'

const Attendance = () => {
  const [ loading, setLoading ] = useState(false)
  const [ delayedClass, setDelayedClass ] = useState(null)
  const [ expandedRows, setExpandedRows ] = useState([]);
  const [ listTeachers, setListTeachers] = useState(null)
  const [ successAddAttendance, setSuccessAddAttendance ] = useState(false)
  const [ idKelasSuccess, setIdKelasSuccess ] = useState(null)
  const [ listClass, setListClass] = useState(null)
  const [ needClass, setNeedClass ] = useState('Iya')
  const [ filters, setFilters ] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }) 
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [ addRawAttendance, setAddRawAttendance ] = useState({
    idKelas: '',
    kelasString: '',
    guru: '', 
    jadwal: null, 
    dataSiswa: ''
  })
  const optionNeedClass = [
    {
      id: '1-Iya',
      value: 'Iya'
    },
    {
      id: '2-Tidak',
      value: 'Tidak'
    }
  ]

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = {...filters}

    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const getDelayedClass = async () => {
    setLoading(true)

    const optionGetDelayedClass = {
        method: 'get',
        url: `${BASE_API_URL}/get-last-delayed`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        }
    }

    await axios.request(optionGetDelayedClass)
      .then((response) => {
        setDelayedClass(response.data.detail)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const attendanceChange = (event) => {
    let value
    const {name} = event.target

    if (name == "jadwal") value = event.value
    else value = event.target.value

    setAddRawAttendance((currentData) => ({
      ...currentData,
      [name]: value
    }))
  }

  const getAllTeachers = async () => {
    const optionGetTeachers = {
      method: 'get',
      url: `${BASE_API_URL}/teachers`,
      headers: {
        'Authorization': localStorage.getItem("accessToken"),
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionGetTeachers)
      .then((response) => {
        setListTeachers(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getAllClass = async () => {
    const optionGetClass = {
      method: 'get',
      url: `${BASE_API_URL}/class`,
      headers: {
        'Authorization': localStorage.getItem("accessToken"),
        'Content-Type': 'application/json'
      }
    }

    await axios.request(optionGetClass)
      .then((response) => {
        const classes = response.data

        classes.map((kelas) => {
          kelas['subject'] = `[${kelas.guru.nama}] ${kelas.namaKelas} - ${kelas.kodeBatch}.${kelas.kodeKelas} (${kelas.jenisKelas}) - Lvl. ${kelas.level}`
        })
        setListClass(classes)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const executeForceAttendance = async () => {
    setLoading(true)

    let dataForceAttendance
    let url

    if (needClass == "Tidak") {
      dataForceAttendance= {
        kelasString: addRawAttendance.kelasString,
        guru: addRawAttendance.guru,
        jadwal: addRawAttendance.jadwal,
        dataSiswa: addRawAttendance.dataSiswa
      }
      url= `${BASE_API_URL}/createClassAndAddStudentsFromString`
    } else {
      dataForceAttendance = {
        idKelas: addRawAttendance.idKelas,
        dataSiswa: addRawAttendance.dataSiswa
      }
      url= `${BASE_API_URL}/addStudentsFromString`
    }

    const optionForceAttendance = {
      method: 'post',
      url: url,
      headers: {
        'Authorization': localStorage.getItem('accessToken'),
        'Content-Type': 'application/json'
      },
      data: dataForceAttendance
    }

    await axios.request(optionForceAttendance)
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          if (needClass == "Tidak") setIdKelasSuccess(response.data.kelas._id)
          else setIdKelasSuccess(addRawAttendance.idKelas)

          setLoading(false)
          setSuccessAddAttendance(true)
          resetAddRawAttendace()
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const resetAddRawAttendace = () => {
    setAddRawAttendance({
      idKelas: '',
      kelasString: '',
      guru: '', 
      jadwal: null, 
      dataSiswa: ''
    })
  }

  const changeStatus = async (data, index) => {
    setLoading(true)
    console.log(data)

    const dataPost = {
      indexGuru: data
    }

    const optionGetDelayedClass = {
        method: 'put',
        url: `${BASE_API_URL}/change-status`,
        headers: {
            'Authorization': localStorage.getItem('accessToken'),
            'Content-Type': 'application/json'
        },
        data: dataPost
    }

    await axios.request(optionGetDelayedClass)
      .then((response) => {
        if (response.status == 200) {
          getDelayedClass()
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getDelayedClass()
    getAllClass()
    getAllTeachers()
  }, [])


  const headerTemplate = (data) => {
    return (
        <div className='d-flex justify-content-between align-items-center'>
          <div>
          <span className="vertical-align-middle mx-2 font-bold line-height-3">{data.namaGuru}</span>
          <Tag value={data.statusGuru} 
              style={{
                color: 'white', 
                backgroundColor: getSeverity("teacherType", data.statusGuru),
                verticalAlign: 'middle'
              }}
          />
          </div>
          {
            data.statusGuruSelesai == "Selesai" ?
              <Button icon='pi pi-verified' label='Followed Up' severity='info' style={{borderRadius: '10px', paddingBlock: '5px'}}/> : 
              <Button icon='pi pi-check' label='Done ?' severity='warning' style={{borderRadius: '10px', paddingBlock: '5px'}} onClick={() => changeStatus(data.indexGuru)}/>
          }
        </div>
    );
  };

  const calculateDelayedClassTotal = (idGuru) => {
      let total = 0;

      if (delayedClass) {
          for (let kelas of delayedClass) {
              if (kelas.idGuru === idGuru) {
                  total++;
              }
          }
      }

      return total;
  };

  const renderHeader = () => {
    return (
        <div className={styles.searchBarLayout}>
            <div className={styles.cardHeader}>
              <p className={styles.cardTitle}>Daftar Kelas Bermasalah</p>
            </div>
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        </div>
    );
  };

  const footerTemplate = (data) => {
    return (
        <React.Fragment>
            <td colSpan={2}>
                <div className="flex justify-content-start align-items-start font-bold w-full">
                  <p>Total Kelas Bermasalah: </p>
                  <span style={{color: 'red', marginInline: '5px'}}> {calculateDelayedClassTotal(data.idGuru)}</span>
                </div>
            </td>
        </React.Fragment>
    );
  };

  const actionBodyTemplate = (data) => (
    <React.Fragment>
      <a href={`/classes/${data.kelas._id}`} style={{textDecoration: 'none'}}>
        <Button icon="pi pi-eye" label='Cek Detail' rounded severity='primary' style={{borderRadius: '10px', height: '40px'}}/>
      </a>
    </React.Fragment>
  );

  const footerDialogAddStudent = (
    <div className='flex justify-content-center'>
        <Button label="Tutup" icon="pi pi-times" onClick={() => setSuccessAddAttendance(false)} className="p-button-text mr-3"/>
        <a href={`/classes/${idKelasSuccess}`}><Button label="Cek Hasil" icon="pi pi-check" autoFocus /></a>
    </div>
  )


  return (
    <ContainerPage>
        <Loading visible={loading}/>
        
        <Dialog visible={successAddAttendance} style={{ width: '25rem' }} header="Berhasil Mengeksekusi Data" footer={footerDialogAddStudent} 
         onHide={() => setSuccessAddAttendance(false)}>
          <div className={styles.successPTC}>  
            <img src={SuccessPTC} alt="" width={250}/>
            <p>Yeay, berhasil Mengeksekusi Data!</p>
          </div>
        </Dialog>
        
        <Card>
            <Card.Body>
                <Tabs defaultActiveKey={0} className={styles.myTabs}>
                  <Tab eventKey={0} title="Tambah Presensi Kelas">
                    <div className='p-fluid mb-3'>
                      <div className='flex-auto my-3' style={{width: '100%'}}>
                          <label htmlFor="maintenance" className='font-bold block mb-2'>Sudah Ada Kelas?</label>
                          <SelectButton value={needClass} options={optionNeedClass} optionLabel='value' optionValue='value'
                            onChange={(e) => setNeedClass(e.target.value)} required autoFocus/>
                      </div>

                      {
                          needClass == 'Tidak'? 
                            <div className='flex flex-wrap gap-2 p-fluid mb-3' style={{width: '100%'}}>
                              <div className='flex-auto' style={{width: '100%'}}>
                                  <label htmlFor="kelasString" className='font-bold block mb-2'>Nama Kelas</label>
                                  <InputText id='kelasString' name='kelasString' className='w-full' value={addRawAttendance.kelasString} onChange={attendanceChange} required autoFocus/>
                              </div>
                              <div className='flex-auto'>
                                  <label htmlFor="dataSiswa" className='font-bold block mb-2'>Guru</label>
                                  <Dropdown id='guru' name='guru' className='w-full' filter options={listTeachers} optionLabel='nama' optionValue='_id'
                                   value={addRawAttendance.guru} onChange={attendanceChange} required autoFocus placeholder='Pilih Guru'/>
                              </div>
                              <div className='flex-auto' style={{maxWidth: '30%'}}>
                                  <label htmlFor="jadwal" className='font-bold block mb-2'>Jadwal</label>
                                  <Calendar id="jadwal" name='jadwal' value={addRawAttendance.jadwal} showTime hourFormat="24" onChange={attendanceChange} showIcon dateFormat='dd MM yy -' />
                              </div>
                            </div>
                          : 
                            <div className='flex-auto mb-3'>
                              <label htmlFor="namaRoom" className='font-bold block mb-2'>Kelas</label>
                              <Dropdown id='idKelas' name='idKelas' className='w-full' options={listClass} optionLabel='subject' optionValue='_id'
                               filter value={addRawAttendance.idKelas} onChange={attendanceChange} required autoFocus placeholder='Pilih Kelas'/>
                            </div>
                      }
                      
                      <div className='flex-auto'>
                          <label htmlFor="dataSiswa" className='font-bold block mb-2'>Data Siswa</label>
                          <InputTextarea id='dataSiswa' name='dataSiswa' className='w-full' value={addRawAttendance.dataSiswa} onChange={attendanceChange} rows={5} required autoFocus/>
                      </div>

                      <div className='flex gap-2 mt-3 justify-content-end'>
                        <Button label='Reset' outlined severity='danger' style={{width: 'fit-content', borderRadius: '10px'}} onClick={() => resetAddRawAttendace()}/>
                        <Button icon='pi pi-cog' label='Eksekusi' severity='info' style={{width: 'fit-content', borderRadius: '10px'}} onClick={() => executeForceAttendance()}/>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey={1} title="Kelas Bermasalah">
                    {/* <DataTable value={delayedClass} rowGroupMode="rowSpan" groupRowsBy="idGuru" removableSort
                      sortMode="single" sortField="idGuru" sortOrder={1} tableStyle={{ minWidth: '25rem' }}  header={renderHeader()}
                      filters={filters} dataKey='kelas._id' globalFilterFields={['namaGuru', 'nomorGuru', 'namaKelas', 'sesi']}
                    > */}
                    <DataTable value={delayedClass} rowGroupMode="subheader" groupRowsBy="namaGuru" sortMode="single" sortField="namaGuru"
                     sortOrder={1} scrollable scrollHeight="60vh" rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate} 
                     filters={filters} dataKey='kelas._id' header={renderHeader()} globalFilterFields={['namaGuru', 'nomorGuru', 'namaKelas', 'sesi', 'statusGuru']}
                     tableStyle={{ minWidth: '30rem' }}
                    >
                     {/* <DataTable value={delayedClass} rowGroupMode="rowspan" groupRowsBy="namaGuru" sortMode="single" sortField="namaGuru" 
                      sortOrder={1} tableStyle={{ minWidth: '50rem' }} filters={filters} dataKey='kelas._id' header={renderHeader()}
                      globalFilterFields={['namaGuru', 'nomorGuru', 'namaKelas', 'sesi']}> */}
                        <Column header="No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                        <Column field='subjekKelas' header='Subjek Kelas' sortable style={{minWidth: '20rem'}}/>
                        <Column field='sesi' header='Sesi Kelas' sortable style={{minWidth: '10rem'}}/>
                        <Column header='Action' body={actionBodyTemplate} style={{minWidth: '10rem', textAlign:'end'}}/>
                    </DataTable>
                  </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    </ContainerPage>
  )
}

export default Attendance
