import React, { useEffect, useRef, useState } from 'react'
import styles from './ListTeacher.module.css'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import axios from 'axios'
import ContainerPage from '../../layouts/ContainerPage/ContainerPage'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { FilterMatchMode } from 'primereact/api'
import { BASE_API_URL, formattedDateWithOutTime, getSeverity, newFormatDate, stringToDate } from '../../helpers/general'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag'
import { InputNumber } from 'primereact/inputnumber'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { listFinanceAccount } from '../../helpers/listBank'
import Loading from '../../components/Loading'
import { color } from 'chart.js/helpers'
import { Badge } from 'primereact/badge'

const ListTeacher = () => {
    const [ teacherDetail, setTeacherDetail ] = useState({
        _id: null,
        nama: '',
        panggilan: '',
        nomor: null,
        tempatLahir: '',
        tanggalLahir: null,
        domisili: '',
        pendidikan: '',
        namaBank: '',
        rekening: '',
        status: '',
        email: '',
    }) 
    const [ activeKey, setActiveKey ] = useState(0)
    const dt = useRef(null)
    const [ listTeacher, setListTeacher ] = useState(null)
    const [ listVerifiedTeacher, setListVerifiedTeacher ] = useState(null)
    const [ listUnverifiedTeacher, setListUnverifiedTeacher ] = useState(null)
    const [ dialogTeacher, setDialogTeacher ] = useState(false)
    const [ dialogDelete, setDialogDelete ] = useState(false)
    const [ dialogVerif, setDialogVerif ] = useState(false)
    const [ dialogLock, setDialogLock ] = useState(false)
    const [ dialogTypeTeacher, setDialogTypeTeacher ] = useState(false)
    const [ dialogTitle, setDialogTitle ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ statusRefreshTable, setStatusRefreshTable] = useState(false)

    const [ listTeacherType, setListTeacherType ] = useState({
        superTeacher: {
            aktif: 0,
            pasif: 0,
            berhenti: 0,
            total: 0,
            list: []
        },
        premiumTeacher: {
            aktif: 0,
            pasif: 0,
            berhenti: 0,
            total: 0,
            list: []
        },
        volunteerTeacher: {
            aktif: 0,
            pasif: 0,
            berhenti: 0,
            total: 0,
            list: []
        }
    })
    const optionsTypeTeacher = [
        {
            id: 1,
            name: "Super Teacher"
        },
        {
            id: 2,
            name: "Premium Teacher"
        },
        {
            id: 3,
            name: "Volunteer Teacher"
        }
    ]
    const [ dataTypeTeacher, setDataTypeTeacher ] = useState({
        idTeacher: null,
        typeTeacher: null
    })
    
    const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    const userChange = (event) => {
        const { name, value } = event.target
        setTeacherDetail((currentValue) => ({
            ...currentValue,
            [name]: value
        }))
    }

    const typeTeacherChange = (event) => {
        const { name, value } = event.target
        setDataTypeTeacher((currentValue) => ({
            ...currentValue,
            [name]: value
        }))
    }

    const getAllTeacher = async () => {
        const optionsGetTeacher = {
            method: 'get',
            url: `${BASE_API_URL}/teachers`,
            headers: {
                'authorization': localStorage.getItem('accessToken')
            }
        }

        await axios.request(optionsGetTeacher)
            .then((response) => {
                if(response.status == '200'){
                    setListTeacher(response.data)
                    getVerifiedTeacher(response.data)
                    getUnVerifiedTeacher(response.data)

                    const teachers = response.data;
                    const superTeacher = {
                        aktif: 0,
                        pasif: 0,
                        berhenti: 0,
                        total: 0,
                        list: []
                    }
                    const premiumTeacher = {
                        aktif: 0,
                        pasif: 0,
                        berhenti: 0,
                        total: 0,
                        list: []
                    }
                    const volunteerTeacher = {
                        aktif: 0,
                        pasif: 0,
                        berhenti: 0,
                        total: 0,
                        list: []
                    }
                    teachers.map((teacher) => {
                        if (teacher.superTeacher) {
                            superTeacher.total += 1;
                            if (teacher.status == 'Aktif') superTeacher.aktif += 1;
                            if (teacher.status == 'Pasif') superTeacher.pasif += 1;
                            if (teacher.status == 'Berhenti') superTeacher.berhenti += 1;

                            superTeacher.list.push(teacher)
                        } else if (!teacher.volunteerTeacher) {
                            premiumTeacher.total += 1;
                            if (teacher.status == 'Aktif') premiumTeacher.aktif += 1;
                            if (teacher.status == 'Pasif') premiumTeacher.pasif += 1;
                            if (teacher.status == 'Berhenti') premiumTeacher.berhenti += 1;

                            premiumTeacher.list.push(teacher)
                        } else {
                            volunteerTeacher.total += 1;
                            if (teacher.status == 'Aktif') volunteerTeacher.aktif += 1;
                            if (teacher.status == 'Pasif') volunteerTeacher.pasif += 1;
                            if (teacher.status == 'Berhenti') volunteerTeacher.berhenti += 1;

                            volunteerTeacher.list.push(teacher)
                        }
                    })

                    setListTeacherType({
                        superTeacher: superTeacher,
                        premiumTeacher: premiumTeacher,
                        volunteerTeacher: volunteerTeacher
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getAllTeacher()
    }, [statusRefreshTable])

    const getVerifiedTeacher = (dataTeachers) => {
        setListVerifiedTeacher(dataTeachers.filter((teacher) => teacher.status !== "Belum terverifikasi"))
    }

    const getUnVerifiedTeacher = (dataTeachers) => {
        setListUnverifiedTeacher(dataTeachers.filter((teacher) => teacher.status === "Belum terverifikasi"))
    }
    
    const resetDetailTeacher = () => {
        setTeacherDetail({
            _id: null,
            nama: '',
            panggilan: '',
            nomor: null,
            tempatLahir: '',
            tanggalLahir: null,
            domisili: '',
            pendidikan: '',
            namaBank: '',
            rekening: '',
            status: '',
            email: '',
        })
    }

    const showDialog = (data, titleDialog) => {
        setDialogTitle(titleDialog)
        setTeacherDetail({
            _id: data._id,
            nama: data.nama,
            panggilan: data.panggilan,
            nomor: data.nomor,
            tempatLahir: data.tempatLahir,
            tanggalLahir: newFormatDate(data.tanggalLahir),
            domisili: data.domisili,
            pendidikan: data.pendidikan,
            namaBank: data.namaBank,
            rekening: data.rekening,
            status: data.status,
            email: data.email,
        })
        
        if (titleDialog == "Edit Data Guru") setDialogTeacher(true)

        if (titleDialog == "Update Status Guru") {
            // setTeacherDetail((currentData) => ({
            //     ...currentData,
            //     status: data.status === "Aktif" ? "Berhenti" : "Aktif"
            // }))
            setDialogLock(true)
        }

        if (titleDialog == "Verifikasi Guru") {
            setTeacherDetail((currentData) => ({
                ...currentData,
                status: "Aktif"
            }))
            setDialogVerif(true)
        }

        if (titleDialog == "Verifikasi Guru Volunteer") {
            setTeacherDetail((currentData) => ({
                ...currentData,
                status: "Aktif",
                volunteerTeacher: true,
            }))
            setDialogVerif(true)
        }

        if (titleDialog == "Update Type Guru") {
            let typeTeacher
            
            if (data.superTeacher) typeTeacher = "Super Teacher"
            else if (data.volunteerTeacher) typeTeacher = "Volunteer Teacher"
            else typeTeacher = "Premium Teacher"

            setDataTypeTeacher({
                idTeacher: data._id,
                typeTeacher: typeTeacher
            })

            setDialogTypeTeacher(true)
        }
    }

    const showDeleteDialog = (data) => {
        setTeacherDetail({
            _id: data._id,
            nama: data.nama,
            nomor: data.nomor,
        })
        setDialogDelete(true)
    }

    const hideDialog = () => {
        resetDetailTeacher()
        if (dialogTitle == 'Edit Data Guru') setDialogTeacher(false)
        if (dialogTitle == 'Verifikasi Guru') setDialogVerif(false)
        if (dialogTitle == 'Verifikasi Guru Volunteer') setDialogVerif(false)
        if (dialogTitle == 'Update Status Guru') setDialogLock(false)
        if (dialogTitle == 'Update Type Guru') setDialogTypeTeacher(false)
    }

    const hideDeleteDialog = () => {
        resetDetailTeacher()
        setDialogDelete(false)
    }

    const renderHeader = () => {
        return (
            <div className={styles.searchBarLayout}>
                <div className={styles.cardHeader}>
                    <p className={styles.cardTitle}>Daftar Guru</p>
                </div>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                </div>
            </div>
        );
    }

    const newLabelTTL = (rowData) => {
        const tanggalLahir = formattedDateWithOutTime(rowData.tanggalLahir)
        return `${rowData.tempatLahir}, ${tanggalLahir}`
    }

    const newLabelBank = (rowData) => {
        return `${rowData.rekening} - ${rowData.namaBank}`
    }

    const actionBodyTemplate = (rowdata) => {

        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className='mr-2' onClick={() => showDialog(rowdata ,'Edit Data Guru')}/>
                <Button icon='pi pi-cog' rounded severity='danger' tooltip='Ubah status keaktifan' tooltipOptions={{showDelay: 300, hideDelay: 500}}
                  onClick={() => showDialog(rowdata, 'Update Status Guru') }
                />
                <a href={`/teachers/${rowdata._id}`}><Button icon="pi pi-eye" className='ml-2 mr-2' rounded outlined severity='success' /></a>
                <Button icon="pi pi-verified" severity='info' rounded onClick={() => showDialog(rowdata, 'Update Type Guru')}/>
            </React.Fragment>
        )
    }

    const actionBodyTemplateUnverified = (rowdata) => {
        return (
            <React.Fragment>
                <Button className={styles.labelButton} label='Volunteer' severity='warning' onClick={() => showDialog(rowdata ,'Verifikasi Guru Volunteer')}/>
                <Button className={styles.labelButton} icon="pi pi-check-circle" label='Verifikasi' onClick={() => showDialog(rowdata ,'Verifikasi Guru')}/>
                <Button className={styles.labelButton} icon="pi pi-eraser" label='Tolak' outlined severity='danger' onClick={() => showDeleteDialog(rowdata)}/>
            </React.Fragment>
        )
    }

    const teacherDialogFooter = () => (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} className='mr-3'/>
            <Button label="Save" icon="pi pi-check" onClick={saveButtonHandler}/>
        </React.Fragment>
    );

    const rejectDialogFooter = () => (
        <React.Fragment>
            <div className='flex-auto text-center'>
                <Button label="No" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDeleteDialog()}/>
                <Button label="Yes" icon="pi pi-check" severity="danger" onClick={() => deleteTeacher(teacherDetail._id)}/>
            </div>
        </React.Fragment>
    )

    const verifDialogFooter = () => (
        <React.Fragment>
            <div className='flex-auto text-center'>
                <Button label="No" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog()}/>
                <Button label="Yes" icon="pi pi-check" severity="primary" onClick={saveButtonHandler}/>
            </div>
        </React.Fragment>
    )

    const updateTypeDialogFooter = () => (
        <React.Fragment>
            <div className='flex-auto text-right'>
                <Button label="Batal" icon="pi pi-times" outlined className='mr-3' onClick={() => hideDialog()}/>
                <Button label="Update Tipe Guru" icon="pi pi-check" severity="primary" onClick={() => updateTypeTeacher()}/>
            </div>
        </React.Fragment>
    )

    const refreshTable = () => {
        getAllTeacher()
    }

    const saveButtonHandler = () => {
        updateTeacher()
    }

    const deleteTeacher = async (teacherID) => {
        const optionDeleteTeacher = {
            method: 'delete',
            url: `${BASE_API_URL}/teachers/${teacherID}`,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        await axios.request(optionDeleteTeacher)
            .then((response) => {
                if (response.status == 200) {
                    setStatusRefreshTable(!statusRefreshTable)
                    hideDeleteDialog()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const updateTeacher = async () => {
        hideDialog()
        setLoading(true)
        const dataUpdateTeacher = teacherDetail

        const optionUpdateTeacher = {
            method: 'put',
            url: `${BASE_API_URL}/teachers/${teacherDetail._id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: dataUpdateTeacher
        }

        await axios.request(optionUpdateTeacher)
            .catch((error) => {
                console.log(error)
            })
        
        refreshTable()
        setLoading(false)
    }

    const updateTypeTeacher = async () => {
        hideDialog()
        setLoading(true)

        let url
        if (dataTypeTeacher.typeTeacher == "Super Teacher") url =  'super-teacher'
        if (dataTypeTeacher.typeTeacher == "Volunteer Teacher") url = 'volunteer-teacher'
        if (dataTypeTeacher.typeTeacher == "Premium Teacher") url = 'premium-teacher'

        const optionsTypeTeacher = {
            method: 'put',
            url: `${BASE_API_URL}/${url}`,
            headers: {
                'Authorization': localStorage.getItem('accessToken')
            },
            data: {
                idTeacher: dataTypeTeacher.idTeacher
            }
        }

        await axios.request(optionsTypeTeacher)
          .then((response) => {
            refreshTable()
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
    }

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({selectionOnly})
    }

    const statusLabel = (data) => {
        return <Tag value={data.status} severity={getSeverity("teacherStatus", data.status)}></Tag>
    }

    const teacherType = (data) => {
        let labelType

        if (data.superTeacher) 
            labelType = "Super Teacher"
        else if (data.volunteerTeacher)
            labelType = "Volunteer Teacher"
        else
            labelType = "Premium Teacher"

        return (
            <Tag value={labelType} style={{color: 'white', backgroundColor: getSeverity("teacherType", labelType)}}/>
        )
    }

    const labelWA = (data) => {
        return (
            <a href={`https://wa.me/${data.nomor}`} target="_blank" className={styles.buttonLabel}>
            <div className={styles.myButton}>
                <i className='pi pi-whatsapp'></i>
                <span>{data.nomor}</span>
            </div>
            </a>
        )
    }

    const headerBadge = (title, length) => (
        <div className='d-flex gap-2 align-items-center'>
          {title}
          <Badge value={length} severity="warning"/>
        </div>
    )

    const listStatusTemplate = (option) => (
        <Badge value={option} severity={getSeverity('teacherStatus', option)} style={{fontSize: '15px'}}/>
    )

    const selectedStatus = (option) => (
        <Badge value={option} severity={getSeverity('teacherStatus', option)} style={{fontSize: '15px', width: '10rem'}}/>
    )

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text onClick={() => refreshTable()} />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={() => exportCSV(false)} />;
    
    return (
        <ContainerPage>  
            <Loading visible={loading}/>          
            <Dialog visible={dialogTeacher} style={{ width: '32rem' }} breakpoints={{ '960px': '75vm', '641px': '90vw' }}
                    footer={teacherDialogFooter} onHide={hideDialog} header={dialogTitle}>
                <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                    <div className='flex-auto'>
                        <label htmlFor="nama" className='font-bold block mb-2'>Nama Guru</label>
                        <InputText disabled id='nama' name='nama' value={teacherDetail.nama} onChange={userChange} required autoFocus/>
                    </div>
                    
                    <div className='flex-auto'>
                        <label htmlFor="nomor" className='font-bold block mb-2'>Nomor Telepon</label>
                        <InputNumber disabled id='nomor' name='nomor' value={teacherDetail.nomor} onValueChange={userChange} required autoFocus useGrouping={false} 
                        minFractionDigits={0} placeholder='62 ...'/>
                    </div>

                    <div className='flex-auto mb-3'>
                        <label htmlFor="tanggalLahir" className='font-bold block mb-2'>Tempat Tanggal Lahir</label>
                        <div className={styles.ttlField}>
                            <InputText disabled id='tempatLahir' name='tempatLahir' value={teacherDetail.tempatLahir} style={{width: '13rem'}} onChange={userChange} required autoFocus/>
                            <span className={styles.koma}>,</span>
                            <Calendar disabled id='tanggalLahir' name='tanggalLahir' className='w-full' value={teacherDetail.tanggalLahir} onChange={userChange} showIcon dateFormat='dd MM yy'/>
                        </div>
                    </div>

                    <div className='flex-auto'>
                        <label htmlFor="domisili" className='font-bold block mb-2'>Kota Asal</label>
                        <InputText disabled id='domisili' name='domisili' value={teacherDetail.domisili} onChange={userChange} required autoFocus/>
                    </div>

                    <div className='flex-auto'>
                        <label htmlFor="pendidikan" className='font-bold block mb-2'>Pendidikan</label>
                        <InputText disabled id='pendidikan' name='pendidikan' value={teacherDetail.pendidikan} onChange={userChange} className='w-full' 
                        required autoFocus/>
                    </div>

                    <div className='flex-auto'>
                        <label htmlFor="namaBank" className='font-bold block mb-2'>Nama Bank</label>
                        <Dropdown id='namaBank' name='namaBank' value={teacherDetail.namaBank} options={listFinanceAccount} optionValue='name' optionLabel='name'
                        onChange={userChange} style={{minWidth: '20rem'}} placeholder='Pilih Bank/E-Wallet' filter
                        />
                    </div>

                    <div className='flex-auto'>
                        <label htmlFor="rekening" className='font-bold block mb-2'>Rekening</label>
                        <InputText id='rekening' name='rekening' value={teacherDetail.rekening} onChange={(userChange)} required autoFocus/>
                    </div>

                    <div className='flex-auto'>
                        <label htmlFor="email" className='font-bold block mb-2'>Email</label>
                        <InputText type='email' id='email' name='email' value={teacherDetail.email} onChange={(userChange)} required autoFocus/>
                    </div>
                </div>
                
            </Dialog>

            <Dialog visible={dialogDelete} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Tolak Pendaftaran Guru" footer={rejectDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content text-center">
                    <i className="pi pi-exclamation-triangle mb-3" style={{ fontSize: '7rem', color:'var(--red-600)' }} />
                    <h5>Yakin ingin menolak pendaftaran "{teacherDetail.nama}"?</h5>
                </div>
            </Dialog>

            <Dialog visible={dialogVerif} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Verifikasi Pendaftaran Guru" footer={verifDialogFooter} onHide={hideDialog}>
                <div className="confirmation-content text-center">
                    <i className="pi pi-check-circle mb-4" style={{ fontSize: '6rem', color: 'var(--primary-color)' }}/>
                    <h5>Yakin ingin memverifikasi pendaftaran "{teacherDetail.nama}"?</h5>
                </div>
            </Dialog>

            <Dialog visible={dialogLock} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Perubahan Status Guru" footer={verifDialogFooter} onHide={hideDialog}>
                <div className="confirmation-content text-center">
                    <i className="pi pi-check-circle mb-4" style={{ fontSize: '6rem', color: 'var(--primary-color)' }}/>
                    <Dropdown name='status' id='status' options={['Aktif', 'Pasif', 'Berhenti']} className='w-full mb-3'
                     itemTemplate={listStatusTemplate} value={teacherDetail.status} valueTemplate={selectedStatus} onChange={userChange} />
                    <h5>Yakin ingin mengubah status guru ?</h5>
                </div>
            </Dialog>

            <Dialog visible={dialogTypeTeacher} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header="Update Type Guru" footer={updateTypeDialogFooter} onHide={hideDialog}
            >
                <div className='flex flex-wrap gap-2 p-fluid mb-3'>
                    <div className='flex-auto w-full'>
                        <label htmlFor="nama" className='font-bold block mb-2'>Nama Guru</label>
                        <InputText disabled id='nama' name='nama' value={teacherDetail.nama} onChange={userChange} required autoFocus/>
                    </div>
                    <div className='flex-auto w-full'>
                        <label htmlFor="nama" className='font-bold block mb-2'>Type Guru</label>
                        <Dropdown id='typeTeacher' name='typeTeacher' options={optionsTypeTeacher} optionValue='name' optionLabel='name' value={dataTypeTeacher.typeTeacher} onChange={typeTeacherChange} required autoFocus/>
                    </div>
                </div>
            </Dialog>

            <div className={styles.containerNotif}>
                <Card className={styles.cardNotif}>
                    <Card.Header className={styles.cardNotifHeaderLayout}>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className={styles.cardNotifHeader}>Super Teacher</p>
                            <span><Badge value={listTeacherType.superTeacher.total} severity="info"/></span>
                        </div>
                        <button onClick={() => setActiveKey(1)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
                    </Card.Header>
                    <Card.Body className={styles.cardNotifContent}>
                        <div className={styles.superTeacherIcon}>
                            <i className='pi pi-star-fill' style={{fontSize: '1.5rem'}}></i>
                            <i className='pi pi-star-fill' style={{fontSize: '3.5rem'}}></i>
                            <i className='pi pi-star-fill' style={{fontSize: '1.5rem'}}></i>
                        </div>
                        <div className='d-flex gap-3'>
                            <div className='text-end'>
                                <p className={styles.subjectCard}>Aktif</p>
                                <p className={styles.cardValue}>{listTeacherType.superTeacher.aktif} <span className={styles.entity}>Guru</span></p>
                            </div>
                            <div className='text-end'>
                                <p className={styles.subjectCard}>Pasif</p>
                                <p className={styles.cardValue}>{listTeacherType.superTeacher.pasif} <span className={styles.entity}>Guru</span></p>
                            </div>
                            <div className='text-end'>
                                <p className={styles.subjectCard}>Berhenti</p>
                                <p className={styles.cardValue}>{listTeacherType.superTeacher.berhenti} <span className={styles.entity}>Guru</span></p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <Card className={styles.cardNotif}>
                    <Card.Header className={styles.cardNotifHeaderLayout}>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className={styles.cardNotifHeader}>Premium Teacher</p>
                            <span><Badge value={listTeacherType.premiumTeacher.total} severity="info"/></span>
                        </div>
                        <button onClick={() => setActiveKey(2)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
                    </Card.Header>
                    <Card.Body className={styles.cardNotifContent}>
                        <div className={styles.premiumTeacherIcon}>
                            <i className='pi pi-verified' style={{fontSize: '3rem'}}></i>
                        </div>
                        <div className='d-flex gap-2'>
                            <div className='text-end'>
                                <p className={styles.subjectCard}>Aktif</p>
                                <p className={styles.cardValue}>{listTeacherType.premiumTeacher.aktif} <span className={styles.entity}>Guru</span></p>
                            </div>
                            <div className='text-end'>
                                <p className={styles.subjectCard}>Pasif</p>
                                <p className={styles.cardValue}>{listTeacherType.premiumTeacher.pasif} <span className={styles.entity}>Guru</span></p>
                            </div>
                            <div className='text-end'>
                                <p className={styles.subjectCard}>Berhenti</p>
                                <p className={styles.cardValue}>{listTeacherType.premiumTeacher.berhenti} <span className={styles.entity}>Guru</span></p>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <Card className={styles.cardNotif}>
                    <Card.Header className={styles.cardNotifHeaderLayout}>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className={styles.cardNotifHeader}>Volunteer Teacher</p>
                            <span><Badge value={listTeacherType.volunteerTeacher.total} severity="info"/></span>
                        </div>
                        <button onClick={() => setActiveKey(3)}><i className='pi pi-external-link' style={{color: '#609af8'}}></i></button>
                    </Card.Header>
                    <Card.Body className={styles.cardNotifContent}>
                    <div className={styles.volunteerTeacherIcon}>
                        <i className='pi pi-users' style={{fontSize: '3rem'}}></i>
                    </div>
                    <div className='d-flex gap-2'>
                        <div className='text-end'>
                            <p className={styles.subjectCard}>Aktif</p>
                            <p className={styles.cardValue}>{listTeacherType.volunteerTeacher.aktif} <span className={styles.entity}>Guru</span></p>
                        </div>
                        <div className='text-end'>
                            <p className={styles.subjectCard}>Pasif</p>
                            <p className={styles.cardValue}>{listTeacherType.volunteerTeacher.pasif} <span className={styles.entity}>Guru</span></p>
                        </div>
                        <div className='text-end'>
                            <p className={styles.subjectCard}>Berhenti</p>
                            <p className={styles.cardValue}>{listTeacherType.volunteerTeacher.berhenti} <span className={styles.entity}>Guru</span></p>
                        </div>
                    </div>
                    </Card.Body>
                </Card>
            </div>

            <Card>
                <Card.Body>
                <Tabs defaultActiveKey={0} activeKey={activeKey} onSelect={(key) => setActiveKey(key)} id="myTabs" className={styles.myTabs}>
                    <Tab eventKey={0} title={headerBadge("Semua Guru",listVerifiedTeacher !== null? listVerifiedTeacher.length : 0)}>
                        <div>
                            <DataTable value={listVerifiedTeacher} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                                rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                                globalFilterFields={['nama', 'nomor', 'tanggalLahir', 'domisili', 'pendidikan', 'tempatLahir', 'tanggalLahir', 'namaBank', 'rekening', 'status']}
                                header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                            >
                                <Column field="nama" sortable header="Nama Guru" frozen style={{ minWidth: '15rem', fontWeight: 'bold' }}></Column>
                                <Column field="nomor" sortable header="No. Handphone" body={labelWA} style={{ minWidth: '13rem' }}></Column>
                                <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="Tipe" body={teacherType} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="TTL" body={newLabelTTL} style={{ minWidth: '12rem' }}></Column>
                                <Column field="domisili" sortable header="Kota Asal" style={{ minWidth: '10rem' }}></Column>
                                <Column field="pendidikan" sortable header="Pendidikan" style={{ minWidth: '12rem' }}></Column>
                                <Column sortable header="Finance Account" body={newLabelBank} style={{ minWidth: '15rem' }}></Column>
                                <Column body={actionBodyTemplate} style={{minWidth: '17rem'}}></Column>
                            </DataTable>                        
                        </div>
                    </Tab>
                    <Tab eventKey={1} title="Super">
                        <div>
                            <DataTable value={listTeacherType.superTeacher.list} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                                rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                                globalFilterFields={['nama', 'nomor', 'tanggalLahir', 'domisili', 'pendidikan', 'tempatLahir', 'tanggalLahir', 'namaBank', 'rekening']}
                                header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                            >
                                <Column field="nama" sortable header="Nama Guru" frozen style={{ minWidth: '15rem', fontWeight: 'bold' }}></Column>
                                <Column field="nomor" sortable header="No. Handphone" body={labelWA} style={{ minWidth: '13rem' }}></Column>
                                <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="Tipe" body={teacherType} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="TTL" body={newLabelTTL} style={{ minWidth: '12rem' }}></Column>
                                <Column field="domisili" sortable header="Kota Asal" style={{ minWidth: '10rem' }}></Column>
                                <Column field="pendidikan" sortable header="Pendidikan" style={{ minWidth: '12rem' }}></Column>
                                <Column sortable header="Finance Account" body={newLabelBank} style={{ minWidth: '15rem' }}></Column>
                                <Column body={actionBodyTemplate} style={{minWidth: '17rem'}}></Column>
                            </DataTable>                        
                        </div>
                    </Tab>
                    <Tab eventKey={2} title="Premium">
                        <div>
                            <DataTable value={listTeacherType.premiumTeacher.list} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                                rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                                globalFilterFields={['nama', 'nomor', 'tanggalLahir', 'domisili', 'pendidikan', 'tempatLahir', 'tanggalLahir', 'namaBank', 'rekening']}
                                header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                            >
                                <Column field="nama" sortable header="Nama Guru" frozen style={{ minWidth: '15rem', fontWeight: 'bold' }}></Column>
                                <Column field="nomor" sortable header="No. Handphone" body={labelWA} style={{ minWidth: '13rem' }}></Column>
                                <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="Tipe" body={teacherType} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="TTL" body={newLabelTTL} style={{ minWidth: '12rem' }}></Column>
                                <Column field="domisili" sortable header="Kota Asal" style={{ minWidth: '10rem' }}></Column>
                                <Column field="pendidikan" sortable header="Pendidikan" style={{ minWidth: '12rem' }}></Column>
                                <Column sortable header="Finance Account" body={newLabelBank} style={{ minWidth: '15rem' }}></Column>
                                <Column body={actionBodyTemplate} style={{minWidth: '17rem'}}></Column>
                            </DataTable>                        
                        </div>
                    </Tab>
                    <Tab eventKey={3} title="Volunteer">
                        <div>
                            <DataTable value={listTeacherType.volunteerTeacher.list} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                                rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                                globalFilterFields={['nama', 'nomor', 'tanggalLahir', 'domisili', 'pendidikan', 'tempatLahir', 'tanggalLahir', 'namaBank', 'rekening']}
                                header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                            >
                                <Column field="nama" sortable header="Nama Guru" frozen style={{ minWidth: '15rem', fontWeight: 'bold' }}></Column>
                                <Column field="nomor" sortable header="No. Handphone" body={labelWA} style={{ minWidth: '13rem' }}></Column>
                                <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="Tipe" body={teacherType} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="TTL" body={newLabelTTL} style={{ minWidth: '12rem' }}></Column>
                                <Column field="domisili" sortable header="Kota Asal" style={{ minWidth: '10rem' }}></Column>
                                <Column field="pendidikan" sortable header="Pendidikan" style={{ minWidth: '12rem' }}></Column>
                                <Column sortable header="Finance Account" body={newLabelBank} style={{ minWidth: '15rem' }}></Column>
                                <Column body={actionBodyTemplate} style={{minWidth: '17rem'}}></Column>
                            </DataTable>                        
                        </div>
                    </Tab>
                    <Tab eventKey={4} title={headerBadge("Guru Belum Terverifikasi", listUnverifiedTeacher !== null ? listUnverifiedTeacher.length:0)}>
                        <div>
                            <DataTable value={listUnverifiedTeacher} tablestyle={{minwidth:'50rem'}} removableSort paginator rows={5} 
                                rowsPerPageOptions={[5, 10, 25, 50]} filters={filters} dataKey='_id' scrollable
                                globalFilterFields={['nama', 'nomor', 'tanggalLahir', 'domisili', 'pendidikan', 'tempatLahir', 'tanggalLahir', 'namaBank', 'rekening']}
                                header={renderHeader()} paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} ref={dt}
                            >
                                <Column field="nama" sortable header="Nama Guru" frozen style={{ minWidth: '15rem', fontWeight: 'bold' }}></Column>
                                <Column field="email" sortable header="Email" style={{ minWidth: '15rem' }}></Column>
                                <Column field="nomor" sortable header="No. Handphone" body={labelWA} style={{ minWidth: '13rem' }}></Column>
                                <Column field="status" sortable header="Status" body={statusLabel} style={{ minWidth: '10rem' }}></Column>
                                <Column sortable header="TTL" body={newLabelTTL} style={{ minWidth: '12rem' }}></Column>
                                <Column field="domisili" sortable header="Kota Asal" style={{ minWidth: '10rem' }}></Column>
                                <Column field="pendidikan" sortable header="Pendidikan" style={{ minWidth: '12rem' }}></Column>
                                <Column sortable header="Finance Account" body={newLabelBank} style={{ minWidth: '15rem' }}></Column>
                                <Column body={actionBodyTemplateUnverified} style={{minWidth: '18rem'}}></Column>
                            </DataTable>                        
                        </div>
                    </Tab>
                </Tabs>
                </Card.Body>
            </Card>
        </ContainerPage>
    )
}

export default ListTeacher
